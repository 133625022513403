import OrderStore from './store';
import { tripCacheStore } from './stores/tripCache';

import ACTION from './action';

import { alertDev } from '../../utils/alert';
import { IOrderDetailsData, ITripEvent } from '../../types/order';
import { IOrderStore } from './types';

class Order {
  tripCache = tripCacheStore;
  api: any;
  xhr: null | any;
  orderStore: any;

  constructor(api: any) {
    this.api = api.trip;
    this.xhr = null;
    this.orderStore = OrderStore();
  }

  get = (): IOrderStore => this.orderStore.getState();

  start = () =>
    this.orderStore.dispatch({
      type: ACTION.ORDERLOAD,
    });

  load = (id: string) =>
    this.api.getDetails(id).then((res: IOrderDetailsData) => {
      this.orderStore.dispatch({
        type: ACTION.UPDATE,
        payload: {
          ...res,
          Id: id,
        },
      });
    });

  // getOrderByParams = (params) => {
  //   return this.api.getOrderByParams(params);
  // }

  goChatPaymentInvoice = (tripId: number) =>
    this.api.goChatPaymentInvoice(tripId);

  getPaymentInvoice = (
    companyId: number,
    tripId: number,
    format: string,
  ) => this.api.getPaymentInvoice(companyId, tripId, format);

  sendInvoiceEmail = (
    companyId: number,
    tripId: number,
    email: string,
  ) => this.api.sendInvoiceEmail(companyId, tripId, email);

  downloadPaymentInvoice = (guid: string) =>
    this.api.downloadPaymentInvoice(guid);

  downloadVouchers = (tripId: number, locale: string) =>
    this.api.downloadVouchers(tripId, locale);

  getDetailsByIds = async (
    id: number[],
  ): Promise<IOrderDetailsData[]> => {
    try {
      const res: IOrderDetailsData[] = await this.api.getDetailsByIds(
        id,
      );

      this.orderStore.dispatch({
        type: ACTION.UPDATESHOWNLINK,
        payload: res,
      });

      return res;
    } catch (err) {
      // @ts-ignore
      return alertDev(err);
    }
  };

  getRefundAir = async (tripItemId: number) => {
    const refundAir = await this.api.getRefundAir(tripItemId);

    this.orderStore.dispatch({
      type: ACTION.GET_REFUND_AIR,
      payload: {
        ...refundAir,
      },
    });

    return refundAir;
  };

  getRefundTrain = (companyId: number, tripItemId: number) =>
    this.api.getRefundTrain(companyId, tripItemId);

  getRefundHotel = (tripItemId: number) =>
    this.api.getRefundHotel(tripItemId);

  getRefundAeroexpress = (tripItemId: number) =>
    this.api.getRefundAeroexpress(tripItemId);

  getRefundTaxi = (tripItemId: number) =>
    this.api.getRefundTaxi(tripItemId);

  getRefundVipHall = async (tripItemId: number) => {
    const refundVipHall = await this.api.getRefundVipHall(tripItemId);

    this.orderStore.dispatch({
      type: ACTION.GET_REFUND_VIP,
      payload: {
        ...refundVipHall,
      },
    });

    return refundVipHall;
  };

  getTripPlanDetails = async (tripId: number) => {
    const res = await this.api.getTripPlanDetails(tripId);

    await this.orderStore.dispatch({
      type: ACTION.GET_PLAN_TRIP_DETAILS,
      payload: res,
    });

    return res;
  };

  createPlanTripEvent = (tripEvent: ITripEvent) =>
    this.api
      .createPlanTripEvent(tripEvent)
      .then(() =>
        this.getTripPlanDetails(tripEvent.TripId as number),
      );

  deletePlanTripEvent = (tripEventId: number, tripId: number) =>
    this.api
      .deletePlanTripEvent(tripEventId)
      .then(() => this.getTripPlanDetails(tripId));

  updatePlanTripEvent = (tripEvent: ITripEvent) =>
    this.api
      .updatePlanTripEvent(tripEvent)
      .then(() =>
        this.getTripPlanDetails(tripEvent.TripId as number),
      );

  downloadPlanTripEvent = (
    tripEventId: number,
    ownerId: number,
    needAddVouchers: boolean,
  ) => this.api.downloadPlanTripEvent(tripEventId, ownerId, needAddVouchers);

  sendToEmailPlanTripEvent = ({
    tripId,
    ownerId,
    addvouchers,
    locale,
    email,
  }: {
    tripId: number;
    ownerId: number;
    addvouchers: boolean;
    locale: string;
    email: string;
  }) =>
    this.api.sendToEmailPlanTripEvent(
      tripId,
      ownerId,
      addvouchers,
      locale,
      email,
    );

  cancelAir = (tripItemId: number) =>
    this.api.sendCancelAir(tripItemId);

  cancelTrain = (tripItemId: number) =>
    this.api.sendCancelTrain(tripItemId);

  cancelHotel = (tripItemId: number) =>
    this.api.sendCancelHotel(tripItemId);

  cancelAeroexpress = (tripItemId: number) =>
    this.api.sendCancelAeroexpress(tripItemId);

  cancelTaxiVoucher = (tripItemId: number) =>
    this.api.sendCancelTaxiVoucher(tripItemId);

  cancelVipHall = (tripItemId: number) =>
    this.api.sendCancelVipHall(tripItemId);

  cancelTransfer = (tripItemId: number) =>
    this.api.sendCancelTransfer(tripItemId);

  getTransferRefund = async (tripItemId: number) => {
    const refundTransfer = await this.api.getTransferRefund(
      tripItemId,
    );

    this.orderStore.dispatch({
      type: ACTION.GET_REFUND_TRANSFER,
      payload: {
        ...refundTransfer,
      },
    });

    return refundTransfer;
  };

  send = (data: any) => this.api.send(data); // TODO не нашел где используетя!

  sendDocument = (data: { tripItemId: number, email: string }) => this.api.sendDocument(data);

  sendServiceForm = (data: {
    tripItemId: number;
    serviceId: number;
    email: string;
  }) => this.api.sendServiceForm(data);

  rename = (id: number, data: { Name: string }) => this.api.rename(id, data);

  recent = (status: string) =>
    this.api.recent({
      count: 5,
      status,
    });

  updateLink = (value: boolean) =>
    this.orderStore.dispatch({
      type: ACTION.UPDATELINK,
      payload: value,
    });

  downloadCalendar = (details: IOrderDetailsData | null = null) => {
    this.updateLink(true);

    const currentDetails = details || this.get().details;

    const ids = currentDetails?.OrderItems.length
      ? currentDetails?.OrderItems.map(({ Id }) => Id)
      : [];
    const data = {
      TripItems: [...ids],
    };

    return this.api
      .downloadCalendar(currentDetails?.TripId, data)
      .finally(() => this.updateLink(false));
  };

  downloadFlightCertificate = (
    tripItemId: number,
    isFree: boolean = false,
  ): Promise<void> => this.api.downloadFlightCertificate(tripItemId, isFree);

  getAllStatusesFlightCertificate = (
    allIds: number[],
    isFree = false,
  ) => {
    const methodApi = isFree
      ? this.api.getStatusFlightCertificateFree
      : this.api.getStatusFlightCertificate;

    const prepareItem = (id: number) =>
      methodApi(id, isFree).then(
        (status: string) => ({ status, id }),
        () => Promise.resolve({ status: 'error', id }),
      );

    Promise.all(allIds.map(prepareItem))
      .then(res => {
        this.orderStore.dispatch({
          type: ACTION.GET_ALL_STATUS_FLIGHT_CERTIFICATE,
          payload: {
            allFlightCertificateStatus: res.reduce(
              (acc, { status, id }) => ({ ...acc, [id]: status }),
              {},
            ),
          },
        });
      })
      .catch(() => {
        this.orderStore.dispatch({
          type: ACTION.GET_ALL_STATUS_FLIGHT_CERTIFICATE,
          payload: {
            allFlightCertificateStatus: {},
          },
        });
      });
  };

  bookFlightCertificate = async (
    tripItemId: number,
    projectId: number,
  ) => {
    this.orderStore.dispatch({
      type: ACTION.GET_FLIGHT_CERTIFICATE,
      payload: {
        Success: true,
        Error: '',
        flightCertificateLoading: true,
      },
    });
    try {
      const res = await this.api.bookFlightCertificate(
        tripItemId,
        projectId,
      );
      this.orderStore.dispatch({
        type: ACTION.GET_FLIGHT_CERTIFICATE,
        payload: {
          ...res,
          flightCertificateLoading: false,
        },
      });
    } catch (e) {
      this.orderStore.dispatch({
        type: ACTION.GET_FLIGHT_CERTIFICATE,
        payload: {
          Success: false,
          Error: 'Ошибка сети',
          flightCertificateLoading: false,
        },
      });
    }
  };

  setResetStore = () =>
    this.orderStore.dispatch({
      type: ACTION.RESET_STORE,
    });

  setAnalytics = (tripId: number, analyticsValueId: number) =>
    this.api.setCustomAnalytics(tripId, analyticsValueId);

  unsetAnalytics = (tripId: number, analyticsValueId: number) =>
    this.api.unsetCustomAnalytics(tripId, analyticsValueId);

  setAnalyticsForItem = (
    tripId: number,
    tripItemId: number,
    analyticsValueId: number,
  ) =>
    this.api.setCustomAnalyticsForItem(
      tripId,
      tripItemId,
      analyticsValueId,
    );

  unsetAnalyticsForItem = (
    tripId: number,
    tripItemId: number,
    analyticsValueId: number,
  ) =>
    this.api.unsetCustomAnalyticsForItem(
      tripId,
      tripItemId,
      analyticsValueId,
    );

  setAdditionalFeeSmartagentTrip = (data: { TripItemId: number, AgentFee: string }) => this.api.setAdditionalFeeSmartagentTrip(data);

  subscribeCart = (cb: any) => this.orderStore.subscribe(cb);
}

export default Order;
