import React, { Component } from 'react';
import * as queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';

import { parseSearchString } from '../../bi/utils/convertSearchParams';
import { prepareQueryMeal } from '../../bi/utils/hotels';
import HotelsResult from './components/result';

import ROUTES from '../../bi/constants/routes';
import { MainAnalytic } from '../../bi/utils/analytics';
import { SELECTTRAVELLERS } from '../../bi/constants/hotelsSearch';
import { ANALYTIC_SERVICE_TYPES } from '../../bi/constants/serviceType';

import UserSession from '../../bi/services/userSession';
import Workspace from '../../bi/services/workspace';
import NotificationService from '../../bi/services/notification';
import FavoriteService from '../../bi/services/favorites';
import NewHotelsService from '../../bi/services/newHotels';
import HotelsService from '../../bi/services/hotels';
import RequestsService from '../../bi/services/requests';
import { Rights } from '../../bi/types/workspace';
import FeatureFlagsService from '../../bi/services/featureFlags';
import AccountSettings from '../../bi/services/accountSettings';
import PopupsService from '../../bi/services/popups';
import AppService from '../../bi/services/app';

import { AccountTravelPolicy, ITravelPolicyListItem } from '../../bi/types/travelPolicy';

interface HotelsRegionResultProps {
  history: RouteComponentProps['history'],
  hotelsService: HotelsService,
  newHotelsService: NewHotelsService,
  favoriteService: FavoriteService,
  notificationService: NotificationService,
  userSessionService: UserSession,
  workspaceService: Workspace,
  requestsService: RequestsService,
  location: Location,
  isFavoriteLastPath: boolean,
  aggregationId: number | null,
  featureFlagsService: FeatureFlagsService,
  accountSettingsService: AccountSettings,
  popupsService: PopupsService,
  appService: AppService,
}

interface HotelsRegionResultState {
  loading: boolean,
  loadingRegion: boolean,
  travelPolicyList: ITravelPolicyListItem[],
  rightsBuyTrip: Rights,
  accountTravelPolicy: AccountTravelPolicy | null,
  travelPolicyAllList: ITravelPolicyListItem[],
  isFiltersHotelsInMicroservice: boolean,
  isShownContext: boolean,
}

class HotelsRegionResult extends Component<HotelsRegionResultProps, HotelsRegionResultState> {
  static defaultProps = {
    isFavoriteLastPath: false,
  };

  isFavoriteLastPath = false;
  idRequest = 0;
  idRequestItem = 0;
  unsubscribeFn: () => void;

  constructor(props: HotelsRegionResultProps) {
    super(props);

    const {
      userSessionService,
      workspaceService: {
        travelPolicy,
        rights,
      },
      featureFlagsService: {
        getFiltersHotelsInMicroservice,
      },
      popupsService: {
        store: {
          popupsState: {
            mice_hotels_region_group_search,
          },
        },
      },
    } = props;

    this.state = {
      loading: true,
      loadingRegion: true,
      travelPolicyList: userSessionService.get().travelPolicies,
      rightsBuyTrip: rights,
      accountTravelPolicy: travelPolicy,
      travelPolicyAllList: userSessionService.get().travelPolicies,
      isFiltersHotelsInMicroservice: getFiltersHotelsInMicroservice(),
      isShownContext: !mice_hotels_region_group_search,
    };
  }

  componentDidMount() {
    this.load();

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_SCREEN_OPENED(ANALYTIC_SERVICE_TYPES.HOTEL),
    );
  }

  load = () => {
    const { rightsBuyTrip, accountTravelPolicy, travelPolicyAllList, isFiltersHotelsInMicroservice } = this.state;
    const { location, requestsService, aggregationId } = this.props;

    this.unsubscribeFn = this.props.hotelsService.subscribeHotels(this.updateState);

    const searchParams = {
      ...parseSearchString(location.search),
      isFiltersHotelsInMicroservice,
    } as Record<string, string | boolean | []>;

    const settings = {
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    };

    if (searchParams) {
      const { request, requestItem } = requestsService.get();

      if (searchParams.is_request && request && requestItem) {
        this.idRequest = request.Id;
        this.idRequestItem = requestItem.Id;
      }

      this.isFavoriteLastPath = this.props.isFavoriteLastPath
        ? !!searchParams.name && (searchParams.name as []).length > 0
        : false;
      this.setState({
        loading: true,
      }, () => {
        if (this.idRequestItem > 0) {
          this.props.hotelsService.searchFromRequest(requestItem, settings, aggregationId, isFiltersHotelsInMicroservice);
        } else {
          // @ts-ignore
          this.props.hotelsService.searchByParams(searchParams, settings, aggregationId);
        }
      });
    }
  };

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateState = ({ loading, loadingRegion }: { loading: boolean, loadingRegion: boolean }) => {
    const { loading: oldLoading, loadingRegion: oldLoadingRegion } = this.state;

    if (loading === oldLoading && loadingRegion === oldLoadingRegion) return;

    this.setState({ loading, loadingRegion });
  };

  searchGeneral = () => {
    const {
      hotelsService: {
        searchFormIsValid,
        setInitRadiusSearchHotels,
        isRegion,
        mapStateToSearchObject,
        searchByParams,
        mapStateToHotelPageSearchSettings,
        getSearchState,
      },
      aggregationId,
    } = this.props;
    const { rightsBuyTrip, accountTravelPolicy, travelPolicyAllList, isFiltersHotelsInMicroservice } = this.state;

    if (searchFormIsValid()) {
      if (isRegion()) {
        setInitRadiusSearchHotels(false);

        this.props.history.push({
          pathname: ROUTES.SEARCH.HOTELS_REGION,
          search: queryString.stringify(mapStateToSearchObject()),
        });

        const settings = {
          rightsBuyTrip,
          accountTravelPolicy,
          travelPolicyAllList,
        };

        const params = {
          ...mapStateToSearchObject(),
          isFiltersHotelsInMicroservice,
        };

        // @ts-ignore
        searchByParams(params, settings, aggregationId);
      } else {
        const hotelId = getSearchState().region.selected.Id;

        this.props.history.push({
          pathname: `${ROUTES.SEARCH.HOTEL_PAGE}/${hotelId}`,
          search: queryString.stringify(mapStateToHotelPageSearchSettings(this.idRequestItem)),
        });
      }
    }
  };

  handleShownContext = (value: boolean) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CONTEXT.HOTELS_REGION.CLOSED);

    this.setState({ isShownContext: value });
  };

  generateLink = (hotelId: number, defaultLink = false) => {
    const {
      history,
      hotelsService,
      requestsService,
      location,
    } = this.props;
    const { isFiltersHotelsInMicroservice } = this.state;

    const {
      id,
      label,
      checkin,
      checkout,
      adult,
      customCheckin,
      customCheckout,
      travellersCount,
    } = hotelsService.getParamForHotelDetail();

    const {
      filters: {
        online,
        // breakfast,
        hasCancellation,
      },
      hotelsFilters: {
        Rate: {
          // Breakfast,
          Online,
          HasCancellation,
          Meal,
        },
      },
    } = hotelsService.getHotelsState();

    const { guid } = hotelsService.getSearchState();

    const { requestItem } = requestsService.get();

    const queryTripId = queryString.parse(location.search)?.tripId;

    // const breakfastFilter = isFiltersHotelsInMicroservice ? Breakfast : breakfast;
    const onlineFilter = isFiltersHotelsInMicroservice ? Online : online;
    const hasCancellationFilter = isFiltersHotelsInMicroservice ? HasCancellation : hasCancellation;
    const currentHotelId = hotelId || id;
    const requestItemId = requestItem.Id > 0 ? requestItem.Id : null;
    const query = {
      CheckinDate: checkin,
      CheckoutDate: checkout,
      GuestsCount: adult,
      RequestItemId: requestItemId,
      TravellersCount: travellersCount,
      RoomCount: SELECTTRAVELLERS[travellersCount].find(item => item.value === adult)?.roomCount,
      Label: label,
      online: onlineFilter,
      breakfast: prepareQueryMeal(Meal),
      hasCancellation: hasCancellationFilter,
      RedirectSearchId: guid,
      RedirectSearchType: null,
      ...hotelsService.prepareCustomTime(customCheckin, customCheckout),
      tripId: queryTripId,
    };

    const pathname = `/search/hotel/${currentHotelId}`;
    const search = queryString.stringify(query);

    if (defaultLink) {
      const searchLink = history.createHref({
        pathname,
        search: queryString.stringify(query),
      });

      return `${window.location.origin}${searchLink}`;
    }

    // eslint-disable-next-line newline-before-return
    return this.props.history.createHref({
      pathname,
      search,
    });
  };

  handleSearchByRadius = () => {
    const { hotelsService: { mapStateToRadiusSearchObject, searchByRadius } } = this.props;
    const { rightsBuyTrip, accountTravelPolicy, travelPolicyAllList, isFiltersHotelsInMicroservice } = this.state;

    const settings = {
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    };

    // @ts-ignore
    searchByRadius(mapStateToRadiusSearchObject(isFiltersHotelsInMicroservice), settings, isFiltersHotelsInMicroservice);
  };

  handleSearchSubMenu = () => {
    this.searchGeneral();

    const { hotelsService } = this.props;
    const searchSettings = hotelsService.getSearchState();

    if (searchSettings.travellersCount > 1) {
      MainAnalytic.send(MainAnalytic.CATEGORY.HOTELS, MainAnalytic.ACTIONS.HOTELS.MULTIPLEROOMSSEARCH, {
        label: SELECTTRAVELLERS[searchSettings.travellersCount].find(({ value }) => value === searchSettings.adult)?.label || '',
      });
    }

    const action = hotelsService.isRegion()
      ? MainAnalytic.ACTIONS.HOTELS.UPDATESEARCHBYREGION
      : MainAnalytic.ACTIONS.HOTELS.UPDATESEARCHBYNAME;
    MainAnalytic.send(MainAnalytic.CATEGORY.HOTELS, action, {
      label: searchSettings.region.label,
      value: searchSettings.adult,
    });
  };

  handleSwitchMap = ({ isMapView }: { isMapView: boolean }) => {
    const action = isMapView ? MainAnalytic.ACTIONS.HOTELS.OPENLISTMAP : MainAnalytic.ACTIONS.HOTELS.CLOSELISTMAP;
    MainAnalytic.send(MainAnalytic.CATEGORY.HOTELS, action);
  };

  handleClickToActionLink = () => {
    MainAnalytic.send(MainAnalytic.CATEGORY.HOTELS, MainAnalytic.ACTIONS.HOTELS.GETLINK);
  };

  handleCopyToClipboard = () => {
    MainAnalytic.send(MainAnalytic.CATEGORY.HOTELS, MainAnalytic.ACTIONS.HOTELS.COPYLINK);
  };

  handleShowHotelOnMap = () => {
    MainAnalytic.send(MainAnalytic.CATEGORY.HOTELS, MainAnalytic.ACTIONS.HOTELS.SHOWDEHOTELFROMMAP);
  };

  render() {
    const {
      hotelsService,
      newHotelsService,
      notificationService,
      favoriteService,
      userSessionService,
      history,
      requestsService,
      aggregationId,
      accountSettingsService,
      popupsService,
      featureFlagsService,
      appService,
    } = this.props;

    const {
      loading,
      loadingRegion,
      travelPolicyList,
      isFiltersHotelsInMicroservice,
      isShownContext,
    } = this.state;

    const { request, requestItem } = requestsService.get();
    const requestId = request.Id;
    const requestItemId = requestItem.Id;
    const requestItemComment = request.Comment;

    return (
      <HotelsResult
        loading={ loading || loadingRegion }
        hotelsService={ hotelsService }
        newHotelsService={ newHotelsService }
        notificationService={ notificationService }
        favoriteService={ favoriteService }
        userSessionService={ userSessionService }
        appService={ appService }
        history={ history }
        travelPolicyList={ travelPolicyList }
        generateLink={ this.generateLink }
        isFavoriteLastPath={ this.isFavoriteLastPath }
        requestId={ requestId }
        aggregationId={ aggregationId }
        requestItemId={ requestItemId }
        requestItemComment={ requestItemComment }
        onSwitchMap={ this.handleSwitchMap }
        onClickToActionLink={ this.handleClickToActionLink }
        onCopyToClipboard={ this.handleCopyToClipboard }
        onSearchSubMenu={ this.handleSearchSubMenu }
        onSearchByRadius={ this.handleSearchByRadius }
        onShowHotelOnMap={ this.handleShowHotelOnMap }
        isFiltersHotelsInMicroservice={ isFiltersHotelsInMicroservice }
        accountSettingsService={ accountSettingsService }
        popupsService={ popupsService }
        isShownContext={ isShownContext }
        onSetShownContext={ this.handleShownContext }
        featureFlagsService={ featureFlagsService }
      />
    );
  }
}

export { HotelsRegionResult };
