import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Text, Tooltip, Stars, Price, BookMark } from 'new-ui';

import { getText, getTextArray } from '../../../../../i18n';

import { ImageGallery } from '../../../../components/ImageGallery/ImageGallery';
import { NoPhoto } from '../../../../components/NoPhoto';
import { Rating } from '../../../../components/Rating';
import AgencyFee from '../../../../components/AgencyFee';
import { HotelItemRate } from '../HotelItemRate';

import toDecline from '../../../../bi/utils/toDecline';
import { preloadOrigUrl } from '../../../../bi/utils/images';
import { calculatePriceValue } from '../../../../bi/utils/price';
import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import HotelsService from '../../../../bi/services/hotels';
import AppService from '../../../../bi/services/app';

import { IHotelsFilters, PreparedVariant } from '../../../../bi/services/hotels/types';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './style.module.css';

import smarthotelMapSvg from '../styles/images/smarthotel-map.svg';

const LABELS = {
  SPECIAL_OFFER: getText('hotels:regionResult.item.specialOffer'),
  DAYS_DECLINE: getTextArray('hotels:regionResult.item.daysDecline'),
  CORPORATE_TARIFF: getText('hotels:regionResult.item.corporateTariff'),
  CHOOSE_ROOM: getText('hotels:regionResult.item.chooseRoom'),
  GUARANTEED: getText('hotels:hotelResult.guaranteed'),
  DISTANCE_TO_CENTER: (distance: string) => getText('hotels:regionResult.item.distanceToCenter', { distance }),
  DISTANCE_TO_POINT: (distance: string) => getText('hotels:regionResult.item.distanceToPoint', { distance }),
};

interface HotelMapItemProps {
  ind: number,
  item: PreparedVariant
  hotelUrl: string,
  handleSaveStats(): void,
  diffDays: number,
  aggregationId: number | null,
  query: { roomCount: number, travellersCount: number },
  animationClass?: string,
  hotelsService: HotelsService,
  favorite?: boolean,
  contract?: boolean,
  warning?: boolean,
  isFiltersHotelsInMicroservice: boolean,
  hotelsFilters: IHotelsFilters,
  appService: AppService,
}

const HotelMapItem = ({
  ind,
  item,
  warning = false,
  contract = false,
  favorite = false,
  hotelUrl,
  handleSaveStats,
  diffDays,
  aggregationId,
  query: { roomCount, travellersCount },
  animationClass = '',
  hotelsService,
  isFiltersHotelsInMicroservice,
  hotelsFilters: {
    Rate: {
      Recommended,
    },
  },
  appService,
}: HotelMapItemProps) => {
  const {
    static: {
      Thumbnail,
      Rating: rating,
      HotelName,
      DistanceFromCenter,
      DistanceFromPoint,
      Stars: stars,
      IsSmartHotel,
      HotelId,
    },
    rate,
    rate: {
      Total,
      AgentFee,
    },
    isContract,
    isPriceGuaranteed,
  } = item;
  const [imageValid, setImageValid] = useState(false);

  const ref = useRef(null);

  const handlePreloadImg = useCallback(
    async () => {
      const res = await preloadOrigUrl(Thumbnail);

      if (!!ref.current && !!res) {
        setImageValid(!!res);
      }
    },
    [Thumbnail, ref],
  );

  useEffect(() => {
    handlePreloadImg();
  }, [Thumbnail, handlePreloadImg, ref]);

  const renderDaysAmount = () => {
    if (diffDays === 1 && roomCount < 1) return null;

    const textDays = toDecline(diffDays, LABELS.DAYS_DECLINE);

    return (
      <Text type='NORMAL_16' color='gray'>
        /{diffDays} {textDays}
      </Text>
    );
  };

  const handleLinkClick = () => {
    handleSaveStats();

    if (!isFiltersHotelsInMicroservice || !Recommended) return;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.HOTELS.SEARCH_RESULTS_HOTEL_RECOMMEND_RATE, {
      idHotel: HotelId,
      rateName: rate.RoomInfo.Name?.toLowerCase(),
      rateSum: rate.Total,
    });
  };

  const renderImage = () => {
    const items = [{ original: Thumbnail }];
    const imageStyles = {
      minHeight: '140px',
      maxHeight: '230px',
      objectFit: 'cover',
    };

    return (
      <div className={ styles['img-wrapper'] }>
        <ImageGallery
          showThumbnails={ false }
          items={ items }
          originalImageStyles={ imageStyles }
        />
      </div>
    );
  };

  const renderNoPhoto = () => (
    <div className={ styles['no-photo'] }>
      <NoPhoto />
    </div>
  );

  const imageContent = imageValid ? renderImage() : renderNoPhoto();

  const ratingContent = !!rating && !!rating.Value && (
    <div className={ styles.rating }>
      <Link
        to={ `${hotelUrl}&Reviews=true` }
        onClick={ handleLinkClick }
        target='_blank'
      >
        <Rating value={ rating } />
      </Link>
    </div>
  );

  const renderPriceHtml = (totalPrice: number) => {
    const { agentMode } = appService.get();
    const preparedPrice = !agentMode ? totalPrice + AgentFee : totalPrice;

    return (
      <Price
        value={ calculatePriceValue(preparedPrice, travellersCount, roomCount) }
        marginSmall
        type='bold_16'
        color='gray'
      />
    );
  };

  const wrapClasses = [styles.wrap];
  const itemClasses = [styles.item];

  if (animationClass) {
    wrapClasses.push(animationClass);
  }

  if (isFiltersHotelsInMicroservice) {
    itemClasses.push(styles.item_rate);
  }

  if (favorite && !contract) {
    itemClasses.push(styles.favorite);
  }

  if (contract) {
    itemClasses.push(styles.contract);
  }

  if (!isPriceGuaranteed && ind === 0) {
    wrapClasses.push(styles['wrap-bookmark']);
  }

  const smartIconHtml = IsSmartHotel && aggregationId && (
    <img className={ styles['smart-icon'] } src={ smarthotelMapSvg } alt='Smarthotel' />
  );

  const warningHtml = warning && <div className={ styles.warning } />;

  const renderContractTooltip = () => (
    <div className={ styles['price-tooltip-container'] }>
      <div className={ styles.row }>
        <Text type='NORMAL_14_130' className={ styles.text }>
          {LABELS.SPECIAL_OFFER}
        </Text>
      </div>
    </div>
  );

  const renderNameHotelContract = () => (
    <Tooltip position={ 'top' } renderContent={ renderContractTooltip }>
      <Text type='NORMAL_16' color='gray' className={ styles.title }>
        <span>{HotelName}</span>
      </Text>
    </Tooltip>
  );

  const renderNameHotelDefault = () => (
    <Text type='NORMAL_16' color='gray' className={ styles.title }>
      {HotelName}
    </Text>
  );

  const renderNameHotel = () =>
    (isContract ? (
      renderNameHotelContract()
    ) : (
      renderNameHotelDefault()
    ));

  const renderHeader = () => (
    <div className={ styles.header }>
      <div className={ styles['properties-container'] }>
        {smartIconHtml}
        <Stars className={ styles.stars } count={ stars } />
      </div>
      {ratingContent}
      <Link
        target='_blank'
        onClick={ handleLinkClick }
        to={ hotelUrl }
        className={ styles.image_link }
      >
        { imageContent }
      </Link>
    </div>
  );

  const renderAgentFee = () => {
    const { agentMode } = appService.get();

    if (!isSmartAgent) {
      return null;
    }

    return (
      <AgencyFee
        fee={ AgentFee }
        agentMode={ agentMode }
      />
    );
  };

  const renderActions = () => (
    <div className={ styles.actions }>
      <div className={ styles.price_wrapper }>
        <div className={ styles['days-amount'] }>
          {renderPriceHtml(Total)}
          {renderDaysAmount()}
        </div>
        { renderAgentFee() }
      </div>
      <div>
        <Link
          to={ hotelUrl }
          onClick={ handleLinkClick }
          target='_blank'
        >
          <Button qaAttr={ QA_ATTRIBUTES.hotels.result.search.chooseRoom } type='textual-medium'>
            { LABELS.CHOOSE_ROOM }
          </Button>
        </Link>
      </div>
    </div>
  );

  const renderRate = () => {
    if (!isFiltersHotelsInMicroservice) return null;

    return (
      <HotelItemRate
        rate={ item.rate }
        isMapView
        containerClassName={ styles.rate_wrap }
      />
    );
  };

  const renderContent = () => {
    const {
      filters: {
        radius: {
          custom,
        },
      },
      radiusCustom,
    } = hotelsService.getHotelsState();

    const customRadiusValue = isFiltersHotelsInMicroservice ? radiusCustom : custom;
    const distanceFromPoint = customRadiusValue ? DistanceFromPoint : DistanceFromCenter;
    const currentDistance = isFiltersHotelsInMicroservice ? DistanceFromCenter : distanceFromPoint;
    const distance = customRadiusValue
      ? LABELS.DISTANCE_TO_POINT(currentDistance.toString())
      : LABELS.DISTANCE_TO_CENTER(currentDistance.toString());

    return (
      <div className={ styles.content }>
        <div className={ styles.info }>
          <Link
            to={ hotelUrl }
            onClick={ handleLinkClick }
            target='_blank'
            className={ `${styles['name-wrapper']}` }
          >
            { renderNameHotel() }
          </Link>

          <Text
            type='NORMAL_14'
            color='gray'
            className={ styles.distance }
          >
            { distance }
          </Text>
        </div>
        { renderActions() }
        { renderRate() }
      </div>
    );
  };

  const renderBookMark = () => {
    if (!isPriceGuaranteed || aggregationId || isSmartAgent) return null;

    return <div className={ styles['bookmark-wrapper'] }><BookMark text={ LABELS.GUARANTEED } /></div>;
  };

  return (
    <div className={ wrapClasses.join(' ') } ref={ ref }>
      { renderBookMark() }
      <div className={ itemClasses.join(' ') }>
        { warningHtml }
        { renderHeader() }
        { renderContent() }
      </div>
    </div>
  );
};

export { HotelMapItem };
