import React, { Component } from 'react';
import { Button, Text, Tooltip } from 'new-ui';

import { getText } from '../../../../../i18n';

import AirlineBonus from '../../../../components/AirlineBonus';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import parseJsonString from '../../../../bi/utils/parseJsonString';
import getAirlineSameCompany from '../../../../bi/utils/getAirlineSameCompany';
import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { ActionAirProps, ActionAirState } from './types';

import styles from '../../index.module.css';

const LABELS = {
  FIX_PRICE: getText('note:airAction.fixPrice'),
  TO_CART: getText('note:airAction.toCart'),
};

class ActionAir extends Component<ActionAirProps, ActionAirState> {
  static defaultProps = {
    onCreateReservation: () => {},
  };

  constructor(props: ActionAirProps) {
    super(props);

    const { item } = props;

    let currentBonus = null;
    let sameAirlineCompany = null;

    if (item.ServiceType === SERVICETYPE.AIR) {
      const { Metadata: { Fare: { Bonus } }, Routes } = parseJsonString(item.Data);

      currentBonus = Bonus || null;
      sameAirlineCompany = getAirlineSameCompany(Routes);
    }

    this.state = {
      currentBonus,
      sameAirlineCompany,
    };
  }

  handleRedirect = () => {
    const { item: { Id }, history, onCreateReservation } = this.props;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.RESERVE_AIR_NOTE);

    history.push(`/note/booking/${Id}`);

    if (onCreateReservation) {
      onCreateReservation();
    }
  };

  renderAnchorButton = () => {
    const { workspaceService } = this.props;
    const { isDisabledAccount, bookStatus } = workspaceService;
    const msg = bookStatus && bookStatus.DisabledAccount ? bookStatus.DisabledAccount.msg : '';

    return (
      <div className={ 'sw-tooltip-wrapper' }>
        <Tooltip
          show={ isDisabledAccount }
          renderContent={ () => (
            <div className={ styles['tooltip-content'] }>
              <Text>{ msg }</Text>
            </div>
          ) }
        >
          <Button
            type='primary'
            disabled={ isDisabledAccount }
            onClick={ this.handleRedirect }
            qaAttr={ QA_ATTRIBUTES.note.lockPrice }
          >
            { LABELS.FIX_PRICE }
          </Button>
        </Tooltip>
      </div>
    );
  };

  renderAddToCart = () => {
    const { foul, moveToCart } = this.props;

    return !foul ? (
      <div className={ styles['reserved-actions'] }>
        <Button
          type='secondary'
          className={ styles.reserved_button }
          onClick={ moveToCart }
        >
          { LABELS.TO_CART }
        </Button>
      </div>
    ) : null;
  };

  renderBonus = () => {
    const { currentBonus, sameAirlineCompany } = this.state;
    const { workspaceService: { isDemo } } = this.props;

    if (!currentBonus || !sameAirlineCompany || !isDemo || (isSmartAgent && isDemo)) return null;

    return (
      <AirlineBonus
        bonus={ currentBonus }
        sameAirlineCompany={ sameAirlineCompany }
      />
    );
  };

  render() {
    const { item, reservedHotel } = this.props;

    const isFlag = item.ServiceType === SERVICETYPE.AIR || item.IsReserved
      ? item.IsReserved
      : !reservedHotel;

    const html = isFlag ? this.renderAddToCart() : this.renderAnchorButton();

    return (
      <>
        { this.renderBonus() }
        <div className={ styles.button }>
          { html }
        </div>
      </>
    );
  }
}

export default ActionAir;
