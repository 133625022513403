// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Text, PROPS, Price } from 'new-ui';
import { getText } from '../../../../../i18n';

import { getAirlineType, getPriceWithCertificate } from '../../../../bi/utils/airline';
import { dateWithoutMoment, textualMonthPattern } from '../../../../bi/utils/formatDate';
import { groupBy } from '../../../../bi/utils/groupBy';
import { getPaidBaggageInfo } from './utils/getPaidBaggageInfo';

import { VALID_SYMBLOL } from '../../../../bi/constants/cart';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { AIRLINENAMES, ADDITIONAL_SERVICES } from '../../../../bi/constants/airline';

import AirlineItem from '../../../../components/AirlineItem';
import HotelItem from '../../../../components/HotelItem';
import TransferItem from '../../../../components/TransferItem';
import TrainItem from '../../../../components/TrainItem';
import Timer from '../../../../components/Timer';
import EmployeeSimpleList from '../../../../components/EmployeeSimpleList';
import AeroexpressItem from '../../../../components/AeroexpressItem';
import AnalyticsSelect from '../../../../components/AnalyticsSelect';
import { TaxiVoucherItem } from '../../../../components/TaxiVoucheriItem';
import { InsuranceItem } from '../../../../components/InsuranceItem';

import styles from './styles/index.module.css';

const LABELS = {
  SELECTION_SEATS: getText('checkout:item.selectionSeats'),
  SELECTION_ADD_BAGGAGE: getText('checkout:item.selectionBaggage'),
  SEATS: {
    ONE: getText('checkout:item.seats.one'),
    TWO: getText('checkout:item.seats.more'),
  },
  SEAT: getText('checkout:item.seat'),
  BAGGAGE: getText('checkout:item.baggage'),
  ADD_FEE: getText('cart:addFee:itemLayout:addFee'),
  ADD_FEE_PRICE: (price) => getText('cart:addFee:itemLayout:price', { price }),
};

class CheckoutItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    allowedEmployees: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    documentType: PropTypes.object.isRequired,
    notepadService: PropTypes.object.isRequired,
    checkoutService: PropTypes.object.isRequired,
    isMulti: PropTypes.bool.isRequired,
    customAnalytics: PropTypes.array.isRequired,
    cartEmployeeBirthday: PropTypes.bool.isRequired,
    isPersonalBonusCards: PropTypes.bool.isRequired,
    qaAttrPrice: PropTypes.string,
  };

  static defaultProps = {
    qaAttrPrice: '',
  };

  state = {
    itemData: JSON.parse(this.props.item.Data),
    metaData: JSON.parse(this.props.item.Metadata),
    showTicketBullits: true,
  };

  handleTimeIsUp = item => {
    this.props.notepadService.updateTimeIsUp(item);
  };

  handleCheckAirlineName = name => {
    const { itemData } = this.state;

    if (!itemData && !itemData.Routes[0] && !itemData.Routes[0].Segments[0]) return false;

    const airlineType = getAirlineType(itemData.Routes[0].Segments[0]);

    return itemData.Routes[0].Segments[0][airlineType].Name === name;
  };

  renderTimer = () => {
    const { item, checkoutService } = this.props;

    if (item.IsReserved && item.ServiceType === SERVICETYPE.AIR && item.BookDeadline && this.handleCheckAirlineName(AIRLINENAMES.POBEDA)) {
      return (
        <div className={ styles.timer }>
          <Timer
            deadLine={ item.BookDeadline }
            currentTime={ checkoutService.get().serverTime }
            onTimeIsUp={ () => this.handleTimeIsUp(item) }
          />
        </div>
      );
    }

    return null;
  };

  getFreeSeatsData = ({ Segments }) => {
    const freeSeatsGroupedBySegments = Segments.reduce((acc, { AdditionalServices = [] }) => {
      const freeSeats = AdditionalServices.filter(({ Type, Service }) => Type === ADDITIONAL_SERVICES.Seat && Service.Price === 0);

      if (!freeSeats.length) {
        return acc;
      }

      return [...acc, freeSeats];
    }, []);

    if (!freeSeatsGroupedBySegments.length) {
      return {
        caption: null,
        data: null,
      };
    }

    const hasOneSegmentWithOneSeat = freeSeatsGroupedBySegments.length === 1 && freeSeatsGroupedBySegments[0].length === 1;

    const label = hasOneSegmentWithOneSeat ? LABELS.SEATS.ONE : LABELS.SEATS.TWO;

    const freeSeatsAsString = freeSeatsGroupedBySegments
      .map(seats => seats.map(({ Service: { Number } }) => Number).join(', '))
      .join(' \\ ');

    return {
      caption: label,
      data: freeSeatsAsString,
    };
  };

  renderCustomAnalytics = () => {
    const {
      customAnalytics,
      item: { UserAnalytics = [] },
    } = this.props;

    const analyticsList = customAnalytics.reduce((acc, analytics) => {
      const value = analytics.Values.find(({ Id }) => UserAnalytics.includes(Id));

      if (!value) {
        return acc;
      }

      return [
        ...acc,
        <div key={ analytics.Id } className={ styles.custom_analytics_item }>
          <AnalyticsSelect
            readonly
            onSet={ () => {} }
            onUnset={ () => {} }
            analytics={ analytics }
            value={ value }
            selectedClassName={ styles.custom_analytics_item_selected }
          />
        </div>,
      ];
    }, []);

    return <div className={ styles.custom_analytics }>{analyticsList}</div>;
  };

  renderItem = () => {
    const { item } = this.props;
    const { itemData, metaData, showTicketBullits } = this.state;

    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        let pnr = '';

        if (item.IsReserved && metaData.PNR && this.handleCheckAirlineName(AIRLINENAMES.POBEDA)) {
          pnr = metaData.PNR;
        }

        return itemData.Routes.map(route => (
          <AirlineItem
            key={ route.ID }
            route={ route }
            meta={ itemData.Metadata }
            pnr={ pnr }
            showSegments
            getFreeSeatsData={ this.getFreeSeatsData }
            showTicketBullits={ showTicketBullits }
          />
        ));
      }
      case SERVICETYPE.HOTEL: {
        return (
          <HotelItem
            item={ itemData }
            additionalData={ item.AdditionalData }
          />
        );
      }
      case SERVICETYPE.TRANSFER: {
        return <TransferItem item={ itemData } />;
      }
      case SERVICETYPE.TRAIN: {
        return <TrainItem isCancellation item={ itemData } />;
      }
      case SERVICETYPE.AEROEXPRESS: {
        return <AeroexpressItem item={ itemData } />;
      }
      case SERVICETYPE.TAXI_VOUCHER: {
        return <TaxiVoucherItem item={ itemData } />;
      }
      case SERVICETYPE.INSURANCE: {
        return <InsuranceItem item={ itemData } />;
      }
    }

    return null;
  };

  renderTransferViewers = () => {
    const { item: { ServiceType } } = this.props;

    if (ServiceType !== SERVICETYPE.TRANSFER) return null;

    const { itemData: { Viewers } } = this.state;

    if (!Viewers) return null;

    const viewersListHtml = Viewers.map((number, idx) => (
      <div key={ `${styles.viewer}_${idx}` } className={ styles.viewer }>
        {number}
      </div>
    ));

    return (
      <div className={ styles.viewers }>{ viewersListHtml }</div>
    );
  };

  renderEmployees = () => {
    const {
      item,
      allowedEmployees,
      isMulti,
      documentType,
      projects,
      cartEmployeeBirthday,
      isPersonalBonusCards,
    } = this.props;

    return (
      <EmployeeSimpleList
        item={ item }
        allowedEmployees={ allowedEmployees }
        isMulti={ isMulti }
        documentType={ documentType }
        projects={ projects }
        cartEmployeeBirthday={ cartEmployeeBirthday }
        isPersonalBonusCards={ isPersonalBonusCards }
        readOnlyCompany
      />
    );
  };

  renderAdditionalService = (label, description) => (
    <Text
      color='gray'
      type='NORMAL_12'
      className={ styles.additional_info }
    >
      { label } {description}
    </Text>
  );

  renderEmployeePaidAddServices = (additionalInfo, index) => {
    const { allowedEmployees, item, isMulti, documentType, projects, isPersonalBonusCards } = this.props;
    const { TravellerId, Type: typeId, Service: { Number, Description } } = additionalInfo;

    const isAdditionalSeats = typeId === ADDITIONAL_SERVICES.Seat;
    const label = isAdditionalSeats ? LABELS.SEAT : LABELS.BAGGAGE;
    const description = isAdditionalSeats ? Number : Description;

    return (
      <EmployeeSimpleList
        item={ {
          ...item,
          EmployeeCartItems: item.EmployeeCartItems.filter(({ EmployeeId }) => EmployeeId === TravellerId),
        } }
        allowedEmployees={ allowedEmployees }
        isMulti={ isMulti }
        documentType={ documentType }
        projects={ projects }
        isPersonalBonusCards={ isPersonalBonusCards }
        index={ index + 1 }
        additionalInfo={ this.renderAdditionalService(label, description) }
      />
    );
  };

  renderPaidServiceWrapper = ({
    keyInd,
    serviceLabel,
    routeTitle,
    customAnalytics,
    services,
    segmentPrice,
  }) => (
    <div className={ styles.paid_seat_wrapper } key={ keyInd }>
      <div className={ styles.item }>
        <Text type={ PROPS.TEXT.TYPES.BOLD_18 }>{ serviceLabel }</Text>
        <Text className={ styles.seat_info } type={ PROPS.TEXT.TYPES.NORMAL_14 }>
          { routeTitle }
        </Text>
      </div>
      { customAnalytics }
      <div className={ styles.passengers }>
        <div className={ styles.employees }>
          {services?.map((segment, indexSegment) => this.renderEmployeePaidAddServices(segment, indexSegment))}
        </div>
        <Price className={ styles.price } value={ segmentPrice } marginSmall type={ PROPS.TEXT.TYPES.BOLD_24 } />
      </div>
    </div>
  );

  renderPaidBaggage = () => {
    const { item } = this.props;
    const { itemData } = this.state;

    if (item.ServiceType !== SERVICETYPE.AIR) {
      return null;
    }

    const paidBaggageInfo = getPaidBaggageInfo({
      itemData,
      labels: LABELS.SELECTION_ADD_BAGGAGE,
    });

    if (!paidBaggageInfo) {
      return null;
    }

    return paidBaggageInfo.map(baggageInfo => this.renderPaidServiceWrapper(baggageInfo));
  };

  renderPaidSeats() {
    const { item } = this.props;

    const { itemData } = this.state;

    if (item.ServiceType !== SERVICETYPE.AIR) {
      return null;
    }

    const paidSeats = itemData.Routes.flatMap(({ Segments }) =>
      Segments.flatMap(({ AdditionalServices = [] }) => AdditionalServices),
    ).filter(service => service.Type === ADDITIONAL_SERVICES.Seat && service.Service.Price > 0);

    if (!paidSeats.length) {
      return null;
    }

    const paidSeatsByFragments = groupBy(paidSeats, ({ SegmentId }) => SegmentId);

    const customAnalytics = this.renderCustomAnalytics();

    return Object.keys(paidSeatsByFragments).map((key, index) => {
      const currentSegment = itemData.Routes.flatMap(({ Segments }) => Segments).find(({ ID }) => ID === Number(key));
      const airlineType = getAirlineType(currentSegment);

      const { ID: airlineId } = currentSegment[airlineType];

      const { FlightNumber, DepartureCityCode, ArrivalCityCode, DepartureCity, ArrivalCity, DepartureTime } = currentSegment;

      const departureDate = dateWithoutMoment(DepartureTime, textualMonthPattern);

      const segmentPrice = paidSeatsByFragments[key].reduce(((acc: number, el) => acc + el.Service.Price), 0);

      const routeTitle = `${airlineId} ${FlightNumber}, ${DepartureCity} (${DepartureCityCode}) - ${ArrivalCity} (${ArrivalCityCode}), ${departureDate}`;

      return this.renderPaidServiceWrapper({
        keyInd: `${currentSegment.ID}_${index}`,
        serviceLabel: LABELS.SELECTION_SEATS,
        routeTitle,
        customAnalytics,
        services: paidSeatsByFragments[key],
        segmentPrice,
      });
    });
  }

  render() {
    const { item, qaAttrPrice } = this.props;
    const {
      itemData: { Routes: routes = [], FlightCertificate, Metadata },
    } = this.state;

    const html = this.renderItem();
    const employeesHtml = this.renderEmployees();
    const additionalServicesPrice = routes
      .flatMap(({ Segments }) => Segments.flatMap(({ AdditionalServices = [] }) => AdditionalServices))
      .reduce((acc, service) => {
        if (service.Type === ADDITIONAL_SERVICES.Seat) {
          return acc + service.Service.Price;
        }

        if (service.Type === ADDITIONAL_SERVICES.Baggage) {
          return acc + service.Service.Price;
        }

        return acc;
      }, 0);

    const isHasCertificate = item.ServiceType === SERVICETYPE.AIR && FlightCertificate;

    const price = item.Price - additionalServicesPrice;
    const count = Metadata ? Metadata.TravellersCount : 1;
    const priceWithCertificate = isHasCertificate ? getPriceWithCertificate(count, price) : price;

    const paidSeats = this.renderPaidSeats();
    const customAnalytics = this.renderCustomAnalytics();
    const paidBaggages = this.renderPaidBaggage();

    const isAddFee = !!item.AgentFee && item.AgentFee !== VALID_SYMBLOL.ZERO;

    const renderAddFee = () => {
      if (!isAddFee) {
        return null;
      }

      return (
        <div
          className={ styles.fee_wrapper }
        >
          <Text
            color='gray'
          >
            { LABELS.ADD_FEE }
          </Text>
          <Text
            color='gray'
          >
            { LABELS.ADD_FEE_PRICE(item.AgentFee)}
          </Text>
        </div>
      );
    };

    return (
      <>
        <div className={ styles.wrapper }>
          <div className={ styles.item }>{html}</div>
          { customAnalytics }
          <div className={ styles.passengers }>
            <div
              className={ styles.employees }
            >
              { employeesHtml }
            </div>
            <div>
              <Price
                qaAttr={ qaAttrPrice }
                className={ styles.price }
                value={ priceWithCertificate }
                marginSmall
                type={ PROPS.TEXT.TYPES.BOLD_24 }
              />
              { renderAddFee() }
            </div>
          </div>
          { this.renderTransferViewers() }
          { this.renderTimer() }
        </div>
        { paidSeats }
        { paidBaggages }
      </>
    );
  }
}

export default CheckoutItem;
