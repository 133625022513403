import { Moment } from 'moment';

import { IAeroexpressPricingResponseMapped } from '../services/aeroexpress/const';

export enum AnalyticTypeClassAeroExpress {
  standard = 'standard',
  business = 'business',
}

interface ITrip {
  airport: string,
  backward_date: string | null,
  count: number,
  departure_date: string,
  direction: string,
  schedule_id: number,
  tariff_type: string,
}
export interface IItem {
  Id: number,
  Price: number,
  PriceOrigin: number,
  ProviderName: string,
  Trip: ITrip,
}

export interface IAeroexpressSearchResult {
  error: string | null,
  items: IAeroexpressPricingResponseMapped[],
  loading: boolean,
}

interface ISearchSettingsRoute {
  code: string,
  isSelected: boolean
  label: string,
  type: string,
}

interface IAeroexpressSearchSchedules {
  dateBack: null,
  dateDeparture: null,
}
export interface ISearchSettings {
  count: number,
  dateBack: Moment,
  dateDeparture: Moment,
  route: ISearchSettingsRoute,
  schedules: IAeroexpressSearchSchedules,
  type: string,
}

interface IScheduleList {
  value: null;
  label: string;
  rolledUpLabel: string;
}

interface IScheduleDate {
  loading: boolean;
  list: IScheduleList[];
  error: null | string;
}

export interface IAeroexpressSearchMenuSchedules {
  dateDeparture: IScheduleDate;
  dateBack: IScheduleDate;
}
export interface IAeroexpressSearchMenuRange {
  min: Moment | null,
  max: Moment | null,
}

export interface IDates {
  from: string,
  to: string,
}

interface ILabel {
  name: string;
  code?: string;
  type: string;
}

export interface ILabelRoute {
  from: ILabel;
  to: ILabel;
}
