import { InsuranceStore } from './store';

import { formatDate, getMoment, isBeforeDate } from '../../utils/formatDate';
import { defineIsValidDate, formatRates, formatRatesToLowerCase, getResRates } from '../../utils/insurance';

import { PATTERN } from '../../constants/dateFormats';
import SERVICETYPE from '../../constants/serviceType';
import { DEFAULT_BOOKED_INSURANCE, LABELS_OF_REFUND } from '../../constants/insurance';

import {
  EnumRateName,
  ICartItem,
  IInsuranceStore,
  IItemInTrip,
  IOrderedInsurance,
  IItemInOrder,
  IListItem,
  InsuranceRateType,
} from '../../types/insurance';

const DEFAULT_TOTAL_PRICE = 0;

class InsuranceService {
  api: any;
  insuranceStore: IInsuranceStore;

  constructor(api: any) {
    this.api = api;
    this.insuranceStore = InsuranceStore;
  }

  fetchPriceOfInsurance = async (
    Id: number,
    isCart: boolean,
    rateNameFromCart: EnumRateName = EnumRateName.DEFAULT,
  ) => {
    const ratesOfInsurance = await this.fetchRatesOfInsuranceFromService(isCart, Id);

    const { resRates, updatedItems = [], choosenRateName = '' } = getResRates(isCart, ratesOfInsurance);

    if (isCart) {
      this.setChoosenRate(rateNameFromCart);
    } else {
      this.insuranceStore.clearSelectedItems();
      const { InsuranceServices } = ratesOfInsurance;

      // если лежит страховка в корзине ставим блокировку на покупку еще одной
      if (choosenRateName) this.insuranceStore.setSelectedItems(updatedItems);

      this.setChoosenRate(choosenRateName as EnumRateName);
      this.setListItems(InsuranceServices);
    }

    const rates = formatRatesToLowerCase(resRates as InsuranceRateType[]);
    const formattedRates = formatRates(rates);

    this.insuranceStore.setRates(formattedRates);
  };

  sendInsuranceToCartService = async (orderedData: IOrderedInsurance, tripId: number | null) => {
    const { orderDate, rate, price, items = [], isCart } = orderedData;
    const rateName = rate.charAt(0).toUpperCase() + rate.slice(1);

    const checkinDate = formatDate(orderDate[0], PATTERN.YEAR_MONTH_DAY_TIME);
    const checkoutDate = formatDate(orderDate[1], PATTERN.YEAR_MONTH_DAY_TIME);

    const itemsToInsured = items.map(item => (typeof item === 'number' ? item : item.TripItemId));
    const cartItems = isCart ? itemsToInsured : [];
    const tripItems = !isCart ? itemsToInsured : [];

    const orderedInsurances = {
      checkinDate,
      checkoutDate,
      rate: rateName,
      price,
      cartItems,
      tripItems,
      tripId,
    };

    try {
      await this.api.insurance.sendInsurancesToCart(orderedInsurances);

      this.insuranceStore.setBookedInsurance(orderedData);
    } catch (e) {
      this.insuranceStore.setAddedInsurance([]);
      this.insuranceStore.setBookedInsurance(DEFAULT_BOOKED_INSURANCE);
    }
  };

  getBlockEditEmployes = (insureditemIds: number[], item: ICartItem) => {
    if (!item.Id) return false;

    const insuredItemsAddedRightNow = this.insuranceStore.addedItemsToInsurance;
    const isInsuredItem = insureditemIds.includes(item.Id);
    const isRecentlyAdded = item.ServiceType !== SERVICETYPE.INSURANCE && insuredItemsAddedRightNow.includes(item.Id);

    return isInsuredItem || isRecentlyAdded;
  };

  setInsuranceSidePanel = (isOpen: boolean) => this.insuranceStore.setInsuranceSidePanel(isOpen);

  setSelectedItems = async ({ Amount, TripItemId }: IItemInTrip) => {
    // Обновление выбранных услуг
    const updatedItems = this.insuranceStore.selectedItems.some(item => item.TripItemId === TripItemId)
      ? this.insuranceStore.selectedItems.filter(item => item.TripItemId !== TripItemId)
      : [...this.insuranceStore.selectedItems, { Amount, TripItemId }];

    this.insuranceStore.setSelectedItems(updatedItems);

    // Вычисление полной цены услуг
    const totalPrice = updatedItems.reduce((total, item) => total + item.Amount, 0);

    await this.defineInsuranceRates(totalPrice);

    this.insuranceStore.setIsSelectedAll();
  };

  setAllItems = async () => {
    this.insuranceStore.setAllItems();

    const totalPrice = this.insuranceStore.selectedItems.reduce((total, item) => total + item.Amount, 0);

    await this.defineInsuranceRates(totalPrice);
  };

  clearSelectedItems = async () => {
    this.insuranceStore.clearSelectedItems();

    await this.defineInsuranceRates(DEFAULT_TOTAL_PRICE);
  };

  setBookedInsurance = (bookedInsurance: IOrderedInsurance) => this.insuranceStore.setBookedInsurance(bookedInsurance);

  setAddedInsurance = (cartIds: number[]) => this.insuranceStore.setAddedInsurance(cartIds);

  setIsValidDates = (valid: boolean) => this.insuranceStore.setIsValiDates(valid);

  setChoosenRate = (rate: EnumRateName) => this.insuranceStore.setChoosenRate(rate);

  deleteBookedInsurance = (ServiceType: string, id: number) => {
    if (ServiceType === SERVICETYPE.INSURANCE) {
      this.insuranceStore.setAddedInsurance([]);
      this.insuranceStore.setBookedInsurance(DEFAULT_BOOKED_INSURANCE);
    }

    const { items } = this.insuranceStore.bookedInsurance;
    const newItems = items.filter((item): item is number => item !== id);

    return this.setBookedInsurance({ ...this.insuranceStore.bookedInsurance, items: newItems });
  };

  checkIsServicesNotRefundable = (OrderItems: IItemInOrder[]) => {
    const isRefundable = OrderItems.some(({ ActualVersion: { ServiceType, JsonData } }: IItemInOrder) => {
      const data = JSON.parse(JsonData);

      if (!data) return false;

      switch (ServiceType) {
        case SERVICETYPE.AIR: {
          const { Fare } = data;

          return Fare ? Fare.IsTicketRefundable === LABELS_OF_REFUND.NOTOFFERED : false;
        }
        case SERVICETYPE.HOTEL: {
          const { Room } = data;

          const currentDate = getMoment();

          return Room ? !Room.FreeCancellation || isBeforeDate(Room.FreeCancellation, currentDate) : false;
        }
        case SERVICETYPE.TRAIN: {
          const { NonRefundable } = data;

          return NonRefundable;
        }
        default:
          return false;
      }
    });

    return isRefundable;
  };

  setListItems = (list: IListItem[]) => {
    const disabledItems = list.reduce((prev: number[], { TripItemId, Data }) => {
      const { AirlineRoutesDates, CheckinDate, CheckoutDate } = JSON.parse(Data);
      const isValidDate = defineIsValidDate(AirlineRoutesDates, [CheckinDate, CheckoutDate]);

      if (!isValidDate) return [...prev, TripItemId];

      return prev;
    }, []);
    this.insuranceStore.setListItems(list, disabledItems);
  };

  fetchRatesOfInsuranceFromService = (isCart: boolean, Id: number) => {
    if (isCart) {
      return this.getPricesOfInsurancesFromService(Id);
    }

    return this.getPricesOfInsurancesInTripsFromService(Id);
  };

  getPricesOfInsurancesFromService = async (Id: number) => {
    const resRates = await this.api.insurance.getPricesOfInsurances(Id);

    return resRates;
  };

  getPricesOfInsurancesInTripsFromService = async (Id: number) => {
    const resRates = await this.api.insurance.getPricesOfInsurancesInTrips(Id);

    return resRates;
  };

  private defineInsuranceRates = async (totalPrice: number) => {
    try {
      const resRates = await this.api.insurance.recalculateRatesOfInsurancesInTrip({ totalPrice });

      // Форматирование и определение пересчитанных тарифов
      const rates = formatRatesToLowerCase(resRates);
      const formattedRates = formatRates(rates);

      this.insuranceStore.setRates(formattedRates);
    } catch (e) {
      const list = this.insuranceStore.listItems.map(({ TripItemId }) => TripItemId);

      this.insuranceStore.setListItems(this.insuranceStore.listItems, list);
    }
  };
}

export default InsuranceService;
