import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Price, Text, TextColor } from 'new-ui';

import { useStores } from '../../../../../../../bi/context';
import { useServices } from '../../../../../../../bi/context/services';
import { MOBX_STORES } from '../../../../../../../bi/context/stores';

import { getText } from '../../../../../../../../i18n';

import OptionButton from '../../../../../../../components/OptionButton';

import { IEmployee } from '../../../../../../../bi/utils/employees';

import SYMBOLS from '../../../../../../../bi/constants/symbols';

import { AirRoute, AirSegment } from '../../../../../../../bi/types/airline';
import { IBaggageRoutes } from '../../../../../../../bi/types/airlineAdditionalServicesBaggage';

import styles from './styles.module.css';

interface IAirlineAdditionalServicesBaggageRowProps {
  baggageRoutes?: IBaggageRoutes[];
  employee: IEmployee;
  everyEmployeeChoosen: boolean;
  resetBaggageSelection: boolean;
  number: number;
  routes: AirRoute[];
  ticketId: number;
  onResetComplete: () => void;
}

const LABELS = {
  SELECT_PASSENGERS: getText('cart:airlineAdditionalService.seats.selectPassengers'),
  WITHOUT_ADD_BAGGAGE: getText('cart:airlineAdditionalService.baggage.withoutAddBaggage'),
};

const DEFAULT_ID = 'id';

const AirlineAdditionalServicesBaggageRow = observer(({
  employee,
  everyEmployeeChoosen,
  number,
  resetBaggageSelection,
  routes,
  ticketId,
  onResetComplete,
  baggageRoutes,
}: IAirlineAdditionalServicesBaggageRowProps) => {
  const {
    airlineBaggageStore: {
      routes: routesStore,
    },
  } = useStores([MOBX_STORES.AIRLINE_BAGGAGE]);

  const {
    airlineBaggageService: {
      updateSelectedBaggage,
    },
  } = useServices(['AirlineBaggage']);

  const { Surname, Name, Patronymic, Id: employeeId } = employee;

  const nameEmployee = `${Surname} ${Name} ${Patronymic}`;

  const preparedBaggageOffers = baggageRoutes?.map(({ BaggageOffers }) => [{
    description: LABELS.WITHOUT_ADD_BAGGAGE,
    offerId: DEFAULT_ID,
    price: 0,
  },
  ...BaggageOffers?.map(({
    Description: description,
    OfferId: offerId,
    Price: price,
  }) => ({
    description,
    offerId,
    price,
  }))]);

  useEffect(() => {
    if (resetBaggageSelection) {
      onResetComplete();
    }
  }, [resetBaggageSelection, onResetComplete]);

  const handleBaggageChoose = (
    routeId: string,
    baggageId: number,
    baggagagePrice: number,
    routeIndex: number,
  ) => {
    if (!everyEmployeeChoosen) return;

    updateSelectedBaggage(
      ticketId,
      employeeId,
      routeId,
      baggageId,
      baggagagePrice,
      routeIndex,
      routes,
    );
  };

  const renderPrice = (price: number, selected: boolean) => {
    const color = selected ? 'default' : 'accent';

    return (
      <>
        <Text type='NORMAL_14' color={ color as TextColor }>
          { SYMBOLS.PLUS.value }&thinsp;
        </Text>
        <Price
          marginSmall
          type='NORMAL_14'
          typeCurrency='NORMAL_14'
          color={ color }
          value={ price }
        />
      </>
    );
  };

  const renderTooltip = () => (
    <Text className={ styles.tooltip } type='NORMAL_14_130' color='white'>
      { LABELS.SELECT_PASSENGERS }
    </Text>
  );

  const renderOptionBaggageButtons = (routeId: string, routeIndex: number) => (
    preparedBaggageOffers?.[routeIndex]?.map(({ offerId, description, price }, index) => {
      const uniqueBaggageId = `${ticketId}_${employeeId}_${routeId}_${routeIndex}`;

      const isCurrent = routesStore?.[uniqueBaggageId]?.id === offerId
      || (!Object.keys(routesStore).includes(uniqueBaggageId) && offerId === DEFAULT_ID);

      return (
        <OptionButton
          key={ `${employeeId}_${routeIndex}_${index}` }
          position='top'
          title={ description }
          isCurrent={ isCurrent }
          disabled={ !everyEmployeeChoosen }
          tooltipContent={ renderTooltip }
          subtitle={ renderPrice(price, isCurrent) }
          onOptionButtonClick={ () => handleBaggageChoose(
            routeId,
            offerId as number,
            price,
            routeIndex,
          ) }
        />
      );
    })
  );

  const getRouteTitle = (segments: AirSegment[]) => {
    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];

    return `${firstSegment.DepartureCity} — ${lastSegment.ArrivalCity}`;
  };

  const renderSegmentInfo = () => routes.map((route: AirRoute, routeIndex: number) => (
    <div className={ styles.segment_info_wrapper } key={ route.ID }>
      <Text className={ styles.segment_info }>
        { getRouteTitle(route.Segments) }
      </Text>
      <div className={ styles.baggage_item }>
        { renderOptionBaggageButtons(route.ID as string, routeIndex) }
      </div>
    </div>
  ),
  );

  return (
    <div className={ styles.wrapper_flight }>
      <div className={ styles.wrapper_baggage }>
        <Text>
          <span className={ styles.wrapper_name_employee }>{number}. {nameEmployee}</span>
        </Text>
        <div className={ styles.wrapper_segment }>
          { renderSegmentInfo() }
        </div>
      </div>
    </div>
  );
});

export { AirlineAdditionalServicesBaggageRow };
