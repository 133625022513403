// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { EmptyPanel, PageLoader, PROPS, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { CartActions } from './components/CartActions';
import { CartWarningApprovalText } from './components/CartWarningApprovalText';
import { CartSuggestHotel } from './components/CartSuggestHotel';
import { CartApproveRequest } from './components/CartApproveRequest';
import { MandatoryCodeErrorDialog } from './components/MandatoryCodeErrorDialog';
import { ApproveErrorDialog } from './components/ApproveErrorDialog';
import { ConsistentCartLists } from './components/ConsistentCart/ConsistentCartLists';
import TripName from '../../components/TripName';
import { BookChildDialog } from '../../components/BookChildDialog';
import AnalyticsBar from '../../components/AnalyticsBar';
import { NormalCart } from './components/NormalCart';
import { AttachingMice } from './components/AttachingMice';
import { WarningBlock } from '../../components/WarningBlock';
import { PaymentMethod } from '../../components/PaymentMethod';
import { Insurance } from '../../components/Insurance';
import { InsuranceSidePanel } from '../../components/Insurance/components/InsuranceSidePanel';

import parseUnix from '../../bi/utils/parseDateTime';
import { MainAnalytic } from '../../bi/utils/analytics';
import getDataForCart, {
  cartHasAirUnderageByProviders,
  cartItemIsNotTotallyFilled,
  getCartByStatus,
  isAirlineEmployeeListValid,
  isCartHasAirChildren,
  s7BonusCardWarning,
} from '../../bi/utils/cart';
import { stringifySearchParams } from '../../bi/utils/convertSearchParams';
import MoneyFormat from '../../bi/utils/money';
import parseJsonString from '../../bi/utils/parseJsonString';
import getAccountId from '../../bi/utils/getAccountId';
import { getMultiTripIds, getMultiTripItems } from '../../bi/utils/common';
import { getDataForEmptyPanel } from '../../bi/utils/searchPanel';
import { isSmartAgent } from '../../bi/utils/env';
import { getPriceAllCertificates } from '../../bi/utils/airline';
import { getPropsFromCart, processInsuranceItemFromCart } from '../../bi/utils/insurance';
import { getInfoForBaggageClearWarning } from '../../bi/utils/getInfoForBaggageClearWarning';
import { getFlagsVisibilityAdditionalServices } from '../../bi/utils/airlineAdditionalServices';

import { SERVICETYPE } from '../../bi/constants/serviceType';
import { CART_ITEM_RENDER_OPTIONS, CART_STATUS } from '../../bi/constants/cart';
import ROUTES from '../../bi/constants/routes';
import { BUYTRIPSACCOUNTRIGHT, BUYTRIPSPERSONALRIGHT } from '../../bi/constants/rights';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import {
  ACCOUNT_IDS_WITH_S7CORPORATE_AND_SEATMAPS,
  ADDITIONAL_SERVICES,
  AIRLINE_PROVIDERS,
} from '../../bi/constants/airline';
import { POPUP_GROUP_TYPES, POPUP_NAME, POPUP_POSITIONS } from '../../bi/constants/popups';
import { METRICS_TYPES } from '../../bi/constants/metrics';
import ANIMATION from '../../bi/constants/animation';
import { AGGREGATORS_ACCOUNT } from '../../bi/constants/accounts';
import { EVENT_STATUSES } from '../../bi/services/events/const';

import { LoadingStatus } from '../../bi/services/utils/network/types';
import { LoadingFields } from '../../bi/services/approvalSchemes';

import { CartPageProps } from './types';

import styles from './styles/index.module.css';

const {
  ICON: {
    TYPES: {
      EMPTY_STATE_BIG_ICONS: { CART },
    },
  },
  TEXT: {
    TYPES: { BOLD_32 },
  },
} = PROPS;

const LABELS = {
  LOADING: getText('cart:loader'),
  DISRUPTED_TP_DIALOG_HEADER_UNLIMITED: getText('cart:disruptedTpDialogHeaderUnlimited'),
  DISRUPTED_TP_DIALOG_HEADER: getText('cart:disruptedTpDialogHeader'),
  UNDERAGE_REQUESTS_ERROR: getText('cart:underageRequestError'),
  UNDERAGE_REQUEST_SUCCESS: getText('cart:underageRequestSuccess'),
  BONUS_CARD_S7_WARNING: getText('cart:s7BonusCardWarning'),
  REMINDER: getText('cart:reminder'),
  EMPTY: getText('cart:empty'),
  CART_TITLE: getText('cart:title'),
  NOTIFICATION: {
    AIR: (locations, departureDate, money) =>
      getText('cart:notifications.note.air', { locations, departureDate, money }),
    HOTEL: (name, city, checkin, checkout, money) =>
      getText('cart:notifications.note.hotel', { name, city, checkin, checkout, money }),
    TRANSFER: (locationFrom, locationTo, money) =>
      getText('cart:notifications.note.transfer', { locationFrom, locationTo, money }),
  },
  YES: getText('cart:yes'),
  NO: getText('cart:no'),
  BAGGAGE_CLEAR_WARNING: getText('cart:mixvelBaggageClearWarning'),
  SERVICES: {
    AIR: getText('trip:services.air'),
  },
};

const CartPage = observer(({
  airlineSeatsService,
  airlineSeatsStore,
  airlineBaggageStore,
  airlineBaggageService,
  airlineAdditionalServices,
  airlineAdditionalServicesStore,
  history,
  cartService,
  cartData,
  aggregationId,
  employeeService,
  formatService,
  notificationService,
  settingsService,
  workspaceService,
  workspaceData,
  userSessionService,
  userData,
  hotelsService,
  airlineService,
  trainsService,
  transferService,
  favoritesService,
  featureFlagsService,
  ffList,
  accountSettingsService,
  tripTagsService,
  approvalSchemesService,
  popupsService,
  sidePanelService,
  bonusProgramService,
  customAnalyticsService,
  travelApprovalService,
  isTravelApproval,
  uiSettingsProtocol,
  metricsService,
  requestsService,
  eventService,
  cloudPaymentService,
  personalPaymentProtocol,
  insuranceService,
}: CartPageProps) => {
  const {
    settingsStore,
    travelApprovalsStore,
    popupsStore,
    eventStore,
    transferStore,
  } = useStores([
    MOBX_STORES.SETTINGS_STORE,
    MOBX_STORES.TRAVEL_APPROVALS,
    MOBX_STORES.POPUPS_STORE,
    MOBX_STORES.EVENT_STORE,
    MOBX_STORES.TRANSFER_STORE,
  ]);

  // delete this shit after delete subscribe
  const [, setForceState] = useState();
  const forceUpdate = useCallback(() => setForceState({}), []);

  const [carts, setCarts] = useState(cartData.carts);
  const [onePopup, setOnePopup] = useState(false);
  const [firstNonTotallyFilledItem, setFirstNonTotallyFilledItem] = useState(cartData.firstNonTotallyFilledItem);
  const [reservationFailedItems, setReservationFailedItems] = useState(cartData.reservationFailedItems);
  const [reservationFailedShow, setReservationFailedShow] = useState(false);
  const [loading, setLoading] = useState(cartData.loading);
  const [tripsByTripIds, setTripsByTripIds] = useState(cartData.tripsByTripIds);
  const [tripsToAddOrder, setTripsToAddOrder] = useState(cartData.tripsToAddOrder);
  const verificationCode = useRef(cartData.verificationCode);
  const [hotelsLoading, setHotelsLoading] = useState(true);

  const [projects, setProjects] = useState(userData.projects);
  const [documentType, setDocumentType] = useState(userData.enums.documents);

  const [suggestedHotel, setSuggestedHotel] = useState(null);
  const [verificationCodeValue, setVerificationCodeValue] = useState('');
  const [buyingEmployee, setBuyingEmployee] = useState(null);
  const [underage, setUnderage] = useState({});
  const [scrolledToNotTotallyFilledItem, setScrolledToNotTotallyFilledItem] = useState(false);
  const [analyticsLoading, setAnalyticsLoading] = useState(false);
  const [showBookChildrenModal, setShowBookChildrenModal] = useState(false);
  const [allItemsAnimatedClass, setAllItemsAnimatedClass] = useState('');
  const [allItemsLoading, setAllItemsLoading] = useState(false);
  const [autocompletedItems, setAutocompletedItems] = useState({});
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [checkClearCart, setCheckClearCart] = useState(false);

  const mounted = useRef(false);
  const cartRef = useRef(null);
  const accountId = getAccountId();
  const init = useRef(false);

  const mandatoryProject = projects.length
    ? carts.some(cart => cart.sources.some(item => item.EmployeeCartItems.every(({ ProjectId, CompanyId, EmployeeId }) => {
      if (ProjectId) return true;

      const { Employee: { Companies } } = item.Employees.find(({ Employee }) => Employee.Id === EmployeeId);

      const company = Companies.find(Company => Company.CompanyId === CompanyId);

      return company?.CompanySettings ? company.CompanySettings.MandatoryProject : false;
    })))
    : false;

  const optionalProject = useRef(ffList.OptionalProject || false);
  const hasVerificationCodeFlag = useRef(ffList.VerificationCode);
  const onlyNewAnalytics = useRef(ffList.OnlyNewAnalytics);

  const unsubscribeFn = useRef(() => {});
  const unsubscribeHotelItemsFn = useRef(() => {});

  const normalCart = useMemo(() => getCartByStatus(carts)(CART_STATUS.NORMAL) || {}, [carts]);

  const location = useLocation();

  useEffect(() => {
    approvalSchemesService.getFactApprovers(normalCart.id);
  }, [normalCart, travelApprovalsStore.chosenApprovedRequest?.Id]);

  const updateHotelItems = ({ loading: loadingHotelItems }) => {
    setHotelsLoading(loadingHotelItems);

    forceUpdate();
  };

  const loadHotelItems = async () => {
    if (!normalCart) {
      return;
    }

    const hotelItems = normalCart.sources.filter(({ ServiceType }) => ServiceType === SERVICETYPE.HOTEL);

    await hotelsService.loadHotelItemsForCart(hotelItems);
  };

  const loadSeatMapsForAirlineItems = async () => {
    if (!normalCart) {
      return;
    }

    const airlineItems = normalCart.sources.filter(({ ServiceType }) => ServiceType === SERVICETYPE.AIR);
    const preparedItems = airlineItems.map(({ Id, Data }) => {
      const JsonData = parseJsonString(Data);

      if (!JsonData) {
        return null;
      }

      const parsedMetadata = Data && parseJsonString(Data);
      const PNR = parsedMetadata.BookData ? parsedMetadata.BookData.PNR : '';

      return {
        PNR,
        ItemId: Id,
        ProviderName: JsonData.ProviderName,
        Routes: JsonData.Routes,
        Class: JsonData.Metadata.Fare.Class,
        RemarkToken: JsonData.Metadata.Fare.RemarkToken,
        FlightToken: JsonData.Metadata.Fare.FlightToken,
      };
    });

    await airlineSeatsService.loadSeatMapsForEntireCart(preparedItems);
  };

  const prepareAirSuggestData = (item) => {
    const { Routes } = JSON.parse(item.Data);

    if (Routes.length > 1) {
      return {
        arrivalCity: null,
        checkin: null,
        airportId: null,
      };
    }

    const firstRouteSegments = Routes[0].Segments;
    const firstRouteLastSegment = firstRouteSegments[firstRouteSegments.length - 1];

    return {
      arrivalCity: firstRouteLastSegment.ArrivalCity,
      checkin: firstRouteLastSegment.ArrivalTime_DateTime,
      airportId: firstRouteLastSegment.ArrivalAirport.ID,
    };
  };

  const prepareTrainSuggestData = (item) => {
    const { DateArrive, To } = JSON.parse(item.Data);

    return {
      arrivalCity: To.City,
      checkin: DateArrive,
    };
  };

  const prepareOneSuggestData = (item) => (() => ({
    [SERVICETYPE.AIR]: () => prepareAirSuggestData(item),
    [SERVICETYPE.TRAIN]: () => prepareTrainSuggestData(item),
  })[item.ServiceType]())();

  const handleValidRequest = () => {
    const { chosenApprovedRequest } = travelApprovalsStore;
    const chosenApprovedRequestId = chosenApprovedRequest?.Id || null;

    if (chosenApprovedRequestId) {
      travelApprovalService.assignApprovalRequestInCartByEmployee(chosenApprovedRequestId, normalCart.id);
    }
  };

  const handleClearCart = async ({ id, sources }) => {
    transferService.deleteTransfers();

    MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.REMOVESERVICEALL, {
      value: sources.length,
    });

    setAllItemsLoading(true);
    setCheckClearCart(true);

    try {
      await cartService.clearCart(id);

      setAllItemsAnimatedClass(ANIMATION.DELETE_CART);
      setTimeout(async () => {
        setAllItemsAnimatedClass('');
        setAllItemsLoading(false);
        cartService.start();

        await cartService.load(id);
      }, 1000);
    } catch {
      setAllItemsLoading(false);
      setAllItemsAnimatedClass('');
    }
  };

  const getFirstItem = ((item) => ({
    [SERVICETYPE.AIR]: () => prepareAirSuggestData(item),
    [SERVICETYPE.TRAIN]: () => prepareTrainSuggestData(item),
  })[item.ServiceType](item));

  const getSuggestData = (items) => {
    const firstItem = getFirstItem(items[0]);

    const isOneArrivalCity = items.slice(1).every((item) => {
      const { arrivalCity } = item.ServiceType === SERVICETYPE.AIR
        ? prepareAirSuggestData(item)
        : prepareTrainSuggestData(item);

      return arrivalCity === firstItem.arrivalCity;
    });

    if (!isOneArrivalCity) {
      return {
        checkin: null,
        airportId: null,
        region: null,
      };
    }

    if (firstItem.ServiceType === SERVICETYPE.AIR) {
      return {
        checkin: firstItem.checkin,
        airportId: firstItem.airportId,
        region: {
          Name: firstItem.arrivalCity,
        },
      };
    }

    const airItem = items.find(({ ServiceType }) => ServiceType === SERVICETYPE.AIR);

    if (airItem) {
      const prepAirItem = prepareAirSuggestData(airItem);

      return {
        checkin: prepAirItem.checkin,
        airportId: prepAirItem.airportId,
        region: {
          Name: prepAirItem.arrivalCity,
        },
      };
    }

    return {
      checkin: firstItem.checkin,
      region: {
        Name: firstItem.arrivalCity,
      },
    };
  };

  const prepareSuggestedHotel = async (recalculateNormalCart) => {
    const ticketItems = recalculateNormalCart.sources.filter(({ ServiceType }) =>
      ServiceType === SERVICETYPE.AIR || ServiceType === SERVICETYPE.TRAIN);

    if (!ticketItems.length) {
      return null;
    }

    if (ticketItems.length === 1) {
      const { arrivalCity, airportId, checkin } = prepareOneSuggestData(ticketItems[0]);

      return {
        checkin,
        airportId: airportId || null,
        isOne: ticketItems[0].ServiceType === SERVICETYPE.AIR,
        region: {
          Name: ticketItems[0].ServiceType === SERVICETYPE.AIR ? arrivalCity : null,
        },
      };
    }

    return getSuggestData(ticketItems);
  };

  const suggestHotel = async (cartsParam) => {
    const recalculateNormalCart = getCartByStatus(cartsParam)(CART_STATUS.NORMAL);

    return !recalculateNormalCart || (recalculateNormalCart && recalculateNormalCart.sources.some(({ ServiceType }) => ServiceType === SERVICETYPE.HOTEL))
      ? null
      : prepareSuggestedHotel(recalculateNormalCart);
  };

  const updateState = async (state) => {
    const { employees } = settingsStore;
    const {
      projects: projectsFromUserSessionService,
      enums: {
        documents,
      },
    } = userSessionService.get();

    const { Rights: { EmployeeId } } = workspaceService.get();

    if (!mounted.current) {
      return;
    }

    const suggestedHotelResult = await suggestHotel(state.carts);

    let employee = buyingEmployee;

    if (workspaceService.canBuyOnlySelf && !buyingEmployee) {
      await settingsService.getEmployeesList();
      employee = employees.find(({ Id }) => Id === EmployeeId);
    }

    setBuyingEmployee(employee);
    setCarts(state.carts);
    setFirstNonTotallyFilledItem(state.firstNonTotallyFilledItem);
    setLoading(state.loading);
    setProjects(projectsFromUserSessionService);
    setDocumentType(documents);
    setSuggestedHotel(suggestedHotelResult);
    setReservationFailedItems(state.reservationFailedItems);
    setReservationFailedShow(!!state.reservationFailedItems.length);
    setUnderage(state.underage);
    setTripsByTripIds(state.tripsByTripIds);
    setTripsToAddOrder(state.tripsToAddOrder);
    setAutocompletedItems(state.autocompletedItems);

    forceUpdate();
  };

  const fetchData = async () => {
    cartService.init();

    const { VerificationCode } = ffList;

    mounted.current = true;
    cartRef.current = null;

    unsubscribeFn.current = cartService.subscribeCart(updateState);
    unsubscribeHotelItemsFn.current = hotelsService.subscribeItems(updateHotelItems);

    bonusProgramService.loadBonuses();

    try {
      customAnalyticsService.getAnalytics();
    } catch {}

    metricsService.startTime(METRICS_TYPES.CART.LOADED);

    await cartService.load(accountId);

    cartService.setMandatoryVerificationCode(VerificationCode);

    tripTagsService.loadAccountTripTags();

    metricsService.endTime();
  };

  const fetchAirlineSeatsData = async () => {
    airlineSeatsService.setLoadingForCart(true);

    try {
      await loadSeatMapsForAirlineItems();
    } catch {}
    airlineSeatsService.setLoadingForCart(false);
  };

  const fetchHotelItems = async () => {
    try {
      await loadHotelItems();
    } catch {}
    hotelsService.setHotelItemsLoading(false);
  };

  const fetchTravelApproval = async () => {
    const { Rights: { UserId } } = workspaceService.get();

    try {
      if (isTravelApproval) {
        await travelApprovalService.getTravelApprovalCart(normalCart, UserId);
      } else {
        travelApprovalService.updateLoading(false);
      }
    } catch {}
  };

  const load = () => {
    fetchData().then(() => fetchTravelApproval());
    fetchAirlineSeatsData();
    fetchHotelItems();
  };

  const { rateName } = processInsuranceItemFromCart(normalCart?.sources);

  useEffect(() => {
    if (!init.current) {
      init.current = true;

      return;
    }

    if (mounted.current && init.current) {
      load();
    }
  }, [location]);

  useEffect(() => {
    const { Rights: { UserId } } = workspaceService.get();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.CART_PAGE_OPENED);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MAIN.MAIN_CARD_OPENED);
    load();

    airlineAdditionalServices.getAdditionalServiceData();

    cloudPaymentService.setAccountId(UserId || accountId);

    return () => {
      mounted.current = false;

      airlineBaggageService.saveBaggageUpdateWarning();
      airlineBaggageService.clearBaggages();

      popupsService.closePopupsByType(POPUP_GROUP_TYPES.SHOWCASE);
      cartService.setMandatoryVerificationCode(false);
      unsubscribeFn.current();
      unsubscribeHotelItemsFn.current();
      travelApprovalService.setResetTravelApprovalsForCartStore();
    };
  }, []);

  useEffect(() => {
    if (scrolledToNotTotallyFilledItem) {
      return;
    }

    if (cartRef.current) {
      const { offsetTop, offsetHeight, clientHeight } = cartRef.current;

      const headerOffset = 130;
      const windowHeight = window.innerHeight;
      const offsetForTravelRequest = 100;

      const cartHeightLessThanWindowHeight = (windowHeight - headerOffset) > clientHeight;
      const toTop = offsetTop - headerOffset;
      const toBottom = offsetTop + offsetHeight - windowHeight + 80;

      const scrollTo = cartHeightLessThanWindowHeight
        ? toTop
        : toBottom;
      const selectScroll = isTravelApproval ? offsetForTravelRequest : scrollTo;

      setScrolledToNotTotallyFilledItem(true);
      window.scroll({ top: selectScroll, behavior: 'smooth' });
    }
  }, [scrolledToNotTotallyFilledItem, cartRef.current]);

  useEffect(() => {
    eventService.getEvents(EVENT_STATUSES.CART);
  }, []);

  // Скрытый релиз страховок
  // useEffect(() => {
  //   if (!normalCart.id) return;

  //   insuranceService.fetchPriceOfInsurance(normalCart.id, true, rateName);
  // }, [rateName, normalCart?.sources?.length]);

  const setCustomAnalytics = async (cartId, analyticsValueId, cartItemId = null) => { // anal
    if (!cartItemId) {
      setAnalyticsLoading(true);

      cartService.setAnalytics(cartId, analyticsValueId);

      return setAnalyticsLoading(false);
    }

    metricsService.startTime(METRICS_TYPES.CART.CUSTOM_ANALYTICS_ITEM.SET);

    const res = await cartService.setAnalyticsForItem(cartItemId, analyticsValueId);

    metricsService.endTime();

    return res;
  };

  const checkValidRequest = async (reqId, cartId) => {
    approvalSchemesService
      .netStore
      .setStatus(LoadingFields.validateCart, LoadingStatus.LOADING);

    if (!isTravelApproval) {
      return null;
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.APPLICATION_CHANGED);

    const CartItemIds = normalCart.sources.map(({ Id }) => Id);
    const data = {
      EmployeeId: null,
      CartItemIds,
      TravelApprovalRequestId: reqId,
    };

    return cartService.setEmployeeInCartItem(data).then(async () => {
      await travelApprovalService.assignApprovalRequestInCart(reqId, cartId);
      await cartService.load(accountId);

      approvalSchemesService
        .netStore
        .setStatus(LoadingFields.validateCart, LoadingStatus.LOADED);
    });
  };

  const moveToNotepad = (item) => {
    const data = JSON.parse(item.Data);

    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        const route = data.Routes[0];
        const locations = `${route.Segments[0].DepartureCity}-${route.Segments[route.Segments.length - 1].ArrivalCity}`;
        const departureDate = parseUnix(data.Routes[0].Segments[0].DepartureTime).format('DD.MM.YYYY');
        const price = data.Price.TotalPrice;

        notificationService.send({
          message: LABELS.NOTIFICATION.AIR(locations, departureDate, MoneyFormat.moneyWithDecimal(price, true)),
          level: 'success',
          onClick: () => history.push(ROUTES.NOTE.MAIN),
        });

        break;
      }
      case SERVICETYPE.HOTEL: {
        const name = data.hotel.Name;
        const city = data.hotel.City;
        const checkin = formatService.date(data.checkin);
        const checkout = formatService.date(data.checkout);

        notificationService.send({
          message: LABELS.NOTIFICATION.HOTEL(name, city, checkin, checkout, MoneyFormat.moneyWithDecimal(item.Price, true)),
          level: 'success',
          onClick: () => history.push(ROUTES.NOTE.MAIN),
        });

        break;
      }
      case SERVICETYPE.TRANSFER: {
        const transfer = data;
        const price = typeof transfer.Price === 'number' ? transfer.Price : transfer.Price.TotalPrice || 0;

        notificationService.send({
          message: LABELS.NOTIFICATION.TRANSFER(transfer.StartPlace.Address, transfer.EndPlace.Address, MoneyFormat.moneyWithDecimal(price, true)),
          level: 'success',
          onClick: () => history.push(ROUTES.NOTE.MAIN),
        });
        break;
      }
    }
  };

  const handleMoveToNotepad = async (item, multiTripId = null) => {
    metricsService.startTime(METRICS_TYPES.CART.NOTE);

    try {
      if (multiTripId) {
        const multiTripItems = getMultiTripItems(normalCart.sources)[multiTripId];

        setSelectedItemId(item.Id);
        setAllItemsLoading(true);

        await cartService.moveToNotepadMulti(multiTripItems);
        multiTripItems.forEach(multiTripItem => moveToNotepad(multiTripItem));
      } else {
        await cartService.moveToNotepad(item);
        moveToNotepad(item);
      }
    } catch (e) {}

    metricsService.endTime();
  };

  const updateNormalCarts = async () => {
    try {
      await cartService.load(accountId);
      handleValidRequest();
    } catch {}
  };

  const resetVerificationCodeValue = () => setVerificationCodeValue('');

  const handleDeleteItem = async ({ Id: id, ServiceType }, cartId, clearCart = true, multiTripId = null) => {
    MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.REMOVESERVICE, {
      label: MainAnalytic.LABELS.CART[ServiceType],
    });

    if (multiTripId) {
      const multiTripIds = getMultiTripIds(normalCart.sources)[multiTripId];

      setSelectedItemId(id);
      setAllItemsAnimatedClass(ANIMATION.DELETE_CART);

      if (normalCart && normalCart.sources.length === multiTripIds.length) {
        return handleClearCart(normalCart);
      }

      return cartService.removeCartMultiItem(multiTripIds, cartId);
    }

    if (normalCart && normalCart.sources.length === 1 && clearCart) {
      return handleClearCart(normalCart);
    }

    resetVerificationCodeValue();

    insuranceService.deleteBookedInsurance(ServiceType, id);

    return cartService.removeOnlyCartItem(id);
  };

  // const handleUpdateCartItemsAfterRemove = ({ Id: id }, cartId) => cartService.updateCartItemsAfterRemove(id, cartId);

  const handleTimeIsUp = (item) => cartService.updateTimeIsUp(item);

  const handleToRequestPurchaseCart = ({ id, sources }, fromApprove = false) => {
    if (!fromApprove && isCartHasAirChildren(sources) && !showBookChildrenModal) {
      return setShowBookChildrenModal(true);
    }

    return cartService.sendUnderageCart(id).then((success) => {
      notificationService.send({
        message: success ? LABELS.UNDERAGE_REQUEST_SUCCESS : LABELS.UNDERAGE_REQUESTS_ERROR,
        level: 'success',
      });
    });
  };

  const handlePurchaseCart = (cart, isUnderage, fromApprove = false) => {
    const { id, sources, isOffline } = cart;
    const { chosenApprovedRequest } = travelApprovalsStore;

    if (isOffline) {
      return cartService.sendToOfflineChat(id);
    }

    if (!fromApprove && isCartHasAirChildren(sources) && !showBookChildrenModal) {
      return setShowBookChildrenModal(true);
    }

    const { autoVerification } = verificationCode.current;

    if (isUnderage) {
      return handleToRequestPurchaseCart(cart, fromApprove);
    }

    if (hasVerificationCodeFlag.current && !autoVerification) {
      cartService.saveVerificationCode(id, verificationCodeValue);
    }

    MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.TRANSITIONTOPAYMENT);

    const chosenApprovedRequestId = chosenApprovedRequest?.Id;

    if (chosenApprovedRequestId) {
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('chosenApprovedRequestId', chosenApprovedRequestId);

      return history.push(`${ROUTES.CART.CHECKOUTSPECIFIC}${id}?${currentUrlParams.toString()}`);
    }

    return history.push(`${ROUTES.CART.CHECKOUTSPECIFIC}${id}`);
  };

  const handleContinuePurchase = (cart) => {
    const { sources } = cart;
    const hasAirWithAdult = cartHasAirUnderageByProviders(sources);
    const action = hasAirWithAdult ? handleToRequestPurchaseCart : handlePurchaseCart;
    action(cart);

    setShowBookChildrenModal(false);
  };

  const resetApproveReservationError = (value) => {
    if (reservationFailedShow && !value) {
      cartService.updateReservationFailedItems([]);
    }

    setReservationFailedShow(value);
  };

  const resetVerificationCodeError = () => cartService.resetVerificationCodeError();

  const handleCheckCode = (code) => {
    setVerificationCodeValue(code);
    cartService.checkCode(code);
  };

  const handleSelectedOptionEmployee = labelType =>
    MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.SELECTOPTIONEMPLOYEE, {
      label: MainAnalytic.LABELS.CART[labelType],
    });

  const handleSelectedEmployee = value => {
    MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.SELECTEMPLOYEE, {
      value,
    });
  };

  const handleAddEmployee = () => {
    resetVerificationCodeValue();

    MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.ADDEMPLOYEE);
  };

  const handleSelectCompany = () => MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.SELECTORGANIZATION);

  const handleSelectProject = () => MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.SELECTPROJECT);

  const handleSelectedDocument = labelType => {
    MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.SELECTDOCUMENT, {
      label: MainAnalytic.LABELS.CART[labelType],
    });
  };

  const handleRemoveEmployee = () => {
    resetVerificationCodeValue();

    MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.REMOVEEMPLOYEE);
  };

  const handleRemoveOptionalEmployee = () => MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.REMOVEOPTIONALEMPLOYEE);

  const handleGoToSearch = (itemData, serviceType, withNumber = true) => {
    const { dateObject } = formatService;

    let service = null;
    let uri = null;
    let mappedItem = null;
    let data = null;

    switch (serviceType) {
      case SERVICETYPE.AIR: {
        service = airlineService;
        uri = ROUTES.SEARCH.AIR;

        const preparedData = airlineService.prepareSearchData(itemData);
        mappedItem = { ...preparedData.mappedItem };
        data = {
          ...preparedData.data,
          withNumber,
        };
        break;
      }
      case SERVICETYPE.HOTEL: {
        const {
          Rate: {
            CustomCheckOutDate: customCheckOut,
            CustomCheckInDate: customCheckIn,
            CheckinDate,
            CheckoutDate,
          },
          GuestsCount: travelers,
        } = itemData;

        service = hotelsService;
        uri = ROUTES.SEARCH.HOTEL;
        data = {
          dateFrom: dateObject(CheckinDate),
          dateTo: dateObject(CheckoutDate),
          travelers,
          timeFrom: customCheckIn ? dateObject(CheckinDate) : null,
          timeTo: customCheckOut ? dateObject(CheckoutDate) : null,
        };
        mappedItem = {
          RegionId: itemData.hotel.RegionId,
          RegionName: itemData.hotel.City,
          Name: itemData.hotel.Name,
        };
        break;
      }
      case SERVICETYPE.TRAIN: {
        service = trainsService;
        uri = ROUTES.SEARCH.TRAIN_RESULTS;
        data = {
          travelers: itemData.Travellers,
          date: dateObject(itemData.DateDepartureLocal),
        };
        mappedItem = {
          CodeStationFrom: itemData.From.Code,
          StationFrom: itemData.From.City,
          CodeStationTo: itemData.To.Code,
          StationTo: itemData.To.City,
          TrainName: itemData.Train.FirmName,
          TrainNumber: itemData.Train.Number,
        };
        break;
      }
      case SERVICETYPE.TRANSFER: {
        service = transferService;
        uri = ROUTES.SEARCH.TRANSFER;
        mappedItem = {
          ...itemData,
          StartPlace: itemData.StartPlace,
          EndPlace: itemData.EndPlace,
        };
        break;
      }
    }

    if (service) {
      service.setNewSearch();
      const searchParams = {
        ...service.getSearchObject(mappedItem, data),
      };

      const searchString = stringifySearchParams(searchParams);
      history.push({ pathname: uri, search: searchString });
    }
  };

  const unsetCustomAnalytics = async (cartId, analyticsId, cartItemId = null) => {
    if (!cartItemId) {
      setAnalyticsLoading(true);

      cartService.unsetAnalytics(cartId, analyticsId);

      return setAnalyticsLoading(false);
    }

    metricsService.startTime(METRICS_TYPES.CART.CUSTOM_ANALYTICS_ITEM.DELETE);

    const res = await cartService.unsetAnalyticsForItem(cartItemId, analyticsId);

    metricsService.endTime();

    return res;
  };

  const getHandlesMetrics = () => {
    const handleMetricStartSelectEmployee = () => metricsService.startTime(METRICS_TYPES.CART.EMPLOYEE.SET);
    const handleMetricStartDeleteEmployee = () => metricsService.startTime(METRICS_TYPES.CART.EMPLOYEE.DELETE);
    const handleMetricStartSelectProject = () => metricsService.startTime(METRICS_TYPES.CART.PROJECT.SET);
    const handleMetricStartDeleteProject = () => metricsService.startTime(METRICS_TYPES.CART.PROJECT.DELETE);
    const handleMetricStartDeleteItem = () => metricsService.startTime(METRICS_TYPES.CART.DELETE_ITEM);
    const handleMetricStartSelectTag = () => metricsService.startTime(METRICS_TYPES.CART.TAGS);

    return {
      handleMetricStartDeleteEmployee,
      handleMetricStartSelectEmployee,
      handleMetricStartSelectProject,
      handleMetricStartDeleteProject,
      handleMetricStartDeleteItem,
      handleMetricStartSelectTag,
      endTime: metricsService.endTime,
    };
  };

  const onAddAnalyticsValue = async (analyticsId, value, cartId = null, cartItemId = null) => {
    const { store: { sortedCustomAnalytics } } = customAnalyticsService;

    let valuesForOnlyNewAnalytics = null;

    if (onlyNewAnalytics.current) {
      const finderAnalytics = sortedCustomAnalytics ? sortedCustomAnalytics.find(({ Id }) => Id === analyticsId) : null;
      valuesForOnlyNewAnalytics = finderAnalytics ? finderAnalytics.Values.find(({ Value }) => Value === value) : null;
    }

    const analyticsValue = valuesForOnlyNewAnalytics || await customAnalyticsService.addAnalyticsValue(analyticsId, value);

    if (cartId && analyticsValue?.Id) {
      await customAnalyticsService.getAnalytics();
      await setCustomAnalytics(cartId, analyticsValue.Id, cartItemId);
    }
  };

  const hasUnsetRequiredTripAnalytics = () => {
    const { store: { requiredCustomAnalytics } } = customAnalyticsService;
    const [requiredTripAnalytics] = requiredCustomAnalytics;

    if (!normalCart) {
      return false;
    }

    const { userAnalytics = [] } = normalCart;

    return requiredTripAnalytics.some(({ Values }) =>
      Values.every(({ Id: valueId }) => !userAnalytics.includes(valueId)),
    );
  };

  const getQAAttrs = () => {
    const {
      selectEmployee,
      inputEmployee,
      employeeSuggestFirstEl,
      selectEmployeeLoading,
      selectEmployeeLoadingSuggest,
      addEmployee,
      passport,
      company,
      department,
      project,
      removeSelectedEmployee,
    } = QA_ATTRIBUTES.cart;

    return {
      select: selectEmployee,
      input: inputEmployee,
      firstEl: employeeSuggestFirstEl,
      loadingEmployee: selectEmployeeLoading,
      loadingSuggest: selectEmployeeLoadingSuggest,
      addEmployee,
      passport,
      company,
      department,
      project,
      removeSelectedEmployee,
    };
  };

  const renderLoading = () => (
    <PageLoader qaAttr={ QA_ATTRIBUTES.cart.loading } text={ LABELS.LOADING } />
  );

  const renderEmpty = () => (
    <EmptyPanel
      searchMenuItems={ getDataForEmptyPanel(uiSettingsProtocol.searchServiceTypeForMenu, accountSettingsService.getBookingTaxi()) }
      alternativeDesign={ isSmartAgent }
      title={ LABELS.EMPTY }
      iconType={ CART }
      qaAttr={ QA_ATTRIBUTES.cart.empty }
    />
  );

  const hasAdditionalServicesShown = (item, seatMapsData) => {
    const { store: { bonuses: { Status } } } = bonusProgramService;
    const { BuyTripAccount, BuyTripPersonal } = workspaceService.rights;
    const { ServiceType, Error, Data } = item;

    const isNotAnAirlineService = ServiceType !== SERVICETYPE.AIR;

    if (isNotAnAirlineService) {
      return false;
    }

    const itemData = parseJsonString(Data);

    // В рамках MVP не показываем выбор места, если пользователь может покупать билеты только по согласованию или тревел-политике.
    const hasIncorrectRights = BuyTripAccount !== BUYTRIPSACCOUNTRIGHT.Unlimited && BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unlimited;
    const hasError = !!Error;
    const hasInvalidEmployeeList = !isAirlineEmployeeListValid(item, itemData);

    if (hasIncorrectRights
      || hasError
      || hasInvalidEmployeeList
    ) {
      return false;
    }

    const pobedaIsNotReserved = !item.IsReserved && itemData.ProviderName === AIRLINE_PROVIDERS.POBEDA;
    const hasNoSeatMapsDataButItsNotUnreservedPobeda = !seatMapsData && !pobedaIsNotReserved;

    if (hasNoSeatMapsDataButItsNotUnreservedPobeda) {
      return false;
    }

    const s7IsCorporate = itemData.ProviderName === AIRLINE_PROVIDERS.S7 && Status === 2;
    const isAllowedToSelectSeatsWithS7Corporate = ACCOUNT_IDS_WITH_S7CORPORATE_AND_SEATMAPS.includes(accountId);

    if (s7IsCorporate && !isAllowedToSelectSeatsWithS7Corporate) {
      return false;
    }

    const { Metadata: { TravellersCount = 0 }, Routes = [] } = itemData;

    const maxAmountOfSeatsToSelect = Routes.reduce((acc, { Segments = [] }) => acc + Segments.length, 0) * TravellersCount;
    const selectedSeats = Routes.reduce((acc, { Segments = [] }) => [
      ...acc,
      ...Segments
        .reduce((seatsAcc, { AdditionalServices = [] }) => [
          ...seatsAcc,
          ...AdditionalServices.filter(({ Type }) => Type === ADDITIONAL_SERVICES.Seat),
        ], []),
    ], []);
    const atLeastOneSelectedSeatIsFree = selectedSeats.some(({ Service: { Price: seatPrice } }) => seatPrice === 0);

    return !(maxAmountOfSeatsToSelect === selectedSeats.length && !atLeastOneSelectedSeatIsFree);
  };

  const renderCartActions = (showButton) => {
    if (!showButton) return null;

    const canPayWithPersonalFunds = personalPaymentProtocol.canPayWithPersonalFunds;
    const isAgregatorCKR = aggregationId === AGGREGATORS_ACCOUNT.CKR;

    const { totalBaggageCost } = airlineBaggageStore;
    const isSelectedBaggageCost = Object.values(totalBaggageCost)?.some((cost) => cost > 0);

    return (
      <CartActions
        hasVerificationCodeFlag={ hasVerificationCodeFlag.current }
        handleCheckCode={ handleCheckCode }
        cart={ normalCart }
        carts={ carts }
        verificationCodeValue={ verificationCodeValue }
        verificationCode={ verificationCode.current }
        analyticsLoading={ analyticsLoading }
        underage={ underage }
        customAnalyticsService={ customAnalyticsService }
        workspaceData={ workspaceData }
        workspaceService={ workspaceService }
        employeeService={ employeeService }
        userSessionService={ userSessionService }
        mandatoryProject={ mandatoryProject }
        approvalSchemesService={ approvalSchemesService }
        handlePurchaseCart={ handlePurchaseCart }
        isTravelApproval={ isTravelApproval }
        cartService={ cartService }
        setLoading={ setLoading }
        handleToRequestPurchaseCart={ handleToRequestPurchaseCart }
        history={ history }
        accountSettingsService={ accountSettingsService }
        isAgregatorCKR={ isAgregatorCKR }
        canPayWithPersonalFunds={ canPayWithPersonalFunds }
        isSelectedBaggageCost={ isSelectedBaggageCost }
      />
    );
  };

  const renderCart = (cart) => {
    const { store: { sortedCustomAnalytics } } = customAnalyticsService;
    const canPayWithPersonalFunds = personalPaymentProtocol.canPayWithPersonalFunds;
    const price = normalCart?.price + getPriceAllCertificates(normalCart.sources);

    const multiTripItems = getMultiTripItems(cart.sources);

    const warningBlockHtml = s7BonusCardWarning(cart.sources) && <WarningBlock
      text={ LABELS.BONUS_CARD_S7_WARNING }
      type='SEMIBOLD_16'
      color='red'
    />;

    const {
      insuredItemIds,
      // isIncludeInsuranceOfCartItems
    } = processInsuranceItemFromCart(cart.sources);
    const itemHtml = cart.sources.map((item, index) => {
      const { Employees, ServiceType, Error, Id, Data } = item;
      const { MultiTripInfo } = parseJsonString(Data);
      const multiTrip = MultiTripInfo?.Id;
      const isBlockEditEmployees = insuranceService.getBlockEditEmployes(insuredItemIds, item);
      const airAdditionalServices = airlineAdditionalServicesStore.airlineAdditionalServices;

      const showFindReplacementBtn = !!Error
        && ServiceType !== SERVICETYPE.AEROEXPRESS
        && ServiceType !== SERVICETYPE.TAXI;

      const multiTripIds = getMultiTripIds(cart.sources)[multiTrip] as unknown as number[];

      const isValidTripIds = multiTripIds?.some(id => id === selectedItemId);

      const currentAllItemsLoading = isValidTripIds && allItemsLoading
        ? allItemsLoading
        : false;

      const currentAllItemsAnimatedClass = isValidTripIds && allItemsAnimatedClass
        ? allItemsAnimatedClass
        : '';

      const showLoaders = checkClearCart ? allItemsLoading : currentAllItemsLoading;

      const showAnimation = checkClearCart ? allItemsAnimatedClass : currentAllItemsAnimatedClass;

      const seatMaps = ServiceType === SERVICETYPE.AIR ? airlineSeatsService.getSeatMapsByItemId(Id) : [];
      const seatMapsData = seatMaps.length ?
        { Price: seatMaps
          .map(({ MinPrice }) => Number(MinPrice))
          .reduce((a, b) => Math.min(a, b)) } :
        null;

      const filteredCustomAnalytics = sortedCustomAnalytics.filter(({ ApplyToTrip }) => !ApplyToTrip);

      const { UserAnalytics = [] } = item;
      const hasUnsetRequiredServiceAnalytics = !filteredCustomAnalytics
        .filter(({ Required, ApplyToTrip }) => !ApplyToTrip && Required)
        .every(({ Values }) => Values.some(({ Id: valueId }) => UserAnalytics.includes(valueId)));

      const setCartRef = r => {
        if (!cartRef.current
          && !hasUnsetRequiredTripAnalytics()
          && (cartItemIsNotTotallyFilled(item, mandatoryProject) || hasUnsetRequiredServiceAnalytics)) {
          cartRef.current = r;

          cartService.setFirstNonTotallyFilledItem(Id);
        }
      };

      const isFirstNonTotallyFilledItem = firstNonTotallyFilledItem && firstNonTotallyFilledItem === Id;

      if (accountSettingsService.getBookingTaxi() && ServiceType === SERVICETYPE.TAXI_VOUCHER) {
        cartService.removeCartItem(item.Id, cart.id);
      }

      const handlesMetrics = getHandlesMetrics();

      const {
        isHideAdditionalSeatsS7,
        isHideAdditionalBaggageMixvel,
      } = getFlagsVisibilityAdditionalServices(item, airAdditionalServices);

      return (
        <NormalCart
          cartRef={ setCartRef }
          key={ `cart_${Id}_${index}` }
          renderOptions={ CART_ITEM_RENDER_OPTIONS }
          item={ item }
          index={ index }
          airlineBaggageStore={ airlineBaggageStore }
          airlineBaggageServiceDeleteOffer={ () => airlineBaggageService.deleteBaggageOffer(item.Id) }
          airlineAdditionalServicesStore={ airlineAdditionalServicesStore }
          isHideAdditionalSeatsS7={ isHideAdditionalSeatsS7 }
          isHideAdditionalBaggageMixvel={ isHideAdditionalBaggageMixvel }
          tripsByTripIds={ tripsByTripIds }
          tripsToAddOrder={ tripsToAddOrder }
          highlightNonFilledEmployee={ isFirstNonTotallyFilledItem }
          buyingEmployee={ buyingEmployee }
          allowedEmployees={ Employees }
          cartId={ cart.id }
          aggregationId={ aggregationId }
          serverTime={ cart.serverTime }
          documentType={ documentType }
          projects={ projects }
          isTravelApproval={ isTravelApproval }
          isBlockEditEmployees={ isBlockEditEmployees }
          // isIncludeInsurance={ isIncludeInsuranceOfCartItems }
          employeeService={ employeeService }
          cartService={ cartService }
          trainsService={ trainsService }
          workspaceService={ workspaceService }
          settingsService={ settingsService }
          hotelsService={ hotelsService }
          transferService={ transferService }
          featureFlagsService={ featureFlagsService }
          accountSettingsService={ accountSettingsService }
          notificationService={ notificationService }
          favoritesService={ favoritesService }
          tripTagsService={ tripTagsService }
          requestsService={ requestsService }
          mandatoryProject={ mandatoryProject }
          optionalProject={ optionalProject.current }
          onMoveToNotepad={ (multiTripId = null) => handleMoveToNotepad(item, multiTripId) }
          onUpdateNormalCarts={ updateNormalCarts }
          onDeleteItem={ (clearCart = true, multiTripId = null) => handleDeleteItem(item, cart.id, clearCart, multiTripId) }
          // onUpdateCartItemsAfterRemove={ () => handleUpdateCartItemsAfterRemove(item, cart.id) }
          onTimeIsUp={ handleTimeIsUp }
          onSelectedEmployee={ handleSelectedEmployee }
          onSelectedOptionEmployee={ handleSelectedOptionEmployee }
          onAddEmployee={ handleAddEmployee }
          onSelectDocument={ handleSelectedDocument }
          onSelectCompany={ handleSelectCompany }
          onSelectProject={ handleSelectProject }
          onRemoveEmployee={ handleRemoveEmployee }
          onRemoveOptionalEmployee={ handleRemoveOptionalEmployee }
          onGoToSearch={ handleGoToSearch }
          onValidRequest={ handleValidRequest }
          onValidRequestChange={ checkValidRequest }
          showFindReplacementBtn={ showFindReplacementBtn }
          sidePanelService={ sidePanelService }
          history={ history }
          seatMapsData={ seatMapsData }
          hasAdditionalServicesShown={ hasAdditionalServicesShown(item, seatMapsData) }
          userSessionService={ userSessionService }
          onHandlesMetrics={ handlesMetrics }
          setCustomAnalytics={ setCustomAnalytics }
          unsetCustomAnalytics={ unsetCustomAnalytics }
          customAnalytics={ filteredCustomAnalytics }
          onAddAnalyticsValue={ (aId, value) => onAddAnalyticsValue(aId, value, cart.id, item.Id) }
          hasUnsetRequiredCustomAnalytics={ hasUnsetRequiredServiceAnalytics && isFirstNonTotallyFilledItem }
          qaAttrNote={ QA_ATTRIBUTES.cart.note }
          qaAttrDelete={ QA_ATTRIBUTES.cart.delete }
          qaAttrTrain={ QA_ATTRIBUTES.cart.train }
          qaAttrHotel={ QA_ATTRIBUTES.cart.hotel }
          qaAttrAir={ QA_ATTRIBUTES.cart.air }
          qaAttrAeroexpress={ QA_ATTRIBUTES.cart.aeroexpress }
          qaAttrPaidSeats={ QA_ATTRIBUTES.cart.paidSeats }
          qaAttrCustomAnalytics={ QA_ATTRIBUTES.cart.customAnalytics }
          qaAttrTags={ QA_ATTRIBUTES.cart.tags }
          qaAttrItemWrapper={ QA_ATTRIBUTES.cart.itemWrapper }
          qaAttr={ getQAAttrs() }
          multiTripItems={ multiTripItems }
          allItemsAnimatedClass={ showAnimation }
          allItemsLoading={ showLoaders }
          normalCartLength={ normalCart.sources.length }
          popupsService={ popupsService }
          autocompletedItems={ autocompletedItems[item.Id] }
        />
      );
    });

    const wrapperClassName = canPayWithPersonalFunds ? styles.two_columns : '';

    return (
      <div data-qa={ QA_ATTRIBUTES.cart.itemsWrapper } className={ wrapperClassName }>
        <div>
          { itemHtml }
          { warningBlockHtml }
        </div>
        <PaymentMethod
          price={ price }
          canPayWithPersonalFunds={ canPayWithPersonalFunds }
        >
          { renderCartActions(canPayWithPersonalFunds) }
        </PaymentMethod>
      </div>
    );
  };

  const renderCustomAnalytics = ({ userAnalytics = [], id: cartId }) => {
    const { store: { sortedCustomAnalytics } } = customAnalyticsService;

    const analyticsWhichAppliesToTrip = sortedCustomAnalytics.filter(({ ApplyToTrip }) => ApplyToTrip);

    return (
      <AnalyticsBar
        withRequiredValidation
        onlyNewAnalytics={ onlyNewAnalytics.current }
        hasUnsetRequiredTripAnalytics={ hasUnsetRequiredTripAnalytics() }
        analyticsList={ analyticsWhichAppliesToTrip }
        cartId={ cartId }
        loading={ analyticsLoading }
        userAnalytics={ userAnalytics }
        onSet={ setCustomAnalytics }
        onUnset={ unsetCustomAnalytics }
        onAddAnalyticsValue={ (aId, value) => onAddAnalyticsValue(aId, value, cartId) }
      />
    );
  };

  const renderAttachingMICE = (cart) => {
    const { linkApplication, setOpenSwitcher, setValueSwitcher } = eventService;
    const { isOpenSwitcher, valueSwitcher, events } = eventStore;
    const { getNoMice } = accountSettingsService;

    const applications = events.map(({ Name, Id, Trips }) => ({
      value: Name,
      id: Id,
      info: !!Trips.length,
    }));

    const showAppInTrip = cart?.sources.every(trip => !!trip.TripId);

    if (!applications.length
      || isSmartAgent
      || showAppInTrip
      || aggregationId
      || getNoMice()) {
      return null;
    }

    return (
      <AttachingMice
        items={ applications }
        linkApplication={ value => linkApplication(value) }
        cartId={ cart.id }
        isOpen={ isOpenSwitcher }
        valueSwitcher={ valueSwitcher }
        setOpenSwitcher={ setOpenSwitcher }
        setValueSwitcher={ setValueSwitcher }
      />
    );
  };

  const renderReminder = () => {
    const { carts: cartItems } = cartService.get();
    const showVSKWarnText = featureFlagsService.getShowVSKWarnText();

    const showRemined = cartItems.some(({ sources }) => sources.some(({ CompanyId }) => !!CompanyId));

    if (!showRemined || !showVSKWarnText) return null;

    return (
      <Text
        className={ styles.reminder }
        type={ PROPS.TEXT.TYPES.NORMAL_16 }
        color={ PROPS.TEXT.COLORS.RED }
      >
        { LABELS.REMINDER }
      </Text>
    );
  };

  const renderAdditionalBaggageClearWarning = () => {
    const isAlreadyBaggageSelected = JSON.parse(localStorage.getItem('isAlreadyBaggageSelected'));

    const routeTitleInfo = getInfoForBaggageClearWarning(normalCart?.sources);

    if (!isAlreadyBaggageSelected
      || !routeTitleInfo
      || Object.keys(routeTitleInfo).length === 0
    ) {
      return null;
    }

    const ticketsInfoElements = Object.values(routeTitleInfo).flatMap((routes, index) =>
      routes.map((routeInfo, routeIndex) => (
        <div key={ `${index}-${routeIndex}` }>
          { LABELS.SERVICES.AIR } { routeInfo }
        </div>
      )),
    );

    const warningText = (
      <>
        <div>{ LABELS.BAGGAGE_CLEAR_WARNING }</div>
        { ticketsInfoElements }
      </>
    );

    return (
      <WarningBlock
        text={ warningText }
        type='SEMIBOLD_16'
        color='red'
      />
    );
  };

  const renderInsurance = () => {
    // Права. Следует ли пользователю показывать страховку
    const { canBuyInsurance, isAdmin } = workspaceService;
    const displayInsurance = featureFlagsService.getDisplayInsurance();

    const isAllowToBuyInsurance = (canBuyInsurance || isAdmin) && !!displayInsurance;
    const isWhiteLabel = uiSettingsProtocol.isWhiteLabel();

    const { id, sources } = normalCart;
    const { validationTransferFields } = transferStore;

    const {
      hasInvalidInsurance,
      cartItemsWIthoutTripsAndInsurances,
      cartItemsIds,
      tripIds,
    } = getPropsFromCart(sources);

    const { minDate, maxDate, unlock } = getDataForCart(cartItemsWIthoutTripsAndInsurances, false);
    const checkinDates = [minDate, maxDate];

    if (!isAllowToBuyInsurance || !hasInvalidInsurance || isWhiteLabel) return null;

    return (
      <Insurance
        cartId={ id }
        cartItems={ cartItemsIds }
        insuranceService={ insuranceService }
        isCart
        isEmptyEmployees={ !unlock }
        onUpdateNormalCarts={ updateNormalCarts }
        orderDate={ checkinDates }
        rateName={ rateName }
        sources={ sources }
        tripIds={ tripIds }
        validationTransferFields={ validationTransferFields }
      />
    );
  };

  const renderNormalCart = () => {
    const canPayWithPersonalFunds = personalPaymentProtocol.canPayWithPersonalFunds;
    const hasBaggageOffers = !!Object.keys(airlineBaggageStore.baggageOffers)?.length;

    return Object.keys(normalCart).length > 0 ? (
      <div className={ styles['normal-cart'] }>
        <Text qaAttr={ QA_ATTRIBUTES.cart.title } type={ BOLD_32 } className={ styles.header }>{ LABELS.CART_TITLE }</Text>
        <TripName
          name={ normalCart.name }
          minDate={ normalCart.minDate }
          maxDate={ normalCart.maxDate }
          onChange={ value => cartService.rename({ name: value, id: normalCart.id }) }
          onClick={ () => handleClearCart(normalCart) }
          eventService={ eventService }
          qaAttrName={ QA_ATTRIBUTES.cart.editName.name }
          qaAttrInput={ QA_ATTRIBUTES.cart.editName.input }
          qaAttrSave={ QA_ATTRIBUTES.cart.editName.success }
          qaAttrClose={ QA_ATTRIBUTES.cart.editName.close }
          qaAttrDeleteAll={ QA_ATTRIBUTES.cart.deleteAll.title }
          qaAttrModalWrapper={ QA_ATTRIBUTES.cart.deleteAll.modal.wrapper }
          qaAttrModalCancel={ QA_ATTRIBUTES.cart.deleteAll.modal.cancel }
          qaAttrModalSuccess={ QA_ATTRIBUTES.cart.deleteAll.modal.success }
          qaAttrModalClose={ QA_ATTRIBUTES.cart.deleteAll.modal.close }
          hasBaggageOffers={ hasBaggageOffers }
          airlineBaggageServiceDeleteOffers={ () => airlineBaggageService.deleteBaggageOffers() }
        />
        { renderAdditionalBaggageClearWarning() }
        <CartApproveRequest
          isTravelApproval={ isTravelApproval }
          id={ normalCart.id }
          history={ history }
          travelApprovalService={ travelApprovalService }
          aggregationId={ aggregationId }
          handleValidRequestOnChange={ checkValidRequest }
        />
        { renderAttachingMICE(normalCart) }
        { renderCustomAnalytics(normalCart) }
        { renderInsurance() }
        { renderCart(normalCart) }
        <CartSuggestHotel
          hotelsService={ hotelsService }
          suggestedData={ suggestedHotel }
          airlineService={ airlineService }
          history={ history }
        />
        { renderReminder() }
        <CartWarningApprovalText />
        { renderCartActions(!canPayWithPersonalFunds)}
        <BookChildDialog
          show={ showBookChildrenModal }
          onClose={ () => setShowBookChildrenModal(false) }
          onContinue={ () => handleContinuePurchase(normalCart) }
        />
      </div>
    ) : renderEmpty();
  };

  const getUnuqiueEmployeesNameFromCarts = () => {
    const employees = carts
      .flatMap(({ sources, status }) => (status === CART_STATUS.NORMAL ? sources : [])
        .flatMap(({ Employees }) => Employees
          .map(({ Employee: { Surname, Name, Patronymic } }) => `${Surname} ${Name} ${Patronymic}`)));

    return Array.from(new Set(employees));
  };

  const renderContent = () => {
    const { popupsState: { add_trip_item_in_cart_popup } } = popupsStore;
    const showApproversCKR = aggregationId === AGGREGATORS_ACCOUNT.CKR;

    if (!onePopup && !add_trip_item_in_cart_popup && !!carts.length) {
      popupsService.addAddingOrderPopup(POPUP_POSITIONS.TOP_RIGHT_ABOVE, POPUP_NAME.ADDING_ORDER_IN_CARD);
      setOnePopup(true);
    }

    return (
      <div className={ styles.carts }>
        <ConsistentCartLists
          cartService={ cartService }
          carts={ carts }
          handlePurchaseCart={ handlePurchaseCart }
          history={ history }
          showApproversCKR={ showApproversCKR }
        />
        { renderNormalCart() }
      </div>
    );
  };

  if (loading || airlineSeatsStore.loadingForCart || hotelsLoading || bonusProgramService.store.bonuses.loading) {
    return renderLoading();
  }

  const uniqueEmployeesNames = getUnuqiueEmployeesNameFromCarts();
  const showMandatoryCodeErrorDialog = !!uniqueEmployeesNames.length && hasVerificationCodeFlag.current && verificationCode.current.isVerificationCodeError;
  const addingOrderOverly = !!carts.length && !popupsStore.popupsState.add_trip_item_in_cart_popup;

  const overlayHtml = addingOrderOverly && <div className={ styles.overlay } />;

  return (
    <div className={ styles.wrapper }>
      { renderContent() }
      <ApproveErrorDialog
        reservationFailedItems={ reservationFailedItems }
        show={ reservationFailedShow }
        toogleDialog={ resetApproveReservationError }
      />
      <InsuranceSidePanel
        insuranceService={ insuranceService }
      />
      <MandatoryCodeErrorDialog
        show={ showMandatoryCodeErrorDialog }
        toogleDialog={ resetVerificationCodeError }
        uniqueEmployeesNames={ uniqueEmployeesNames }
      />
      { overlayHtml }
    </div>
  );
});

export default CartPage;
