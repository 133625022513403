// @ts-nocheck
import React, { Component, createRef } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import * as queryString from 'query-string';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  ItemPanel,
  PROPS,
  Select,
  Text,
  Textarea,
  Tooltip,
} from 'new-ui';

import { getText } from '../../../../../i18n';

import { bindServices, UseServicesReturnType } from '../../../../bi/context/services';
import { servicesProtocolsContext } from '../../../../bi/context/context';
import { MOBX_STORES } from '../../../../bi/context/stores';
import { withStores } from '../../../../bi/context';

import { sendStatOnDeleteOption } from '../../../../bi/services/cart/approveAnalytic';

import parseJsonString from '../../../../bi/utils/parseJsonString';
import getAirlineSameCompany from '../../../../bi/utils/getAirlineSameCompany';
import { checkAirUnderageEmployees, checkUnderageEmployees } from '../../../../bi/utils/employees';
import {
  addSelectedTripTags,
  defineTextOfLabelOfTooltip,
  getCountOfTravellers,
  isOptionalEmployeeItem,
} from '../../../../bi/utils/cart';
import { getDetailsAmount } from '../../../../bi/utils/taxi';
import {
  fullFormYear,
  fullFormYearTime,
  getDateFromTimezoneForm,
  getLocalMomentObject,
  getMoment,
  getTimeFromTimezoneForm,
  isMoment,
  momentObject,
  momentObjectUTC,
} from '../../../../bi/utils/formatDate';
import {
  getPriceWithCertificate,
  getVoidTimerStartsAfterBookingString,
  getVoidUntilEndOfDayString,
} from '../../../../bi/utils/airline';
import getAccountId from '../../../../bi/utils/getAccountId';
import { getCurrenMultiTriptIds } from '../../../../bi/utils/common';
import MoneyFormat from '../../../../bi/utils/money';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import ANIMATION from '../../../../bi/constants/animation';
import { SPECIFIC_CART_TYPES } from '../../../../bi/constants/cart';
import { BUYTRIPSACCOUNTRIGHT, BUYTRIPSPERSONALRIGHT } from '../../../../bi/constants/rights';
import { ApprovalWarning } from '../../../../bi/constants/travelApproval';
import ROUTES from '../../../../bi/constants/routes';
import { ADDITIONAL_SERVICES } from '../../../../bi/constants/airline';
import { PLACETYPE, TRANSFERFIELDS } from '../../../../bi/constants/transfer';
import { FILTER_TYPE } from '../../../../bi/constants/transfers';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { POPUP_NAME } from '../../../../bi/constants/popups';

import { isSmartAgent, svgColor } from '../../../../bi/utils/env';
import {
  getFreeFlightCertificateInCart,
  getTransformationCompanySettings,
  SettingsType,
} from '../../../../bi/utils/accountSettings';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import ItemLayout from '../../../../components/ItemLayout';
import AirlineItem from '../../../../components/AirlineItem';
import HotelItem from '../../../../components/HotelItem';
import TransferItem from '../../../../components/TransferItem';
import TrainItem from '../../../../components/TrainItem';
import AeroexpressItem from '../../../../components/AeroexpressItem';
import { InsuranceItem } from '../../../../components/InsuranceItem';
import EmployeeList from '../../../../components/EmployeeList';
import { TransferFields } from '../../../../components/TransferFields';
import AirlineBonus from '../../../../components/AirlineBonus';
import AnalyticsSelect from '../../../../components/AnalyticsSelect';
import { TripTags } from '../../../../components/TripTags';
import { ToNote } from '../../../../components/ToNote';
import { NameErrorsTravelPolicy } from '../../../../components/NameErrorsTravelPolicy';
import { NameErrorsTravelApproval } from '../../../../components/NameErrorsTravelApproval';
import TaxiDetailsPrice from '../../../../components/TaxiDetailsPrice';
import { TaxiVoucherItem } from '../../../../components/TaxiVoucheriItem';
import { RoundIconWithTooltip } from '../../../../components/RoundIconWithTooltip';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import DialogRules from '../../../../components/RulesDialog';
import { BookMarkAddingTrip } from '../../../../components/BookMarkAddingTrip';
import LayoutTimer from '../../../../components/layoutTimer';
import { AirlineAdditionalServices } from '../AirlineAdditionalServices';
import TransferDialog from '../TransferDialog';
import { AddInTripDialog } from '../AddInTripDialog';
import FlightInfoComponent from '../MinimalFares/FlightInfoComponent';
import { AbsencePatronymicInForeignPassport } from './components/AbsencePatronymicInForeignPassport';

import { LoadingStatus } from '../../../../bi/services/utils/network/types';
import { LoadingFields } from '../../../../bi/services/approvalSchemes';

import styles from './styles/index.module.css';

const {
  BUTTON: {
    TYPES: {
      PRIMARY,
      TEXTUAL,
    },
  },
  TEXT: {
    TYPES: {
      NORMAL_14,
      NORMAL_14_130,
      NORMAL_16_140,
    },
    COLORS: {
      WHITE,
      GRAY,
      RED,
      GREEN,
    },
  },
  ICON: {
    TYPES: {
      QUESTION,
      TRASHCAN,
      REQUEST,
      WARNING,
      MENU: {
        TRIPS,
      },
    },
    COLORS: {
      BLUE,
    },
  },
} = PROPS;

const LABELS = {
  FINDREPLACEMENT: getText('cart:normalCart.findReplacement'),
  TONOTEPAD: getText('cart:normalCart.toNote'),
  DELETE: getText('cart:normalCart.delete'),
  DELETEITEM: getText('cart:normalCart.deleteItem'),
  TIPTOAPPROVER: getText('cart:normalCart.tipToApprover'),
  SAVE: getText('common:save'),
  CONFIRM_ACTION: getText('cart:normalCart.confirmAction'),
  DIALOG_DELETE_DESCRIPTION: getText('cart:normalCart.deleteMultiDescription'),
  DIALOG_TO_NOTE_DESCRIPTION: getText('cart:normalCart.toNoteMultiDescription'),
  TIME_FOR_RESERVE_CANCELLATION: getText('cart:normalCart.timeForReserveCancellation'),
  REQUSET_NUMBER: getText('cart:normalCart.requestNumber'),
  BOOKING: getText('cart:normalCart.booking_com'),
  SEAT: getText('cart:normalCart.seat'),
  SEAT_SELECTION: getText('cart:normalCart.seatSelection'),
  ADD_TO_TRIP: getText('cart:addingTripDialog.title'),
  REQUEST_1C: {
    NOT_SELECTED: getText('aeroexpress:menu.notSelect'),
    USER_NOT_SELECTED: getText('cart:addingTripDialog.notSelectedUser'),
    NUMBER: getText('components:requestItem.requestNumber'),
  },
  ADD_TO_TRIP_TOOLTIP: getText('cart:addingTripDialog.tooltip'),
  ADD_TO_TRIP_INSURED: getText('cart:addingTripDialog.insured'),
  ANNULATION: voidTimeLimitString => getText('cart:normalCart.annulation', { voidTimeLimitString }),
  TRANSFER_NOTICE: getText('cart:normalCart.transferNotice'),
  RULES_TARIFF: getText('cart:normalCart.rulesTariff'),
  SEATS: {
    ONE: getText('cart:normalCart.seats.one'),
    MORE: getText('cart:normalCart.seats.more'),
  },
  TP_CONTRAVENTION_REASON: {
    CAUSE: getText('cart:normalCart.tpContraventionReason.cause'),
    CHOOSE_CAUSE: getText('cart:normalCart.tpContraventionReason.chooseCause'),
  },
  AIR_CERTIFICATE: {
    TITLE: travellersCount => getText('cart:normalCart.airCertificate.title', { travellersCount }),
    TOOLTIP_ONE: getText('cart:normalCart.airCertificate.tooltip.one'),
    TOOLTIP_TWO: getText('cart:normalCart.airCertificate.tooltip.two'),
  },
  ADDITIONAL_SERVICES: {
    ADDITIONAL_INFO: getText('cart:normalCart.additionalServices.additionalServiceInfo'),
    ADDITONAL: getText('cart:normalCart.additionalServices.additional'),
    SERVICES: getText('cart:normalCart.additionalServices.services'),
  },
  C: 'С',
  YES: getText('common:yes'),
  NO: getText('common:no'),
  ADD_TAX: getText('hotels:hotelResult.item.rate.andTax'),
};

const qaAttrFields = {
  price: 'price',
  note: 'note',
  delete: 'delete',
  selectEmployee: 'selectEmployee',
  icon: 'icon',
};

const BOOKED_STATUS = 0;

@withStores([MOBX_STORES.TRAVEL_APPROVALS, MOBX_STORES.POPUPS_STORE])
@observer
class NormalCart extends Component {
  static contextType = servicesProtocolsContext;
  services: UseServicesReturnType<'ApprovalSchemes'>;

  static propTypes = {
    cartRef: PropTypes.func,
    cartId: PropTypes.number.isRequired,
    aggregationId: PropTypes.number,
    item: PropTypes.object.isRequired,
    buyingEmployee: PropTypes.object,
    tripsByTripIds: PropTypes.array,
    tripsToAddOrder: PropTypes.array,
    documentType: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    employeeService: PropTypes.object.isRequired,
    cartService: PropTypes.object.isRequired,
    onHandlesMetrics: PropTypes.object,
    workspaceService: PropTypes.object.isRequired,
    settingsService: PropTypes.object.isRequired,
    sidePanelService: PropTypes.object.isRequired,
    hotelsService: PropTypes.object,
    transferService: PropTypes.object,
    notificationService: PropTypes.object,
    favoritesService: PropTypes.object,
    featureFlagsService: PropTypes.object.isRequired,
    accountSettingsService: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    tripTagsService: PropTypes.object.isRequired,
    onMoveToNotepad: PropTypes.func.isRequired,
    onDeleteItem: PropTypes.func.isRequired,
    onTimeIsUp: PropTypes.func.isRequired,
    serverTime: PropTypes.object.isRequired,
    allowedEmployees: PropTypes.array.isRequired,
    isBlockEditEmployees: PropTypes.bool,
    readonly: PropTypes.bool,
    showFindReplacementBtn: PropTypes.bool,
    showDeleteBtn: PropTypes.bool,
    confirmDelete: PropTypes.bool,
    mandatoryProject: PropTypes.bool,
    optionalProject: PropTypes.bool,
    renderOptions: PropTypes.object,
    onSelectedEmployee: PropTypes.func,
    onSelectedOptionEmployee: PropTypes.func,
    onAddEmployee: PropTypes.func,
    onSelectDocument: PropTypes.func,
    onSelectCompany: PropTypes.func,
    onSelectProject: PropTypes.func,
    onRemoveEmployee: PropTypes.func,
    onRemoveOptionalEmployee: PropTypes.func,
    onGoToSearch: PropTypes.func,
    specificCartType: PropTypes.string,
    // seatMapsData: PropTypes.object,
    isTravelApproval: PropTypes.bool,
    // hasAdditionalServicesShown: PropTypes.bool,
    userSessionService: PropTypes.object.isRequired,
    onLoadSpecificCart: PropTypes.func,
    highlightNonFilledEmployee: PropTypes.bool,
    customAnalytics: PropTypes.array,
    setCustomAnalytics: PropTypes.func,
    unsetCustomAnalytics: PropTypes.func,
    onAddAnalyticsValue: PropTypes.func,
    onValidRequest: PropTypes.func,
    onValidRequestChange: PropTypes.func,
    hasUnsetRequiredCustomAnalytics: PropTypes.bool,
    qaAttrCustomAnalytics: PropTypes.object,
    qaAttrTags: PropTypes.string,
    stores: PropTypes.shape({
      travelApprovalsStore: PropTypes.object.isRequired,
      popupsStore: PropTypes.object.isRequired,
    }),
    qaAttrTrain: PropTypes.object,
    qaAttrHotel: PropTypes.object,
    qaAttrAir: PropTypes.object,
    qaAttrAeroexpress: PropTypes.object,
    qaAttrPaidSeats: PropTypes.object,
    qaAttrItemWrapper: PropTypes.string,
    qaAttr: PropTypes.object,
    index: PropTypes.number.isRequired,
    multiTripItems: PropTypes.object.isRequired,
    isSpecificCart: PropTypes.bool,
    onUpdateNormalCarts: PropTypes.func,
    allItemsAnimatedClass: PropTypes.string,
    allItemsLoading: PropTypes.bool,
    normalCartLength: PropTypes.number.isRequired,
    popupsService: PropTypes.object.isRequired,
    airlineBaggageServiceDeleteOffer: PropTypes.func,
    isHideAdditionalSeatsS7: PropTypes.bool,
    isHideAdditionalBaggageMixvel: PropTypes.bool,
  };

  static defaultProps = {
    cartRef: () => {},
    serverTime: getMoment(),
    readonly: false,
    showFindReplacementBtn: false,
    showDeleteBtn: false,
    confirmDelete: false,
    mandatoryProject: false,
    optionalProject: false,
    isTravelApproval: false,
    renderOptions: {},
    hotelsService: {},
    transferService: {},
    notificationService: {},
    favoritesService: {},
    aggregationId: null,
    buyingEmployee: null,
    tripsByTripIds: [],
    onSelectedEmployee: () => {},
    onSelectedOptionEmployee: () => {},
    onAddEmployee: () => {},
    onSelectDocument: () => {},
    onSelectCompany: () => {},
    onSelectProject: () => {},
    onRemoveEmployee: () => {},
    onRemoveOptionalEmployee: () => {},
    onGoToSearch: () => {},
    specificCartType: '',
    // seatMapsData: null,
    onLoadSpecificCart: () => {},
    // hasAdditionalServicesShown: false,
    highlightNonFilledEmployee: false,
    customAnalytics: [],
    setCustomAnalytics: () => {},
    unsetCustomAnalytics: () => {},
    onAddAnalyticsValue: () => {},
    onValidRequest: () => {},
    onValidRequestChange: () => {},
    hasUnsetRequiredCustomAnalytics: false,
    qaAttrCustomAnalytics: {},
    qaAttrTags: '',
    qaAttrTrain: {},
    qaAttrHotel: {},
    qaAttrAir: {},
    qaAttrAeroexpress: {},
    qaAttrPaidSeats: {},
    qaAttrItemWrapper: '',
    qaAttrEmployeeFirstEl: '',
    qaAttr: null,
    multiTripItems: {},
    onHandlesMetrics: {
      handleMetricStartSelectEmployee: () => {},
      handleMetricStartDeleteEmployee: () => {},
      handleMetricStartSelectProject: () => {},
      handleMetricStartDeleteProject: () => {},
      handleMetricStartDeleteItem: () => {},
      handleMetricStartSelectTag: () => {},
      endTime: () => {},
    },
    isSpecificCart: false,
    onUpdateNormalCarts: () => {},
    allItemsAnimatedClass: '',
    allItemsLoading: false,
    autocompletedItems: null,
  };

  getPersonalBonusCards = this.props.accountSettingsService.getPersonalBonusCards();

  constructor(props, context) {
    super(props);
    const { item, workspaceService, showFindReplacementBtn } = props;

    this.services = bindServices(context, ['ApprovalSchemes']);

    const itemData = parseJsonString(item.Data);
    const itemMetadata = parseJsonString(item.Metadata);

    let currentBonus = null;
    let sameAirlineCompany = null;

    if (item.ServiceType === SERVICETYPE.AIR) {
      const { Metadata: { Fare: { Bonus } }, Routes } = itemData;

      currentBonus = Bonus || null;
      sameAirlineCompany = getAirlineSameCompany(Routes);
    }

    const selectedTripTags = item.Tags ? item.Tags.map(({ Id }) => Id) : [];
    const bookingCom = item.ServiceType === SERVICETYPE.HOTEL && workspaceService.isAccountOfBookingCom;
    const isBookCertificate = showFindReplacementBtn ? false : (itemData.FlightCertificate || false);

    this.state = {
      accountId: getAccountId(),
      itemData,
      itemMetadata,
      loading: false,
      animatedClass: '',
      showDeleteDialog: false,
      showToNoteDialog: false,
      showRulesAirDialog: false,
      showAddInTripDialog: false,
      showTransferNoticeDialog: false,
      tp: {
        contraventionReason: item.Reason || '',
        isEditing: !item.Reason,
      },
      currentBonus,
      sameAirlineCompany,
      selectedTripTags,
      bookingCom,
      isBookCertificate,
      isBookCertificateLoading: false,
      loadingAddedTrip: false,
      lastAddedEmployee: null,
      TimeDeparture: '',
      TimeArrival: '',
      AllowBooking: null,
      request1c: null,
      showRemoveItemDialog: false,
    };

    this.tpReasonRef = createRef();
  }

  componentDidMount() {
    this.updateRequests();
    this.load1CSuggests();
  }

  componentDidUpdate(prevProps) {
    const { item, item: { Tags, Data, Employees } } = this.props;

    const {
      item: {
        Employees: prevEmployees,
        Data: prevData,
        Tags: prevTags,
      },
    } = prevProps;

    if (Employees !== prevEmployees || Data !== prevData) {
      this.load1CSuggests();
    }

    if (item.Data !== prevData) {
      this.setState({
        itemData: parseJsonString(item.Data),
        itemMetadata: parseJsonString(item.Metadata),
      });
    }

    if (Tags !== prevTags) {
      const selectedTripTags = Tags ? Tags.map(({ Id }) => Id) : [];

      this.setState({ selectedTripTags });
    }
  }

  load1CSuggests = () => {
    const {
      item: { Id, ServiceType },
      cartService,
      accountSettingsService: {
        store: { salary1C },
      },
    } = this.props;

    if (salary1C) {
      this.updateCAPreCallLoading(LoadingFields.oneC);

      cartService.loadSuggestedRequests(Id, ServiceType).then(
        () => this.updateCAPreCallLoading(LoadingFields.oneC, true),
      ).catch(() => this.updateCAPreCallLoading(LoadingFields.oneC, true));
    }
  };

  updateRequests = () => {
    const { autocompletedItems } = this.props;

    this.setState({ request1c: autocompletedItems });
  };

  redirectToEmployee = (employee) => {
    const { item, history, onAddEmployee } = this.props;

    if (isSmartAgent && !employee) {
      const travelers = true;

      return history.push({
        pathname: ROUTES.EMPLOYEE,
        search: queryString.stringify({ cartItem: item.Id, travelers }),
      });
    }

    if (employee) {
      return history.push({ pathname: `${ROUTES.EMPLOYEE}${employee.Id}` });
    }

    onAddEmployee();

    return history.push({
      pathname: ROUTES.EMPLOYEE,
      search: queryString.stringify({ cartItem: item.Id }),
    });
  };

  handleBookCertificate = async (isBookCertificate) => {
    const { item: { Id }, cartService, onLoadSpecificCart } = this.props;
    const { accountId } = this.state;

    try {
      this.setState({ isBookCertificateLoading: true });
      await cartService.bookFlightCertificateFromCart(Id, isBookCertificate);
      await cartService.load(accountId);
      await onLoadSpecificCart();
      this.setState({ isBookCertificate, isBookCertificateLoading: false });
    } catch (e) {
      this.setState({ isBookCertificateLoading: false });
    }
  };

  redirectToProject = (employeeId) => {
    const { item: { Id: cartItem }, history } = this.props;

    history.push({
      pathname: ROUTES.COSTCENTERS,
      search: queryString.stringify({ cartItem, employeeId }),
    });
  };

  setLoading = (value) => this.setState({ loading: value });

  handleAnimationEnd = () => this.setState({ animatedClass: '' });

  moveToNotepad = async () => {
    const { itemData } = this.state;
    const { onMoveToNotepad, onUpdateNormalCarts, item } = this.props;

    const multiTrip = itemData?.MultiTripInfo?.Id;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.ADD_TO_NOTEPAD, { service: item?.ServiceType });

    this.setLoading(true);

    await onMoveToNotepad(multiTrip);

    this.setState({ animatedClass: ANIMATION.MOVETONOTE }, () => {
      setTimeout(async () => {
        this.setLoading(false);
        await onUpdateNormalCarts();
      }, 800);
    });
  };

  handleMoveToNotepad = () => {
    const { itemData } = this.state;
    const { item: { ServiceType }, transferService } = this.props;
    const { BookId } = itemData;

    const multiTrip = itemData?.MultiTripInfo?.Id;

    if (ServiceType === SERVICETYPE.TRANSFER) {
      transferService.deleteTransferCart({ BookId });
    }

    if (multiTrip) {
      this.setToNoteDialog(true);
    } else {
      this.moveToNotepad();
    }
  };

  addSelectedTripTags = (newTags) => {
    const { selectedTripTags } = this.state;

    if (!newTags) return;

    const tagsArray = addSelectedTripTags(selectedTripTags, newTags);

    this.setState({ selectedTripTags: tagsArray });
  };

  handleAddEmployee = (employee) => {
    const {
      item,
      cartService,
      multiTripItems,
      buyingEmployee,
      isTravelApproval,
      workspaceService,
      stores: { travelApprovalsStore: { chosenApprovedRequest } },
    } = this.props;
    const { itemData } = this.state;
    const { BookDeadline } = item;

    const multiTrip = itemData?.MultiTripInfo?.Id;
    const cartItemsIds = [item.Id];
    const data = {
      EmployeeId: employee.Id,
      CartItemIds: cartItemsIds,
      TravelApprovalRequestId: chosenApprovedRequest?.Id,
    };

    const isNotUseMultiTrip = !!buyingEmployee && buyingEmployee.Id && !(isTravelApproval && !BookDeadline);

    const updateEmployee = () => {
      this.addSelectedTripTags(employee.Tags);

      if (item.ServiceType === SERVICETYPE.TRANSFER && !workspaceService.canBuyOnlySelf && buyingEmployee) {
        if (!itemData.TableSignature && itemData.AdditionalServices.includes(FILTER_TYPE.TABLE)) {
          const nameEmployee = `${employee.Surname} ${employee.Name} ${employee.Patronymic}`;

          this.setState({
            itemData: {
              ...this.state.itemData,
              TableSignature: nameEmployee,
            },
          });
        }

        this.handleUpdateTransfer();
      }
    };

    if (multiTrip && !isNotUseMultiTrip) {
      const multiTripIds = getCurrenMultiTriptIds(multiTripItems[multiTrip]);

      return cartService.addMultiEmployee(multiTripIds, employee.Id).then(list => {
        updateEmployee();

        return list[0];
      });
    }

    return cartService.setEmployeeInCartItem(data).then((res) => {
      updateEmployee();

      return res;
    });
  };

  updateCAPreCallLoading = (name: LoadingFields, loaded: boolean = false) => this
    .services
    .approvalSchemesService
    .netStore
    .setStatus(name, loaded ? LoadingStatus.LOADED : LoadingStatus.LOADING);

  updateCA = () => this.services.approvalSchemesService.getFactApprovers(
    this.props.cartId,
  );

  setEmployeeInCartItem = async (employeeId) => {
    const {
      item,
      multiTripItems,
      buyingEmployee,
      isTravelApproval,
      cartService,
      stores: { travelApprovalsStore: { chosenApprovedRequest } },
    } = this.props;
    const { itemData } = this.state;
    const { BookDeadline } = item;

    this.updateCAPreCallLoading(LoadingFields.setEmployee);

    const isNotUseMultiTrip = !!buyingEmployee && buyingEmployee.Id && !(isTravelApproval && !BookDeadline);

    let cartItemsIds = [item.Id];
    const multiTrip = itemData?.MultiTripInfo?.Id;

    if (multiTrip && !isNotUseMultiTrip) {
      cartItemsIds = getCurrenMultiTriptIds(multiTripItems[multiTrip]);
    }

    const data = {
      EmployeeId: employeeId,
      CartItemIds: cartItemsIds,
    };

    if (chosenApprovedRequest?.Id) {
      data.TravelApprovalRequestId = chosenApprovedRequest.Id;
    }

    await cartService.setEmployeeInCartItem(data);

    await this.updateCA();
    this.updateCAPreCallLoading(LoadingFields.setEmployee, true);
  };

  handleRemoveEmployee = ({ Id }) => {
    const { item, cartService, multiTripItems } = this.props;
    const { itemData } = this.state;

    this.updateCAPreCallLoading(LoadingFields.deleteEmployee);

    const updateEmployee = () => {
      if (item.ServiceType === SERVICETYPE.TRANSFER) {
        this.setState({
          itemData: {
            ...this.state.itemData,
            TableSignature: '',
          },
        }, () => this.handleUpdateTransfer());
      }
    };

    let cartItemsIds = [item.Id];

    const multiTrip = itemData?.MultiTripInfo?.Id;

    if (multiTrip) {
      cartItemsIds = getCurrenMultiTriptIds(multiTripItems[multiTrip]);
    }

    return cartService.removeEmployee(cartItemsIds, Id).then(async () => {
      updateEmployee();
      await this.updateCA();

      this.updateCAPreCallLoading(LoadingFields.deleteEmployee, true);
    });
  };

  handleResetNonFilledItem = () => this.props.cartService.resetFirstNonTotallyFilledItem();

  deleteItem = async (clearCart = true) => {
    const {
      item: { ServiceType },
      confirmDelete,
      onDeleteItem,
      transferService,
      onUpdateNormalCarts,
      normalCartLength,
    } = this.props;

    const { itemData } = this.state;
    const { BookId } = itemData;

    const multiTripId = itemData?.MultiTripInfo?.Id;

    this.setLoading(true);

    sendStatOnDeleteOption(confirmDelete);

    if (ServiceType === SERVICETYPE.TRANSFER) {
      transferService.deleteTransferCart({ BookId });
    }

    await onDeleteItem(clearCart, multiTripId);

    if (normalCartLength === 1) return;

    this.setState({ animatedClass: ANIMATION.DELETE_CART }, () => {
      setTimeout(async () => {
        this.setLoading(false);
        await onUpdateNormalCarts();
      }, 1000);
    });
  };

  handleDeleteMultiDialog = () => {
    this.setDeleteDialog(false);
    this.deleteItem();
  };

  setDeleteDialog = value => this.setState({ showDeleteDialog: value });

  setToNoteDialog = value => this.setState({ showToNoteDialog: value });

  setTransferNoticeDialog = value => this.setState({ showTransferNoticeDialog: value });

  addProjectToCart = async (employeeId, projectId) => {
    const { item, cartService, userSessionService } = this.props;
    let res;

    this.updateCAPreCallLoading(LoadingFields.editProject);

    if (projectId) {
      res = await cartService.addEmployeeProject(item.Id, employeeId, projectId);
    }

    if (!projectId) {
      res = await cartService.delEmployeeProject(item.Id, employeeId);
      userSessionService.defaultProjectHead();
      userSessionService.defaultDepartmentHead();
    }

    await this.updateCA();
    this.updateCAPreCallLoading(LoadingFields.editProject, true);

    return res;
  };

  handleItemDelete = async () => {
    const {
      confirmDelete,
      onHandlesMetrics,
      item,
      airlineBaggageServiceDeleteOffer,
      isHideAdditionalBaggageMixvel,
    } = this.props;
    const { itemData } = this.state;

    onHandlesMetrics.handleMetricStartDeleteItem();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.DELETE_ITEM, { service: item?.ServiceType });

    const isMultiTrip = !!itemData?.MultiTripInfo?.Id;

    if (confirmDelete || isMultiTrip) {
      return this.setDeleteDialog(true);
    }

    const res = await this.deleteItem();

    onHandlesMetrics.endTime();

    if (!isHideAdditionalBaggageMixvel) {
      airlineBaggageServiceDeleteOffer();
    }

    return res;
  };

  handleFindReplacement = (withNumber) => {
    const { item: { ServiceType }, onGoToSearch } = this.props;
    const { itemData } = this.state;
    this.deleteItem(false);
    onGoToSearch(itemData, ServiceType, withNumber);
  };

  handleAddDocument = (employeeId, documentId) => {
    this.setLoading(true);

    const { item, cartService } = this.props;

    return cartService.addEmployeeDocument(item.Id, employeeId, documentId).then(() => {
      this.setLoading(false);
    });
  };

  addCompany = async (employeeId: number, companyId: number, IsDepartmentTrue: boolean) => {
    const { item, cartService, featureFlagsService } = this.props;
    const { DepartmentOnly } = featureFlagsService.getFeatureFlags();

    // await this.handleBookCertificate(false);

    if (DepartmentOnly && IsDepartmentTrue) {
      return cartService.setDepartmentOnlyCompany(item.Id, employeeId, companyId);
    }

    return cartService.addEmployeeCompany(item.Id, employeeId, companyId);
  };

  handleAddCompany = async (...props: Parameters<NormalCart['addCompany']>) => {
    this.updateCAPreCallLoading(LoadingFields.editCompany);

    const res = await this.addCompany(...props);
    await this.updateCA();

    this.updateCAPreCallLoading(LoadingFields.editCompany, true);

    return res;
  };

  handleItemReasonChange = () => {
    this.setLoading(true);

    const { item, cartService } = this.props;
    const { tp: { contraventionReason } } = this.state;
    const preparedString = `"${contraventionReason}"`;

    cartService.updateItemReason(item.Id, preparedString).then(() => {
      this.toggleReasonChangeView(false);
      this.setLoading(false);
    });
  };

  toggleReasonChangeView = (value) => this.setState({ tp: { ...this.state.tp, isEditing: value } });

  isTpDisrupted = item => !!Object.keys(item.TravelPolicy).length;

  handleChangeInputTransferFields = (value, field) => {
    this.setState({
      itemData: {
        ...this.state.itemData,
        [field]: value,
      },
    });
  };

  handleChangeTimeTransferFields = (fieldArrival, valueArrival, fieldDeparture, valueDeparture) => {
    this.setState({
      [fieldArrival]: valueArrival,
      [fieldDeparture]: valueDeparture,
    });
  };

  handleChangeDateTransferFields = ({ value, hours, minutes }, field, setDateArrival, setTimeArrival) => {
    if (field === TRANSFERFIELDS.TimeDeparture || field === TRANSFERFIELDS.TimeArrival) {
      return this.setState({
        [field]: `${hours}:${minutes}`,
      });
    }

    const currentValue = value && isMoment(value) ? value : null;

    const fieldTime = `Time${field.slice(4)}`;

    return this.setState({
      itemData: {
        ...this.state.itemData,
        [field]: currentValue,
      },
      ...(!this.state[fieldTime] && { [fieldTime]: '00:00' }),
    }, () => {
      this.handleUpdateTransfer(field, setDateArrival, setTimeArrival);
    });
  };

  handleChangeTerminalFields = (value, field) => {
    this.setState({
      itemData: {
        ...this.state.itemData,
        [field]: value,
      },
    }, () => this.handleUpdateTransfer());
  };

  handleUpdateTransferFields = () => {
    const { item } = this.props;
    const { itemData } = this.state;

    if (itemData.StartPlace.TransferPlaceType === PLACETYPE.AIRPORT
      && !itemData[TRANSFERFIELDS.ArrivalNumber]) {
      this.setState({
        itemData: {
          ...this.state.itemData,
          [TRANSFERFIELDS.ArrivalNumber]: item.DefaultFlightNumber || null,
        },
      });
    }

    this.handleUpdateTransfer();
  };

  handleChangeLastAddedEmployee = employee => this.setState({ lastAddedEmployee: employee });

  handleUpdateTransfer = async (
    field = '',
    setDateArrival = () => {},
    setTimeArrival = () => {},
    viewers = null,
  ) => {
    const { cartService, item } = this.props;
    const { itemData, TimeArrival, TimeDeparture, AllowBooking } = this.state;
    const isViewersChange = field === TRANSFERFIELDS.Viewers;

    this.setLoading(true);

    const {
      Notes,
      TableSignature,
      DateArrival,
      DateDeparture,
      ArrivalNumber,
      CarriageNumber,
      StartPlace: { Type: TypeStartPlace },
      EndPlace: { Type: TypeEndPlace },
      ArrivalTerminal,
      DepartureTerminal,
      Viewers,
    } = itemData;

    const preparedDateDeparture = getLocalMomentObject(DateDeparture, fullFormYear);
    const preparedDateArrival = getLocalMomentObject(DateArrival, fullFormYear);

    let withRecommendedTime = false;
    const isNeedUpdateTp = field === TRANSFERFIELDS.DateDeparture
      || field === TRANSFERFIELDS.DateArrival
      || field === TRANSFERFIELDS.TimeDeparture
      || field === TRANSFERFIELDS.TimeArrival;

    const isNeedUpdateRecommendedTime = (field === TRANSFERFIELDS.DateDeparture || field === TRANSFERFIELDS.TimeDeparture)
       && !!DateDeparture && !!TimeDeparture
      && TypeStartPlace === PLACETYPE.CITY
      && (TypeEndPlace === PLACETYPE.AIRPORT || TypeEndPlace === PLACETYPE.RAILSTATION);

    if (isNeedUpdateRecommendedTime) {
      withRecommendedTime = true;

      await this.handleUpdateRecommendedTime(setDateArrival, setTimeArrival, isNeedUpdateTp);
    }

    const isNeedCheckTime = (field === TRANSFERFIELDS.DateArrival
        || field === TRANSFERFIELDS.TimeArrival
        || field === TRANSFERFIELDS.DateDeparture
        || field === TRANSFERFIELDS.TimeDeparture)
      && ((DateArrival || TimeArrival) || (DateDeparture || TimeDeparture))
      && !withRecommendedTime;

    if (isNeedCheckTime || AllowBooking === null) {
      await this.handleUpdateCheckTime();
    }

    if (!withRecommendedTime) {
      await cartService.updateTransferAndTp(item.Id, {
        Note: Notes,
        Table: TableSignature,
        DateArrival: DateArrival
          ? `${preparedDateArrival} ${TimeArrival && TimeArrival.length >= 4 ? TimeArrival : '00:00'}`
          : '',
        DateDeparture: DateDeparture
          ? `${preparedDateDeparture} ${TimeDeparture && TimeDeparture.length >= 4 ? TimeDeparture : '00:00'}`
          : '',
        ArrivalNumber,
        CarriageNumber,
        ArrivalTerminal,
        DepartureTerminal,
        Viewers: isViewersChange ? viewers : Viewers,
      }, isNeedUpdateTp);

      this.handleUpdateValidRequestChange();
    }

    this.handleValidateTransferFields();

    this.setLoading(false);
  };

  handleValidateTransferFields = () => {
    const { transferService } = this.props;
    const { itemData, AllowBooking, TimeArrival, TimeDeparture } = this.state;
    const {
      DateArrival,
      DateDeparture,
      ArrivalNumber,
      BookId,
      AdditionalData,
      CarriageNumber,
      ArrivalTerminal,
      DepartureTerminal,
    } = itemData;

    const preparedDateDeparture = getLocalMomentObject(DateDeparture, fullFormYearTime);
    const preparedDateArrival = getLocalMomentObject(DateArrival, fullFormYearTime);

    transferService.updateTransferCart({
      BookId,
      DateArrival: DateArrival ? preparedDateArrival : '',
      DateDeparture: DateDeparture ? preparedDateDeparture : '',
      ArrivalNumber,
      TimeDeparture: TimeDeparture.length > 1 ? TimeDeparture : '',
      TimeArrival: TimeArrival.length > 1 ? TimeArrival : '',
      AdditionalData,
      AllowBooking,
      CarriageNumber,
      EndTerminals: DepartureTerminal,
      StartTerminals: ArrivalTerminal,
    });
  };

  handleUpdateCheckTime = async () => {
    const { cartService } = this.props;
    const { itemData, TimeArrival, TimeDeparture } = this.state;
    const { BookId, DateDeparture, DateArrival } = itemData;

    const preparedDateDeparture = getLocalMomentObject(DateDeparture, fullFormYear);
    const preparedDateArrival = getLocalMomentObject(DateArrival, fullFormYear);

    const result = await cartService.getCheckTime({
      DepartureTime: DateDeparture ? `${preparedDateDeparture} ${TimeDeparture}` : '',
      PickUpTime: `${preparedDateArrival} ${TimeArrival}`,
      BookId,
    });
    const { Notice, AllowBooking } = result;

    this.setState({
      Notice,
      AllowBooking,
    }, () => this.handleValidateTransferFields());

    if (!AllowBooking && Notice) {
      this.setTransferNoticeDialog(true);
    }
  };

  handleUpdateRecommendedTime = async (setDateArrival, setTimeArrival, isNeedUpdateTp) => {
    const { cartService, item } = this.props;
    const { itemData, TimeDeparture } = this.state;
    const {
      DateDeparture,
      BookId,
      Notes,
      TableSignature,
      ArrivalNumber,
      CarriageNumber,
      ArrivalTerminal,
      DepartureTerminal,
      Viewers,
    } = itemData;

    const preparedDateDeparture = getLocalMomentObject(DateDeparture, fullFormYear);

    const result = await cartService.getRecommendedTime({
      DepartureTime: `${preparedDateDeparture} ${TimeDeparture}`,
      BookId,
    });
    const { RecommendedTime } = result;

    if (RecommendedTime) {
      this.setState({
        TimeArrival: getTimeFromTimezoneForm(RecommendedTime),
        itemData: {
          ...this.state.itemData,
          DateArrival: momentObject(RecommendedTime),
        },
      }, () => {
        if (RecommendedTime && DateDeparture && TimeDeparture) {
          this.handleUpdateCheckTime();
        }

        this.handleValidateTransferFields();
      });

      setDateArrival(momentObject(RecommendedTime));
      setTimeArrival(getTimeFromTimezoneForm(RecommendedTime));

      await cartService.updateTransferAndTp(item.Id, {
        Note: Notes,
        Table: TableSignature,
        DateArrival: `${getDateFromTimezoneForm(RecommendedTime)} ${getTimeFromTimezoneForm(RecommendedTime)}`,
        DateDeparture: DateDeparture ? `${preparedDateDeparture} ${TimeDeparture && TimeDeparture.length >= 4 ? TimeDeparture : '00:00'}` : '',
        ArrivalNumber,
        CarriageNumber,
        ArrivalTerminal,
        DepartureTerminal,
        Viewers,
      }, isNeedUpdateTp);

      this.handleUpdateValidRequestChange();
    }
  };

  handleUpdateValidRequestChange = () => {
    const { cartId, stores: { travelApprovalsStore: { chosenApprovedRequest } }, onValidRequestChange } = this.props;

    const chosenApprovedRequestId = chosenApprovedRequest?.Id || null;

    if (chosenApprovedRequestId) {
      onValidRequestChange(chosenApprovedRequestId, cartId);
    }
  };

  handleUpdateTripTags = async (tags) => {
    const { tripTagsService, item: { Id, ServiceType }, onHandlesMetrics } = this.props;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.TAGS_CHANGED, { service: ServiceType });
    onHandlesMetrics.handleMetricStartSelectTag();

    this.setLoading(true);

    const res = await tripTagsService.updateCartItemTripTags(Id, tags);

    this.setLoading(false);

    onHandlesMetrics.endTime();

    return res;
  };

  handleChangeSelectedTripTags = selectedTripTags => this.setState({ selectedTripTags });

  handleGetRules = itemId => this.props.cartService.cancellationInfo(itemId, this.props.cartId);

  getQaAttrByField = (serviceType, field) => {
    const { qaAttrTrain, qaAttrHotel, qaAttrAir, qaAttrAeroexpress } = this.props;

    switch (serviceType) {
      case SERVICETYPE.AIR: {
        return qaAttrAir[field];
      }
      case SERVICETYPE.TRAIN: {
        return qaAttrTrain[field];
      }
      case SERVICETYPE.AEROEXPRESS: {
        return qaAttrAeroexpress[field];
      }
      case SERVICETYPE.HOTEL: {
        return qaAttrHotel[field];
      }
      case SERVICETYPE.TRANSFER: {
        return '';
      }
      default:
        return '';
    }
  };

  handleChangeRequestId = (requestId) => {
    const {
      cartService,
      item: { Id: cartItemId, ServiceType },
    } = this.props;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.REQUEST_1C_CHANGED, { service: ServiceType });

    cartService
      .setRequestForItem({ cartItemId, requestId })
      .then(() => {
        this.setState({ request1c: requestId });
      });
  };

  renderItem = () => {
    const {
      item,
      aggregationId,
      hotelsService,
      notificationService,
      favoritesService,
      workspaceService,
      renderOptions,
      sidePanelService,
      allowedEmployees,
      qaAttrTrain,
      qaAttrHotel,
      qaAttrAir,
      qaAttrAeroexpress,
      trainsService,
      item: { IsReserved },
    } = this.props;
    const { itemData } = this.state;

    const hotelItemFullInfo = !!renderOptions.hotelItem && !!renderOptions.hotelItem.fullInfo;
    const hasUnderageEmployees = checkUnderageEmployees(allowedEmployees);

    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        const metadata = item.Metadata && JSON.parse(item.Metadata);
        const altPnr = metadata && (metadata.PNR || null);
        const pnr = (itemData.BookData && itemData.BookData.PNR) || altPnr;
        const hasAirUnerageEmployees = checkAirUnderageEmployees(item);
        const isMultiTrip = !!itemData?.MultiTripInfo?.Id;

        return itemData.Routes.map(route =>
          (
            <div key={ route.ID } className={ styles.segment }>
              <AirlineItem
                route={ route }
                pnr={ pnr }
                meta={ itemData.Metadata }
                hasUnderageEmployees={ hasAirUnerageEmployees }
                showSegments
                isCart
                getFreeSeatsData={ this.getFreeSeatsData }
                qaAttr={ qaAttrAir }
                isMultiTrip={ isMultiTrip }
              />
            </div>
          ),
        );
      }
      case SERVICETYPE.HOTEL: {
        const { hotels } = hotelsService.getItemsState();
        const { hotel: { ClassificatorId } } = itemData;

        const preloadedHotel =
          hotels.find(({ ClassificatorId: preClassificatorId }) => preClassificatorId === ClassificatorId) ?? null;

        return (
          <HotelItem
            hotelItemFullInfo={ hotelItemFullInfo }
            item={ itemData }
            isReserved={ IsReserved && item?.BookDeadline }
            additionalData={ item.AdditionalData }
            aggregationId={ aggregationId }
            preloadedHotel={ preloadedHotel }
            hotelsService={ hotelsService }
            notificationService={ notificationService }
            workspaceService={ workspaceService }
            favoritesService={ favoritesService }
            sidePanelService={ sidePanelService }
            qaAttrTitle={ qaAttrHotel.title }
            qaAttrDate={ qaAttrHotel.date }
            qaAttrDetails={ qaAttrHotel.details }
            qaAttrDetailsBreakfast={ qaAttrHotel.detailsBreakfast }
            qaAttrCancellation={ qaAttrHotel.cancellation }
          />
        );
      }
      case SERVICETYPE.TRANSFER: {
        return (
          <TransferItem item={ itemData }/>
        );
      }
      case SERVICETYPE.TRAIN: {
        const { Train } = itemData;
        const isSapsan = trainsService.isSapsan(Train);
        const isLastochka = trainsService.isLastochka(Train);
        const differentTrains = isSapsan || isLastochka;

        return (
          <TrainItem
            isCancellation
            isSpecialTariffs
            differentTrains={ differentTrains }
            item={ itemData }
            hasUnderageEmployees={ hasUnderageEmployees }
            qaAttrFrom={ qaAttrTrain.from }
            qaAttrTo={ qaAttrTrain.to }
            qaAttrDateFrom={ qaAttrTrain.dateFrom }
            qaAttrDateTo={ qaAttrTrain.dateTo }
            qaAttrCancellation={ qaAttrTrain.cancellation }
            qaAttrNumber={ qaAttrTrain.number }
          />
        );
      }

      case SERVICETYPE.AEROEXPRESS: {
        return (
          <AeroexpressItem item={ itemData } qaAttrDate={ qaAttrAeroexpress.date } />
        );
      }

      case SERVICETYPE.TAXI_VOUCHER: {
        return (
          <TaxiVoucherItem item={ itemData } />
        );
      }

      case SERVICETYPE.INSURANCE: {
        return (
          <InsuranceItem item={ itemData } />
        );
      }
    }

    return null;
  };

  renderMinimal() {
    const { item: { Data, LowestFares } } = this.props;

    if (!LowestFares?.length) {
      return null;
    }

    return (
      <div className={ styles.actions }>
        <FlightInfoComponent
          lowestFares={ LowestFares }
          data={ Data }
        />
      </div>
    );
  }

  renderBookAirCertificate = () => {
    const {
      item: {
        ServiceType,
        CompanyId,
        EmployeeCartItems,
      },
      workspaceService,
      showFindReplacementBtn,
      accountSettingsService: {
        getCompanySettings,
        store: {
          accountSettings: {
            accessFlightCertificates,
          },
        },
      },
      qaAttrAir,
    } = this.props;
    const { BuyTripPersonal, BuyTripAccount } = workspaceService.rights;
    const { isBookCertificate, itemData, isBookCertificateLoading } = this.state;

    const TravellersCount = itemData.Metadata ? itemData.Metadata.TravellersCount : 1;
    const hasIncorrectRights = BuyTripAccount !== BUYTRIPSACCOUNTRIGHT.Unlimited && BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unlimited;
    const checkHasIncorrectRights = accessFlightCertificates
      ? !accessFlightCertificates
      : hasIncorrectRights;

    const companiesSettings = getCompanySettings();

    const isFreeFlightCertificate = getFreeFlightCertificateInCart(companiesSettings, TravellersCount, EmployeeCartItems);
    const removeFlightCertificateFlag = () => getTransformationCompanySettings({ companiesSettings, CompanyId, setting: SettingsType.removeFlightCertificate });
    const removeFlightCertificate = CompanyId
      ? removeFlightCertificateFlag()
      : true;
    const checkRemoveFlightCertificate = accessFlightCertificates
      ? !accessFlightCertificates
      : removeFlightCertificate;

    if (ServiceType !== SERVICETYPE.AIR ||
      checkHasIncorrectRights ||
      showFindReplacementBtn ||
      checkRemoveFlightCertificate ||
      isFreeFlightCertificate ||
      isSmartAgent
    ) {
      return null;
    }

    const text = LABELS.AIR_CERTIFICATE.TITLE(getPriceWithCertificate(TravellersCount));

    return (
      <div className={ styles.certificate_wrap } >
        <Checkbox
          onChange={ this.handleBookCertificate }
          value={ isBookCertificate }
          loading={ isBookCertificateLoading }
          qaAttr={ qaAttrAir.certificateCheckbox }
        >
          <Text type={ NORMAL_16_140 }>
            <div className={ styles.certificate_content }>
              { text }
              <span>&nbsp;</span>
              <Tooltip
                renderContent={ () => (
                  <div className={ styles.certificate_tooltip }>
                    <Text
                      type={ NORMAL_14_130 }
                      color={ WHITE }
                    >
                      { LABELS.AIR_CERTIFICATE.TOOLTIP_ONE }
                    </Text>
                    <Text
                      type={ NORMAL_14_130 }
                      color={ WHITE }
                    >
                      { LABELS.AIR_CERTIFICATE.TOOLTIP_TWO }
                    </Text>
                  </div>
                ) }
              >
                <Icon type={ QUESTION } />
              </Tooltip>
            </div>
          </Text>
        </Checkbox>
      </div>
    );
  };

  renderRulesButtonAirAndTrain = () => {
    const { item: { ServiceType } } = this.props;

    if (ServiceType !== SERVICETYPE.AIR && ServiceType !== SERVICETYPE.TRAIN) {
      return null;
    }

    return (
      <Button
        className={ styles.rules_button }
        type={ TEXTUAL }
        onClick={ () => this.setState({ showRulesAirDialog: true }) }
      >
        { LABELS.RULES_TARIFF }
      </Button>
    );
  };

  renderRulesDialog = () => {
    const { showRulesAirDialog } = this.state;
    const { item } = this.props;

    return (
      <DialogRules
        show={ showRulesAirDialog }
        items={ [item] }
        onClose={ () => this.setState({ showRulesAirDialog: false }) }
        onGetRules={ this.handleGetRules }
      />
    );
  };

  handleShowAddInTripDialog = value => {
    const {
      popupsService: {
        closePopupState,
      },
      stores: {
        popupsStore: {
          popupsState: {
            add_trip_item_in_cart_popup,
          },
        },
      },
      item,
    } = this.props;

    if (value) {
      this.props.cartService.getTripsToAddOrder(BOOKED_STATUS);
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.ADD_TRIP, { service: item?.ServiceType });
    }

    if (!add_trip_item_in_cart_popup) {
      closePopupState(POPUP_NAME.ADDING_ORDER_IN_CARD);
    }

    this.setState({ showAddInTripDialog: value });
  };

  handleSaveAddingTrip = async (TripId) => {
    const { cartService, item } = this.props;

    this.setLoading(true);

    await cartService.saveAddingTrip({ TripId, CartItemId: item.Id });

    this.setLoading(false);
    this.handleShowAddInTripDialog(false);
  };

  renderAddInTripDialog = () => {
    const { showAddInTripDialog, loading } = this.state;
    const { tripsToAddOrder } = this.props;

    if (!showAddInTripDialog) return false;

    return (
      <AddInTripDialog
        show={ showAddInTripDialog }
        items={ tripsToAddOrder }
        loading={ loading }
        onClose={ this.handleShowAddInTripDialog }
        onSave={ this.handleSaveAddingTrip }
      />
    );
  };

  renderItemEmployees = () => {
    const {
      item: {
        ServiceType,
        EmployeeCartItems,
        IsReserved,
        BookDeadline,
      },
      buyingEmployee,
      documentType,
      projects,
      featureFlagsService,
      accountSettingsService,
      employeeService,
      workspaceService,
      settingsService,
      mandatoryProject,
      optionalProject,
      allowedEmployees,
      readonly,
      onSelectedEmployee,
      onSelectedOptionEmployee,
      onSelectDocument,
      onSelectCompany,
      onSelectProject,
      onRemoveEmployee,
      onRemoveOptionalEmployee,
      onValidRequest,
      onHandlesMetrics,
      highlightNonFilledEmployee,
      hasUnsetRequiredCustomAnalytics,
      isTravelApproval,
      isBlockEditEmployees,
      qaAttrPaidSeats,
      qaAttr,
      isSpecificCart,
      stores: {
        travelApprovalsStore: {
          chosenApprovedRequest,
          approvedList,
        },
      },
    } = this.props;

    const {
      itemData,
      itemMetadata,
      lastAddedEmployee,
    } = this.state;

    const maxEmployees = getCountOfTravellers(ServiceType, itemData, allowedEmployees.length);
    const isOptionalEmployee = isOptionalEmployeeItem(ServiceType, maxEmployees);
    let showDocument = false;
    let isChoiceForEachEmployee = false;

    const altPnr = itemMetadata?.PNR || null;
    const pnr = itemData.BookData?.PNR || altPnr;

    const isTravelApprovalAndNoBooking = isTravelApproval && !BookDeadline;

    switch (ServiceType) {
      case SERVICETYPE.AIR: {
        showDocument = true;

        if (maxEmployees > 1) {
          isChoiceForEachEmployee = true;
        }

        break;
      }
      case SERVICETYPE.TRAIN: {
        showDocument = true;

        break;
      }
      case SERVICETYPE.AEROEXPRESS: {
        if (maxEmployees > 1) {
          isChoiceForEachEmployee = true;
        }

        showDocument = true;

        break;
      }
      case SERVICETYPE.HOTEL:
      case SERVICETYPE.TRANSFER:
      case SERVICETYPE.TAXI_VOUCHER:
      case SERVICETYPE.TAXI:
      case SERVICETYPE.INSURANCE: {
        break;
      }
    }

    const isReservedHotel = ServiceType === SERVICETYPE.HOTEL && IsReserved;
    const employeeListReadOnly = readonly || !!pnr || ServiceType === SERVICETYPE.INSURANCE || isReservedHotel;

    const sendSmsFlag = featureFlagsService.getSendSms()
      || ((ServiceType === SERVICETYPE.TAXI_VOUCHER
      || ServiceType === SERVICETYPE.TAXI)
      && !accountSettingsService.getBookingTaxi());

    const cartEmployeeBirthday = accountSettingsService.getCartEmployeeBirthday();
    const { DepartmentOnly } = featureFlagsService.getFeatureFlags();

    return (
      <EmployeeList
        isCart
        routes={ itemData.Routes }
        withNumber={ sendSmsFlag }
        items={ allowedEmployees }
        max={ maxEmployees }
        buyingEmployee={ buyingEmployee }
        onSetEmployeeInCartItem={ this.setEmployeeInCartItem }
        chosenApprovedRequest={ chosenApprovedRequest }
        isTravelApprovalAndNoBooking={ isTravelApprovalAndNoBooking }
        isPersonalBonusCards={ this.getPersonalBonusCards }
        highlightNonFilledEmployee={ highlightNonFilledEmployee && !hasUnsetRequiredCustomAnalytics }
        lastAddedEmployee={ lastAddedEmployee }
        showDocument={ showDocument }
        enumOfDocuments={ documentType }
        employeeService={ employeeService }
        settingsService={ settingsService }
        projects={ projects }
        workspaceService={ workspaceService }
        departmentOnly={ DepartmentOnly }
        mandatoryProject={ mandatoryProject }
        optionalProject={ optionalProject }
        isBlockEditEmployees={ isBlockEditEmployees }
        readOnly={ employeeListReadOnly }
        isOptionalEmployee={ isOptionalEmployee }
        isChoiceForEachEmployee={ isChoiceForEachEmployee }
        employeesWithCompanies={ EmployeeCartItems }
        isMulti={ workspaceService.isMultiCompany() }
        handleAddEmployee={ this.handleAddEmployee }
        onResetNonFilledItem={ this.handleResetNonFilledItem }
        handleRemoveEmployee={ this.handleRemoveEmployee }
        handleAddDocument={ this.handleAddDocument }
        handleAddCompany={ this.handleAddCompany }
        handleChangeLastAddedEmployee={ this.handleChangeLastAddedEmployee }
        onHandlesMetrics={ onHandlesMetrics }
        redirectToEmployee={ this.redirectToEmployee }
        redirectToProject={ this.redirectToProject }
        onChangeProjectSelect={ this.addProjectToCart }
        onAddProject={ this.addProjectToCart }
        onSelectedEmployee={ onSelectedEmployee }
        onSelectedOptionEmployee={ onSelectedOptionEmployee }
        onSelectDocument={ onSelectDocument }
        onSelectCompany={ onSelectCompany }
        onSelectProject={ onSelectProject }
        onRemoveEmployee={ onRemoveEmployee }
        onValidRequest={ onValidRequest }
        onRemoveOptionalEmployee={ onRemoveOptionalEmployee }
        isReserved={ IsReserved }
        serviceType={ ServiceType }
        cartEmployeeBirthday={ cartEmployeeBirthday }
        qaAttrPaidSeats={ qaAttrPaidSeats }
        qaAttr={ qaAttr }
        isSpecificCart={ isSpecificCart }
        approvedList={ approvedList }
        accountSettingsService={ accountSettingsService }
      />
    );
  };
  renderToNoteDialog = () => {
    const { showToNoteDialog } = this.state;

    return (
      <ConfirmDialog
        visible={ showToNoteDialog }
        title={ LABELS.CONFIRM_ACTION }
        description={ LABELS.DIALOG_TO_NOTE_DESCRIPTION }
        onChangeVisible={ this.setToNoteDialog }
        onClick={ () => {
          this.setToNoteDialog(false);
          this.moveToNotepad();
        } }
        onClose={ () => this.setToNoteDialog(false) }
        visibleAction
      />
    );
  };

  renderTransferNoticeDialog = () => {
    const { showTransferNoticeDialog, Notice } = this.state;

    return (
      <TransferDialog
        visible={ showTransferNoticeDialog }
        title={ LABELS.TRANSFER_NOTICE }
        description={ Notice || '' }
        onChangeVisible={ this.setTransferNoticeDialog }
        onClose={ () => this.setTransferNoticeDialog(false) }
      />
    );
  };

  handleDeleteItem = () => {
    this.setDeleteDialog(false);
    this.deleteItem();
  };

  renderDefaultDescriptionDelete = () => (
    <Button
      onClick={ this.handleDeleteItem }
      type={ PRIMARY }
    >
      { LABELS.DELETEITEM }
    </Button>
  );

  renderDeleteDialog = () => {
    const { showDeleteDialog, itemData } = this.state;
    const isMultiTrip = !!itemData?.MultiTripInfo?.Id;

    const description = isMultiTrip
      ? LABELS.DIALOG_DELETE_DESCRIPTION
      : this.renderDefaultDescriptionDelete;

    const onClick = isMultiTrip
      ? this.handleDeleteMultiDialog
      : () => {};

    const onClose = isMultiTrip
      ? () => this.setDeleteDialog(false)
      : () => {};

    return (
      <ConfirmDialog
        visible={ showDeleteDialog }
        title={ LABELS.CONFIRM_ACTION }
        description={ description }
        onChangeVisible={ this.setDeleteDialog }
        onClick={ onClick }
        onClose={ onClose }
        visibleAction={ isMultiTrip }
      />
    );
  };

  setAddedTripLoading = (value) => this.setState({ loadingAddedTrip: value });

  handleCloseAddedTrip = async (tripItemId: number) => {
    this.setAddedTripLoading(true);

    await this.props.cartService.deleteAddedTrip(tripItemId);

    this.setAddedTripLoading(false);
  };

  renderLayoutHead = () => {
    const {
      item,
      tripsByTripIds,
      serverTime,
      specificCartType,
      item: { TripId, Id, IsReserved, ServiceType },
      onTimeIsUp,
    } = this.props;
    const { loadingAddedTrip } = this.state;

    const addedOnTrip = TripId ? <BookMarkAddingTrip
      trips={ tripsByTripIds }
      tripId={ TripId }
      loading={ loadingAddedTrip }
      type={ specificCartType }
      serviceType={ ServiceType }
      onClose={ () => this.handleCloseAddedTrip(Id) }
    /> : null;

    const timerContent = () => {
      if (!IsReserved || (ServiceType !== SERVICETYPE.AIR && ServiceType !== SERVICETYPE.HOTEL) || !item?.BookDeadline) {
        return null;
      }

      return (
        <LayoutTimer
          bookDeadline={ item.BookDeadline }
          time={ serverTime }
          onTimeIsUp={ () => onTimeIsUp(item) }
          type={ ServiceType }
        />
      );
    };

    return (
      <div className={ styles.layout_header }>
        { timerContent() }
        { addedOnTrip }
      </div>
    );
  };

  renderTooltip = (label: string) => () => (
    <div className={ styles.add_trip_tooltip }>
      <Text
        type='NORMAL_14_130'
        color='white'
      >
        { label }
      </Text>
    </div>
  );

  renderAddInTrip = () => {
    const {
      item: {
        TripId,
        ServiceType,
      },
      specificCartType,
      isBlockEditEmployees,
      stores: {
        popupsStore: {
          popupsState: {
            add_trip_item_in_cart_popup,
          },
        },
      },
    } = this.props;

    const labelTooltip = defineTextOfLabelOfTooltip(TripId, isBlockEditEmployees, LABELS);
    const conditionApprove = specificCartType === SPECIFIC_CART_TYPES.APPROVER ||
      specificCartType === SPECIFIC_CART_TYPES.VIEWER ||
      ServiceType === SERVICETYPE.INSURANCE;
    const isShown = TripId || isBlockEditEmployees;

    if (conditionApprove) return null;

    const classNamesHighlited = !add_trip_item_in_cart_popup ? styles.highlight : '';

    return (
      <div
        className={ classNamesHighlited }
      >
        <div
          className={ styles.add }
        >
          <Tooltip
            show={ isShown }
            renderContent={ this.renderTooltip(labelTooltip) }
          >
            <IconButton
              iconType='plusDefault'
              iconColor='blue'
              disabled={ isShown }
              alternativeDesign={ isSmartAgent }
              onClick={ () => this.handleShowAddInTripDialog(true) }
            >
              <Text
                type='NORMAL_14'
              >
                { LABELS.ADD_TO_TRIP }
              </Text>
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  renderTextDisplayFormat = (text) =>
    <Text type='NORMAL_14'>{text}</Text>;

  renderAirlineAdditionalServices = () => {
    const { itemData } = this.state;
    const {
      item,
      item: {
        ServiceType,
        EmployeeCartItems,
      },
      cartId,
      isHideAdditionalSeatsS7,
      isHideAdditionalBaggageMixvel,
    } = this.props;

    /** Скрываем блок доп. услуг, если нет доступа ни по одной услуге */
    if (isHideAdditionalSeatsS7 && isHideAdditionalBaggageMixvel) return null;

    const maxEmployees = getCountOfTravellers(ServiceType, itemData);
    const employeesWithPassports = EmployeeCartItems.filter(employee => employee.DocumentId);
    const everyEmployeeChoosen = maxEmployees === employeesWithPassports.length;
    const segments = itemData.Routes.map(({ Segments, ID: RouteId }) => Segments.map(segment => ({ ...segment, RouteId }))).flat();

    return (
      <AirlineAdditionalServices
        cartId={ cartId }
        segments={ segments }
        everyEmployeeChoosen={ everyEmployeeChoosen }
        currentItem={ item }
        renderAditionalPriceCollapsed={ this.renderAditionalPriceCollapsed }
        isHideAdditionalSeatsS7={ isHideAdditionalSeatsS7 }
        isHideAdditionalBaggageMixvel={ isHideAdditionalBaggageMixvel }
      />
    );
  };

  renderAddTo1CRequest = () => {
    const {
      accountSettingsService: {
        store: { salary1C },
      },
      cartService,
      item: {
        Employees,
        ServiceType,
        RequestItem,
        Id: cartItemId,
      },
    } = this.props;
    const { itemData, request1c } = this.state;

    const requestsSelect = [
      ...cartService.suggestedRequests(cartItemId, LABELS.REQUEST_1C.NUMBER),
      { value: 'remove', label: LABELS.REQUEST_1C.NOT_SELECTED },
    ];

    if (RequestItem?.Id && requestsSelect.findIndex((obj) => obj.value === RequestItem?.Id) === -1) {
      requestsSelect.push(
        { value: RequestItem.Id, label: `${LABELS.REQUEST_1C.NUMBER} ${RequestItem.RequestId}` },
      );
    }

    const maxEmployees = getCountOfTravellers(ServiceType, itemData);
    const isOptional = isOptionalEmployeeItem(ServiceType, maxEmployees);

    const enableEdit = isOptional
      ? Employees.length >= 1
      : Employees.length === maxEmployees;

    const value = request1c || RequestItem?.Id;

    if (!salary1C
      || ([
        SERVICETYPE.AEROEXPRESS,
        SERVICETYPE.TAXI_VOUCHER,
        SERVICETYPE.TAXI,
      ].indexOf(ServiceType) > -1)
    ) {
      return null;
    }

    return (
      <Tooltip
        renderContent={ this.renderTooltip(LABELS.REQUEST_1C.USER_NOT_SELECTED) }
        show={ !enableEdit }
      >
        <Select
          value={ value }
          search
          renderLabel={ ({ label }) => this.renderTextDisplayFormat(label) }
          disabled={ !enableEdit }
          items={ requestsSelect }
          placeholder={ this.renderTextDisplayFormat(LABELS.REQUEST_1C.NOT_SELECTED) }
          onChange={ this.handleChangeRequestId }
          icon='menuRequest1C'
          theme='open'
        />
      </Tooltip>
    );
  };

  renderHeader = () => {
    const {
      item,
      readonly,
      showDeleteBtn,
      workspaceService: { isDemo },
      userSessionService,
      stores: {
        travelApprovalsStore: {
          validateApprovedRequest,
          chosenApprovedRequest,
        },
      },
    } = this.props;

    const travelPolicyList = userSessionService.get().travelPolicies;
    const { ServiceType, RequestId } = item;
    const qaAttrNote = this.getQaAttrByField(item.ServiceType, qaAttrFields.note);
    const qaAttrDelete = this.getQaAttrByField(item.ServiceType, qaAttrFields.delete);

    const shouldHideToNote = ServiceType === SERVICETYPE.AEROEXPRESS
      || ServiceType === SERVICETYPE.TAXI_VOUCHER
      || ServiceType === SERVICETYPE.INSURANCE
      || readonly;

    const moveToNotepadHtml = !shouldHideToNote && (
      <div
        className={ styles.button_wrapper }
      >
        <ToNote
          onClick={ this.handleMoveToNotepad }
          qaAttr={ qaAttrNote }
        />
      </div>
    );

    const tpDisruptedHtml = this.isTpDisrupted(item) && (
      <NameErrorsTravelPolicy
        travelPolicyList={ travelPolicyList }
        applyTP
        item={ { TravelPolicy: { Errors: item.TravelPolicy } } }
        oneTravelPolicy={ false }
      />
    );

    const travelApprovalSchemaHtml = !validateApprovedRequest.Valid && chosenApprovedRequest && (
      <NameErrorsTravelApproval
        item={ item }
        validateApprovedRequest={ validateApprovedRequest }
        chosenApprovedRequest={ chosenApprovedRequest }
      />
    );

    const deleteHtml = (!readonly || showDeleteBtn) && (
      <IconButton
        className={ styles.delete }
        iconType={ TRASHCAN }
        iconColor={ svgColor }
        onClick={ this.handleItemDelete }
        disabled={ isDemo && readonly }
        qaAttr={ qaAttrDelete }
      >
        <Text type={ NORMAL_14 }>
          { LABELS.DELETE }
        </Text>
      </IconButton>
    );

    const toRequestHtml = !!RequestId && (
      <NavLink
        to={ `${ROUTES.REQUESTS}/${RequestId}` }
        className={ styles.request }
      >
        <IconButton iconType={ REQUEST }>
          <Text type={ NORMAL_14 }>
            { LABELS.REQUSET_NUMBER }{ RequestId }
          </Text>
        </IconButton>
      </NavLink>
    );

    return (
      <div className={ styles.header }>
        <div className={ styles.right }>
          <div
            className={ styles.addToTrip }
          >
            { toRequestHtml }
            { travelApprovalSchemaHtml }
            { tpDisruptedHtml }
          </div>
          { this.renderAddTo1CRequest() }
          { this.renderAddInTrip() }
          { moveToNotepadHtml }
          { deleteHtml }
        </div>
      </div>
    );
  };

  renderItemTPContraventionReason = () => {
    const { item, readonly } = this.props;
    const { tp: { contraventionReason, isEditing } } = this.state;

    return this.isTpDisrupted(item) && (
      <div className={ styles['tp-contravention-reason'] }>
        {
          isEditing || !contraventionReason ? (
            <>
              <Text color={ GRAY } className={ styles.label }>{ LABELS.TP_CONTRAVENTION_REASON.CAUSE }</Text>
              <div className={ styles.content }>
                <Textarea
                  readOnly={ readonly }
                  placeholder={ LABELS.TP_CONTRAVENTION_REASON.CHOOSE_CAUSE }
                  className={ styles.input }
                  value={ contraventionReason }
                  onChange={ value => this.setState({ tp: { isEditing: true, contraventionReason: value.trim() } }) }
                  onBlur={ this.handleItemReasonChange }
                  ref={ this.tpReasonRef }
                  qaAttr={ QA_ATTRIBUTES.cart.travelPolicyReasonField }
                />
                { !readonly && (
                  <Button
                    type={ PRIMARY }
                    disabled={ !contraventionReason }
                    onClick={ this.handleItemReasonChange }
                    className={ styles['save-button'] }
                    qaAttr={ QA_ATTRIBUTES.cart.travelPolicyReasonSave }
                  >
                    { LABELS.SAVE }
                  </Button>
                ) }
              </div>
            </>
          ) : (
            <>
              <div className={ styles.preview }>
                <Text color={ GRAY }>{ LABELS.TP_CONTRAVENTION_REASON.CAUSE }:</Text>
                &nbsp;
                <div onClick={ () => (readonly ? null : this.toggleReasonChangeView(true)) } className={ styles['icon-wrapper'] }>
                  <Text>{ contraventionReason }</Text>
                  <Icon type={ TRIPS } className={ styles.icon } color={ BLUE } />
                </div>
              </div>
            </>
          )
        }
      </div>
    );
  };

  renderItemError = () => {
    const { item: { Error: err } } = this.props;

    return err && (
      <div className={ styles['error-wrap'] }>
        <Icon className={ styles.icon } type={ WARNING } />
        <Text color={ RED } type={ NORMAL_14 }>{ err }</Text>
      </div>
    );
  };

  renderBonus = () => {
    const { currentBonus, sameAirlineCompany } = this.state;
    const { workspaceService: { isDemo } } = this.props;

    if (!currentBonus || !sameAirlineCompany || !isDemo || (isDemo && isSmartAgent)) return null;

    return (
      <AirlineBonus
        bonus={ currentBonus }
        sameAirlineCompany={ sameAirlineCompany }
      />
    );
  };

  renderDetailsPrice = () => {
    const { item: { Price } } = this.props;

    const detailsAmount = getDetailsAmount(Price);

    return (
      <TaxiDetailsPrice
        tariffAmount={ detailsAmount.tariff }
        vatAmount={ detailsAmount.vat }
        showDetails
      />
    );
  };

  renderVatHtml = () => {
    const { Rate: { Price: { HasVAT, VAT } } } = JSON.parse(this.props.item.Data);

    if (!HasVAT) return null;

    const moneyFormatVat = MoneyFormat.moneyWithDecimal(Number(VAT), true);

    return (
      <div className={ styles.vat }>
        <Text className={ styles.label } type='NORMAL_12' color='gray'>
          { LABELS.ADD_TAX }: { moneyFormatVat }
        </Text>
      </div>
    );
  };

  renderBookingCom = () => <div className={ styles.hotel_bookingcom }>{ LABELS.BOOKING }</div>;

  renderPriceOption = () => {
    const { item: { ServiceType } } = this.props;

    if (ServiceType === SERVICETYPE.AIR) return this.renderBonus();

    if (ServiceType === SERVICETYPE.TAXI || ServiceType === SERVICETYPE.TAXI_VOUCHER) return this.renderDetailsPrice();

    if (ServiceType === SERVICETYPE.HOTEL) return this.renderVatHtml();

    return this.state.bookingCom ? this.renderBookingCom() : null;
  };

  renderCustomAnalytics = (readonly = false) => {
    const {
      customAnalytics,
      item: { UserAnalytics = [] },
      specificCartType,
      hasUnsetRequiredCustomAnalytics,
      featureFlagsService,
      qaAttrCustomAnalytics,
    } = this.props;

    const onlyNewAnalytics = featureFlagsService.getOnlyNewAnalytics();

    let alreadyHighlighted = false;

    return customAnalytics.map(analytics => {
      const customAnalyticsValue = analytics?.Values.find(({ Id }) => UserAnalytics.includes(Id));

      const highlighted = !alreadyHighlighted && analytics.Required && !customAnalyticsValue && !!hasUnsetRequiredCustomAnalytics;

      if (highlighted) {
        alreadyHighlighted = true;
      }

      const qaAttrSelect = analytics.Required ? qaAttrCustomAnalytics.selectMandatory : qaAttrCustomAnalytics.select;

      return (
        <div key={ analytics.Id } className={ styles.custom_analytics_and_tags_item }>
          <AnalyticsSelect
            highlighted={ highlighted }
            onlyNewAnalytics={ onlyNewAnalytics }
            withRequiredValidation={ !!specificCartType }
            readonly={ readonly }
            value={ customAnalyticsValue }
            analytics={ analytics }
            onSet={ this.setCustomAnalytics }
            onUnset={ this.unsetCustomAnalytics }
            onAdd={ this.onAddAnalyticsValue }
            displaySearchIcon
            qaAttrTitle={ qaAttrCustomAnalytics.title }
            qaAttrDelete={ qaAttrCustomAnalytics.delete }
            qaAttrLoading={ qaAttrCustomAnalytics.loading }
            qaAttrSelect={ qaAttrSelect }
            qaAttrAdd={ qaAttrCustomAnalytics.add }
            qaAttrDialog={ qaAttrCustomAnalytics.dialog }
            qaAttrSuggestFirstEl={ qaAttrCustomAnalytics.suggestFirstEl }
          />
        </div>
      );
    });
  };

  renderTripTags = (disabled = false) => {
    const { selectedTripTags } = this.state;
    const {
      specificCartType,
      tripTagsService,
      workspaceService,
      qaAttrTags,
      accountSettingsService: {
        store: {
          accountSettings: {
            hideTagsInCart,
          },
        },
      },
    } = this.props;

    if (hideTagsInCart) {
      return null;
    }

    const noeditable = specificCartType === SPECIFIC_CART_TYPES.VIEWER || disabled;

    return (
      <TripTags
        tripTagsService={ tripTagsService }
        noeditable={ noeditable }
        selectedTags={ selectedTripTags }
        onUpdateTags={ this.handleUpdateTripTags }
        onChangeTags={ this.handleChangeSelectedTripTags }
        store={ tripTagsService.store }
        workspaceService={ workspaceService }
        qaAttrLink={ qaAttrTags }
      />
    );
  };

  renderCustomAnalyticsAndTags = (disabled = false) => {
    const tripTags = (
      <div className={ styles.custom_analytics_and_tags_item }>
        { this.renderTripTags(disabled) }
      </div>
    );

    const customAnalytics = this.renderCustomAnalytics(disabled);

    return (
      <div className={ styles.custom_analytics_and_tags }>
        { customAnalytics }
        { tripTags }
      </div>
    );
  };

  renderAirlineAdditionalServicesInfo = () => {
    const {
      isHideAdditionalSeatsS7,
      isHideAdditionalBaggageMixvel,
    } = this.props;

    /** Скрываем плашку доп. услуг, если нет доступа ни по одной услуге */
    if (isHideAdditionalSeatsS7 && isHideAdditionalBaggageMixvel) return null;

    return (
      <div className={ styles['additional-service-info'] }>
        <div className={ styles['icon-wrapper'] }>
          <Icon type='info' fill='#AFC4E2' color='white'/>
          <Text>{ LABELS.ADDITIONAL_SERVICES.ADDITIONAL_INFO }</Text>
        </div>
      </div>
    );
  };

  renderTipToApprover = () => {
    const { item: { Error: err }, specificCartType } = this.props;

    const showTipToApprover = err && specificCartType === SPECIFIC_CART_TYPES.APPROVER;

    return showTipToApprover && (
      <div className={ styles['error-wrap'] }>
        <Text type={ NORMAL_14 } color={ RED }>
          {LABELS.TIPTOAPPROVER}
        </Text>
      </div>
    );
  };

  setCustomAnalytics = ({ Id: analyticsValueId }) => {
    const { setCustomAnalytics, cartId, item: { Id: itemId, ServiceType } } = this.props;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.CUSTOM_ANALYTIC_CHANGED, { service: ServiceType });

    return setCustomAnalytics(cartId, analyticsValueId, itemId);
  };

  unsetCustomAnalytics = (analyticsId) => {
    const { unsetCustomAnalytics, cartId, item: { Id: itemId } } = this.props;

    return unsetCustomAnalytics(cartId, analyticsId, itemId);
  };

  onAddAnalyticsValue = async (aId, value) => {
    const { cartId, item: { Id: itemId }, onAddAnalyticsValue } = this.props;

    this.setLoading(true);

    await onAddAnalyticsValue(aId, value, cartId, itemId);

    this.setLoading(false);
  };

  // добавляет места в билет
  getFreeSeatsData = ({ Segments }) => {
    const { item } = this.props;

    if (item.ServiceType !== SERVICETYPE.AIR) {
      return {
        caption: null,
        data: null,
      };
    }

    const freeSeatsGroupedBySegments = Segments.reduce((acc, { AdditionalServices = [] }) => {
      const freeSeats = AdditionalServices.filter(({ Type, Service }) => Type === ADDITIONAL_SERVICES.Seat && Service.Price === 0);

      if (!freeSeats.length) {
        return acc;
      }

      return [
        ...acc,
        freeSeats,
      ];
    }, []);

    if (!freeSeatsGroupedBySegments.length) {
      return {
        caption: null,
        data: null,
      };
    }

    const hasOneSegmentWithOneSeat = freeSeatsGroupedBySegments.length === 1 && freeSeatsGroupedBySegments[0].length === 1;

    const label = hasOneSegmentWithOneSeat ? LABELS.SEATS.ONE : LABELS.SEATS.MORE;

    const freeSeatsAsString = freeSeatsGroupedBySegments
      .map(seats =>
        seats
          .map(({ Service: { Number } }) => Number)
          .join(', '))
      .join(' \\ ');

    return {
      caption: label,
      data: freeSeatsAsString,
    };
  };

  renderVoidInfo() {
    const {
      item: { ServiceType },
    } = this.props;

    const {
      itemData: {
        VoidTimeLimitUTC = null,
        VoidUntilEndOfDay = false,
        VoidTimeLimitUpdated = null,
      },
    } = this.state;

    if (ServiceType !== SERVICETYPE.AIR || !VoidTimeLimitUTC) {
      return null;
    }

    const voidTimeLimitUpdatedUTC = momentObjectUTC(VoidTimeLimitUpdated);

    const voidTimeLimitString = VoidUntilEndOfDay
      ? getVoidUntilEndOfDayString(VoidTimeLimitUTC)
      : getVoidTimerStartsAfterBookingString(VoidTimeLimitUTC, voidTimeLimitUpdatedUTC);

    return (
      <Text
        className={ styles.voidtime }
        type={ NORMAL_14_130 }
        color={ GREEN }
      >
        { LABELS.ANNULATION(voidTimeLimitString) }
      </Text>
    );
  }

  renderAirAFlag = () => {
    const { item: { Data } } = this.props;
    const { AFlag } = JSON.parse(Data);

    if (!AFlag) {
      return null;
    }

    return (
      <RoundIconWithTooltip
        label={ LABELS.C }
        className={ styles.a_flag }
      />
    );
  };

  renderAditionalPriceCollapsed = () => {
    const { airlineAdditionalServicesStore: { airlineAdditionalServices } } = this.props;
    const { itemData: { Routes: routes = [] } } = this.state;

    const additionalServices = routes
      .flatMap(({ Segments }) => Segments.flatMap(({ AdditionalServices = [] }) => AdditionalServices));

    if (!Object.keys(airlineAdditionalServices).length || !additionalServices.length) return null;

    const additionalServicesPrice = additionalServices
      .reduce((acc, service) => {
        if (service.Type === ADDITIONAL_SERVICES.Seat) {
          return acc + (service.Service?.Price || 0);
        }

        return acc;
      }, 0);

    if (!additionalServicesPrice) return null;

    const label = additionalServices.length > 1 ? LABELS.SEATS.MORE : LABELS.SEATS.ONE;

    return (
      <Text type='bold_18_130' color='black' className={ styles.additional_price }>
        { label }: + { additionalServicesPrice } ₽
      </Text>
    );
  };

  renderAdditionalPrice = () => {
    const {
      item,
      airlineAdditionalServicesStore: { airlineAdditionalServices },
      airlineBaggageStore: { totalBaggageCost },
    } = this.props;
    const { itemData: { Routes: routes = [] } } = this.state;

    const additionalServices = routes
      .flatMap(({ Segments }) => Segments.flatMap(({ AdditionalServices = [] }) => AdditionalServices));

    if ((!Object.keys(airlineAdditionalServices).length || !additionalServices.length) && !totalBaggageCost?.[item.Id]) return null;

    const additionalServicesPrice = additionalServices
      .reduce((acc, service) => {
        if (service.Type === ADDITIONAL_SERVICES.Seat) {
          return acc + (service.Service?.Price || 0);
        }

        return acc;
      }, 0) + (totalBaggageCost?.[item.Id] || 0); //

    if (!additionalServicesPrice) return null;

    return (
      <Text type='NORMAL_14_130' color='gray' className={ styles.additional_price }>
        + { additionalServicesPrice } ₽
        <br/>
        { LABELS.ADDITIONAL_SERVICES.ADDITONAL }
        <br/>
        { LABELS.ADDITIONAL_SERVICES.SERVICES }
      </Text>
    );
  };

  render() {
    const {
      allowedEmployees,
      item,
      isBlockEditEmployees,
      showFindReplacementBtn,
      readonly,
      cartRef,
      stores: { travelApprovalsStore: { validateApprovedRequest, validateLoadingEmployee, chosenApprovedRequest } },
      qaAttrItemWrapper,
      index,
      transferService,
      cartService,
      allItemsAnimatedClass,
      allItemsLoading,
      cartService: {
        setAdditionalFeeSmartagentCart,
      },
    } = this.props;

    const {
      itemData,
      loading,
      animatedClass,
      isDemo,
      isBookCertificate,
    } = this.state;

    const {
      Id,
      IsReserved,
      Price,
      Reason,
      ServiceType,
      AgentFee,
    } = item;

    const transferHtml = ServiceType === SERVICETYPE.TRANSFER ? (
      <TransferFields
        itemData={ itemData }
        readonly={ readonly }
        isIncludeInsurance={ isBlockEditEmployees }
        onUpdate={ this.handleUpdateTransferFields }
        onChange={ this.handleChangeInputTransferFields }
        onChangeDate={ this.handleChangeDateTransferFields }
        onChangeTime={ this.handleChangeTimeTransferFields }
        onChangeTerminal={ this.handleChangeTerminalFields }
        onUpdateTransfer={ this.handleUpdateTransfer }
        transferService={ transferService }
        cartService={ cartService }
      />
    ) : null;

    const airAFlagHtml = ServiceType === SERVICETYPE.AIR && this.renderAirAFlag();

    const findReplacementHtml = showFindReplacementBtn && (
      <Button
        type={ PRIMARY }
        onClick={ this.handleFindReplacement }
        disabled={ isDemo && readonly }
      >
        { LABELS.FINDREPLACEMENT }
      </Button>
    );

    const { Routes: routes = [] } = itemData;

    const additionalServicesPrice = routes
      .flatMap(({ Segments }) => Segments.flatMap(({ AdditionalServices = [] }) => AdditionalServices))
      .reduce((acc, service) => {
        if (service.Type === ADDITIONAL_SERVICES.Seat) {
          return acc + service.Service.Price;
        }

        if (service.Type === ADDITIONAL_SERVICES.Baggage) {
          return acc + service.Service.Price;
        }

        return acc;
      }, 0);

    const price = showFindReplacementBtn ? null : Price - additionalServicesPrice;
    const TravellersCount = itemData.Metadata ? itemData.Metadata.TravellersCount : 1;

    const priceWithCetificate = isBookCertificate ? getPriceWithCertificate(TravellersCount, price) : price;

    const finderError = validateApprovedRequest.CartItemValidationStatuses
      ? validateApprovedRequest.CartItemValidationStatuses[Id]
      : null;

    const checkError = finderError && chosenApprovedRequest ? finderError[0] !== ApprovalWarning.valid : false;

    const warning = (this.isTpDisrupted(item) && !Reason) || checkError;

    const qaAttrPrice = this.getQaAttrByField(ServiceType, qaAttrFields.price);
    const qaAttrIcon = this.getQaAttrByField(ServiceType, qaAttrFields.icon);
    const isReservedHotel = isSmartAgent && ServiceType === SERVICETYPE.HOTEL && !!item?.BookDeadline;

    const reservedType = IsReserved && (ServiceType === SERVICETYPE.AIR || isReservedHotel)
      ? ServiceType
      : '';

    return (
      <>
        <ItemPanel
          ref={ cartRef }
          className={ styles.wrapper }
          renderHeader={ this.renderHeader }
          warning={ !!warning }
          renderLayoutHead={ this.renderLayoutHead }
          reserved={ reservedType }
          animationClass={ animatedClass || allItemsAnimatedClass }
          loading={ loading || validateLoadingEmployee || allItemsLoading }
          onAnimationEnd={ this.handleAnimationEnd }
          qaAttr={ `${qaAttrItemWrapper}-${index}` }
        >
          <div className={ styles.body }>
            <ItemLayout
              price={ priceWithCetificate }
              serviceType={ ServiceType }
              renderOption={ this.renderPriceOption() }
              html={ findReplacementHtml }
              absoluteHtml={ airAFlagHtml }
              qaAttrPrice={ qaAttrPrice }
              qaAttrIcon={ qaAttrIcon }
              itemId={ Id }
              isAdditionalFee
              setAdditionalFeeSmartagent={ setAdditionalFeeSmartagentCart }
              feeSmartagent={ AgentFee }
              renderAdditionalPriceText={ this.renderAdditionalPrice() }
            >
              { this.renderItem() }
              <AbsencePatronymicInForeignPassport
                cartItem={ item.EmployeeCartItems }
                employees={ allowedEmployees }
                serviceType={ ServiceType }
              />
              { this.renderRulesButtonAirAndTrain() }
              { this.renderVoidInfo() }
              { this.renderBookAirCertificate()}
              { this.renderAirlineAdditionalServicesInfo() }
            </ItemLayout>
            { this.renderMinimal() }
            <div className={ styles.actions }>
              { transferHtml }
              { this.renderItemEmployees() }
              { this.renderCustomAnalyticsAndTags() }
              { this.renderItemTPContraventionReason() }
              { this.renderItemError() }
              { this.renderTipToApprover() }
            </div>
            { this.renderAirlineAdditionalServices() }
          </div>
        </ItemPanel>
        { this.renderDeleteDialog() }
        { this.renderToNoteDialog() }
        { this.renderRulesDialog() }
        { this.renderAddInTripDialog() }
        { this.renderTransferNoticeDialog() }
      </>
    );
  }
}

export { NormalCart };
