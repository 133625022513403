import Store from './store';
import ACTION from './action';

import { SERVICETYPE } from '../../constants/serviceType';

import { IData, ResType, IError, ICart } from '../../types/booking';
import { PaymentMethodTypes } from '../../types/paymentMethod';

const NOACCESSERROR = 'Недостаточно прав для бронирования поездки. Обратитесь к администратору аккаунта вашей компании';

class Booking {
  api: any;
  store: any;
  errorRequest: null;

  constructor(api: any) {
    this.api = api.cart;
    this.store = Store();
    this.errorRequest = null;
  }

  getState = () => this.store.getState();

  getServiceName = (service: string, data: IData) => {
    let serviceName = '';
    switch (service) {
      case SERVICETYPE.AIR: {
        const places = data.Routes
          .map(route => `${route.Segments[0].DepartureCity}-${route.Segments[route.Segments.length - 1].ArrivalCity}`);
        serviceName = `${places.join('; ')}`;
        break;
      }
      case SERVICETYPE.HOTEL: {
        serviceName = `${data.hotel.Name}`;
        break;
      }
      case SERVICETYPE.TRAIN: {
        serviceName = `${data.StationDepart} ${data.StationArrive}`;
        break;
      }
      case SERVICETYPE.TRANSFER: {
        serviceName = `${data.StartPlace.Address} ${data.EndPlace.Address}`;
        break;
      }
    }

    return serviceName;
  };

  startBookingPaymentMethods = ({ Id }: { Id: number }, paymentMethod: PaymentMethodTypes) =>
    this.api.startBookingPaymentMethods(Id, paymentMethod)
      .then((res: ResType) => {
        let payload;

        if (typeof res === 'string') {
          payload = {
            bookId: res,
            errorMessages: [],
          };
        } else {
          const { BookId, ErrorMessages } = res;

          payload = {
            bookId: BookId,
            errorMessages: ErrorMessages,
          };
        }

        this.store.dispatch({
          type: ACTION.STARTBOKING,
          payload,
        });

        return typeof res === 'string' ? null : res?.OrderId;
      })
      .catch((e: IError) => {
        const Message = e.status === 403 ? NOACCESSERROR : e.message;
        const { cart: { Items } } = this.getState();

        this.store.dispatch({
          type: ACTION.UPDATEERROR,
          payload: [{
            Message,
            CartItem: Items[0],
          }],
        });
      });

  getBookingStatusPaymentMethods = async (id:string | null = null) => {
    let bookingId;

    if (id) {
      bookingId = id;
    } else {
      bookingId = this.getState().bookingId ? this.getState().bookingId : this.getState().cart.BookId;
    }

    if (!bookingId) {
      return;
    }

    const payload = await this.api.getBookingStatusPaymentMethods(bookingId);
    this.store.dispatch({
      type: ACTION.UPDATESTATUSV2,
      payload,
    });
  };
  // @ts-ignore
  getBookingStatusPaymentMethods = async (id:string | null = null) => {
    const { bookingId } = id ? { bookingId: id } : this.getState();

    if (!bookingId) {
      return;
    }

    const payload = await this.api.getBookingStatusPaymentMethods(bookingId);
    this.store.dispatch({
      type: ACTION.UPDATESTATUSV2,
      payload,
    });
  };

  updateCart = (payload: ICart) => this.store.dispatch({
    type: ACTION.UPDATECART,
    payload,
  });

  applyChanges = async (cartId: string, itemId: string | null, apply: boolean) => {
    await this.api.applyChanges(cartId, itemId, apply);
    this.store.dispatch({ type: ACTION.APPLYCHANGES });
  };

  applyChangesHotel = async (cartItemId: string, rateId: string) => {
    const { bookingId } = this.getState();

    if (!bookingId) {
      return;
    }

    await this.api.applyChangesHotel(cartItemId, rateId, bookingId);
  };

  continueBookingPaymentMethods = async (cartId: string) => {
    const res = await this.api.continueBookingPaymentMethods(cartId);

    return this.store.dispatch({
      type: ACTION.CONTINUEBOOKING,
      payload: {
        errorMessages: res?.ErrorMessages ?? [],
      },
    });
  };

  cancelBookingPaymentMethods = (cartId: string) => this.api.cancelBookingPaymentMethods(cartId);

  setCartItem = (item: ICart) => {
    this.store.dispatch({
      type: ACTION.LOADCART,
      payload: item,
    });

    return item;
  };

  resetStore = () => {
    this.store.dispatch({
      type: ACTION.RESET,
    });
  };

  loadAnalogRooms = (cartItemId: string, rateId: string) => this.api.loadAnalogRooms(rateId).then((res = []) => this.store.dispatch({
    type: ACTION.LOADANALOGROOMS,
    payload: {
      cartItemId,
      rateId,
      analogRooms: res || [],
    },
  }));

  updateAnalogRooms = (roomId: string, cartItemId: string, value: any) => this.store.dispatch({
    type: ACTION.UPDATEANALOGROOMS,
    payload: {
      roomId,
      cartItemId,
      value,
    },
  });

  subscribe = (cb: any) => this.store.subscribe(cb);
}

export default Booking;
