import CONFIG from '../../../../config';

import { getText, isRuLng } from '../../../../i18n';

import Api from '../../api';

import { Amplitude, MainAnalytic } from '../../utils/analytics';
import { accountType } from '../../utils/accountType';
import {
  BUYTRIPSACCOUNTRIGHT,
  BUYTRIPSPERSONALRIGHT,
  EDITACCESSRIGHT,
  EDITEMPLOYEERIGHT,
  FINANCEDOCUMENTSRIGHT,
  VIEWTRIPSRIGHT,
} from '../../constants/rights';

import { ACCOUNTS } from '../../constants/accounts';
import { TYPEUPD, TYPEUPDRU, TYPEUPDRU_SMARTAGENT } from './constants/schemes';
import ACTION from './action';

import WorkspaceStore from './store';
import { accountStore } from './stores/account';

import MoneyFormat from '../../utils/money';
import { isSmartAgent } from '../../utils/env';

import { Account } from './types';
import { ICompanyFunds } from '../../types/app';
import { ICheckoutDataItem } from '../checkout/types';

const LABELS = {
  NOT_ENOUGH_MONEY: {
    BALANCE: (amount: string) => getText('services:workspace.bookStatus.notEnoughMoney.balance', { amount }),
    TOTAL_BALANCE: (amount: string, balance: string) => getText('services:workspace.bookStatus.notEnoughMoney.totalBalance', {
      amount,
      balance,
    }),
  },
  HAVE_DEBT: (debt: string) => getText('services:workspace.bookStatus.haveDebt', { debt }),
  RESTRICTED_ACCESS: getText('services:workspace.bookStatus.restrictedAccess'),
  LIMIT_EXCEEDED: getText('services:workspace.bookStatus.limitExceeded'),
  DISABLED_ACCOUNT: getText('services:workspace.bookStatus.disabledAccount'),
  SPECIAL_BILL_VALIDATION: getText('services:workspace.bookStatus.specialBillValidation'),
};

class Workspace {
  api: Api;
  xhr: null | any;
  store: ReturnType<typeof WorkspaceStore>;
  accountStore = accountStore;

  bookStatusEnum = {
    NotEnoughMoney: 'NotEnoughMoney',
    HaveDebt: 'HaveDebt',
    CanBook: 'CanBook',
    RestrictedAccess: 'RestrictedAccess',
    LimitExceeded: 'LimitExceeded',
    DisabledAccount: 'DisabledAccount',
    SpecialBillValidationError: 'SpecialBillValidationError',
  };

  bookStatus = {
    NotEnoughMoney: {
      canBook: false,
      msg: (amountToPay: number, amountToBooking: number, showTotalBalance: boolean) => (showTotalBalance
        ? LABELS.NOT_ENOUGH_MONEY.TOTAL_BALANCE(MoneyFormat.money(amountToPay, true), MoneyFormat.money(amountToBooking, true))
        : LABELS.NOT_ENOUGH_MONEY.BALANCE(MoneyFormat.money(amountToPay, true)).toString()),
    },
    HaveDebt: {
      canBook: false,
      msg: (debt: number) => LABELS.HAVE_DEBT(MoneyFormat.money(debt, true)),
    },
    CanBook: {
      canBook: true,
      msg: null,
    },
    RestrictedAccess: {
      canBook: false,
      msg: LABELS.RESTRICTED_ACCESS,
    },
    LimitExceeded: {
      canBook: false,
      msg: LABELS.LIMIT_EXCEEDED,
    },
    DisabledAccount: {
      canBook: false,
      msg: LABELS.DISABLED_ACCOUNT,
    },
    SpecialBillValidationError: {
      canBook: false,
      msg: LABELS.SPECIAL_BILL_VALIDATION,
    },
  };

  constructor(api: Api) {
    this.api = api;
    this.xhr = null;
    this.store = WorkspaceStore();
  }

  get(): Account {
    return this.store.getState();
  }

  getAccount = async () => {
    const res = await this.api.workspace.account();
    const admin = await this.api.workspace.getAdmin();
    const userId = res?.Rights?.UserId || admin?.id;

    accountStore.setUserId(userId);

    Amplitude.setUser(res.ShortCompanyName);
    MainAnalytic.setUser(res);
    MainAnalytic.initAmplitude(userId, res?.Rights?.AccountId, res?.CompanyId, accountType());

    this.store.dispatch({
      type: ACTION.GETACCOUNT,
      payload: res,
    });

    return { ...res, userId };
  };

  isMultiCompany = (): boolean => this.get().Multi;

  get rights() {
    return this.get().Rights;
  }

  get travelPolicy() {
    return this.get().TravelPolicy;
  }

  get canEditRights() {
    return this.rights.EditRights === EDITACCESSRIGHT.Available;
  }

  get canEditEmployee() {
    return this.rights.EditEmployee === EDITEMPLOYEERIGHT.All;
  }

  get canBuyAccount() {
    return this.rights.BuyTripAccount !== BUYTRIPSACCOUNTRIGHT.Unavailable;
  }

  get canBuyPersonal() {
    return this.rights.BuyTripPersonal !== BUYTRIPSACCOUNTRIGHT.Unavailable;
  }

  get canEditSelf() {
    return this.rights.EditEmployee === EDITEMPLOYEERIGHT.Personal;
  }

  get canViewReports() {
    return this.rights.Finance === FINANCEDOCUMENTSRIGHT.Available;
  }

  get canBuyInsurance() {
    return this.rights.BuyInsurance;
  }

  get canBuyOnlySelf() {
    return !this.canBuyAccount && this.rights.BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unavailable;
  }

  get canBuyPersonalUnlimited() {
    return this.rights.BuyTripPersonal === BUYTRIPSPERSONALRIGHT.Unlimited;
  }

  get canBuyAccountUnlimited() {
    return this.rights.BuyTripAccount === BUYTRIPSPERSONALRIGHT.Unlimited;
  }

  get isDemo() {
    return this.get().IsDemo;
  }

  get isDisabledAccount() {
    return !this.get().IsEnabled;
  }

  get canUseIntercom() {
    return this.rights.CanUseIntercom;
  }

  get isAdmin() {
    return !this.get().Rights.UserId;
  }

  get AccountEmail() {
    return this.get().Email;
  }

  get isAccountOfBookingCom() {
    return this.get().UserName === ACCOUNTS.BOOKINGCOM;
  }

  get companyId() {
    return this.get().CompanyId;
  }

  get canCreateExpenseReports() {
    return this.rights.CreateExpenseReports;
  }

  get isViewPersonalTrips() {
    return this.rights.ViewTrips === VIEWTRIPSRIGHT.Personal;
  }

  get isPreSchemeFromRights() {
    const { BuyTripPersonal, BuyTripAccount } = this.rights;

    return BuyTripPersonal === BUYTRIPSPERSONALRIGHT.ApprovalScheme
        || BuyTripAccount === BUYTRIPSACCOUNTRIGHT.ApprovalScheme;
  }

  get dataUserEmployee() {
    const { Rights: { EmployeeId }, Companies } = this.get();

    return {
      onlyOwnTrips: !this.canBuyAccount,
      employeeId: EmployeeId,
      companies: this.isMultiCompany() ? Companies : [],
    };
  }

  getPhoneForIntercom = () => {
    const user = this.get().Email;

    const { PHONE_CONTOUR_TRAVEL, PHONEWITHHYPHENS } = CONFIG.ABOUT;

    if (CONFIG.WORKSPACE.PHONES.CONTOUR_TRAVEL.includes(user)) {
      return PHONE_CONTOUR_TRAVEL;
    }

    return PHONEWITHHYPHENS;
  };

  checkAccessRights = (item: ICheckoutDataItem, companyFunds: ICompanyFunds[]) => {
    switch (item.State) {
      case this.bookStatusEnum.HaveDebt: {
        return {
          canBook: false,
          msg: this.bookStatus.HaveDebt.msg(item.Debt),
        };
      }
      case this.bookStatusEnum.NotEnoughMoney: {
        const foundFunds = companyFunds.find(({ CompanyId }) => CompanyId === item.CompanyId);

        const shouldShowTotalBalance = this.isAdmin
            || !foundFunds?.MaxVisibleBookingAmount
            || !!(foundFunds?.MaxVisibleBookingAmount && item.AmountToBooking < foundFunds?.MaxVisibleBookingAmount);

        return {
          canBook: false,
          msg: this.bookStatus.NotEnoughMoney.msg(item.AmountToPay, item.AmountToBooking, shouldShowTotalBalance),
        };
      }
      case this.bookStatusEnum.RestrictedAccess: {
        return {
          ...this.bookStatus.RestrictedAccess,
        };
      }
      case this.bookStatusEnum.LimitExceeded: {
        return {
          ...this.bookStatus.LimitExceeded,
        };
      }
      case this.bookStatusEnum.DisabledAccount: {
        return {
          ...this.bookStatus.DisabledAccount,
        };
      }
      case this.bookStatusEnum.SpecialBillValidationError: {
        return {
          ...this.bookStatus.SpecialBillValidationError,
        };
      }
      default: {
        return {
          ...this.bookStatus.CanBook,
        };
      }
    }
  };

  getPeriodSchemeDescription = (currentCompanyId: number) => {
    const { Companies } = this.get();

    const company = Companies ? Companies.find((item) => item.CompanyId === currentCompanyId) : null;
    const companyPeriod = company ? company.PeriodPlan : null;

    if (isSmartAgent && isRuLng()) {
      return companyPeriod ? TYPEUPDRU_SMARTAGENT[companyPeriod] : TYPEUPDRU_SMARTAGENT[TYPEUPD.BIMONTHLY];
    }

    return companyPeriod ? TYPEUPDRU[companyPeriod] : TYPEUPDRU[TYPEUPD.BIMONTHLY];
  };

  getCompaniesForReporting = () => {
    const { Companies } = this.get();

    const preparedCompanies = Companies.filter(({ IsShowReporting }) => IsShowReporting);

    return preparedCompanies.map(({ ShortCompanyName, CompanyId, CompanyName }) => ({
      label: ShortCompanyName || CompanyName,
      value: CompanyId,
    }));
  };

  getCompanyData() {
    const { Email, CompanyId, CompanyName, Companies, CompanyInn } = this.get();
    const reportingCompanies = this.getCompaniesForReporting();

    return { Email, CompanyId, CompanyName, Companies, CompanyInn, reportingCompanies };
  }

  getUsersEmailsByUserIds = (ids: number[]) => this.api.popups.getUserEmailsByUserIds(ids);

  subscribe = (cb: (item: any) => void) => this.store.subscribe(cb);
}

export default Workspace;
