import React, { ReactNode, useState } from 'react';
import { Button, Dialog, Icon, ItemPanel } from 'new-ui';
import { getText } from '../../../../../i18n';

import { SERVICETYPE, SERVICES_ICON } from '../../../../bi/constants/serviceType';

import { FavoriteAction } from '../../../../components/FavoriteAction';

import { TrainSearchStartDialog } from '../dialogs/TrainSearchStartDialog';
import { HotelSearchStartDialog } from '../dialogs/HotelSearchStartDialog';
import { AirlineSearchStartDialog } from '../dialogs/AirlineSearchStartDialog';

import styles from './style.module.css';

const LABELS = {
  BUTTON: getText('favorites:chooseDates'),
  START_SEARCH: getText('favorites:dialogs.transfer.startSearch'),
};

const DIALOG_FORM = {
  [SERVICETYPE.AIR]: AirlineSearchStartDialog,
  [SERVICETYPE.TRAIN]: TrainSearchStartDialog,
  [SERVICETYPE.HOTEL]: HotelSearchStartDialog,
};

const QA_ATTR_DELETE_DIALOG = {
  wrapper: '',
  close: '',
  buttons: {
    confirm: '',
    close: '',
  },
};

const QA_ATTR_DIALOG = {
  air: {
    wrapper: '',
    header: '',
    date: '',
    passengers: '',
    buttons: {
      startSearch: '',
      cancel: '',
    },
  },
  train: {
    wrapper: '',
    header: '',
    date: '',
    passengers: '',
    buttons: {
      startSearch: '',
      cancel: '',
    },
  },
  hotel: {
    wrapper: '',
    header: '',
    dateFrom: '',
    dateTo: '',
    guests: '',
    buttons: {
      startSearch: '',
      cancel: '',
    },
  },
};

interface IFavoriteItemWrapProps {
  type: string;
  children: ReactNode;
  dialogProps?: any,
  id?: string;
  onOpenDialog?(type: string): void;
  onRemoveItem(): void;
  onSearch(): void;
  qaAttrChooseDates?: string;
  qaAttrDelete?: string;
  qaAttrDeleteDialog?: any;
  qaAttrDialog?: any;
  qaAttrIcon?: string,
}

const FavoriteItemWrap = ({
  dialogProps = null,
  type,
  id = '',
  children,
  onOpenDialog = () => {},
  onRemoveItem,
  onSearch,
  qaAttrChooseDates = '',
  qaAttrDelete = '',
  qaAttrIcon = '',
  qaAttrDeleteDialog = QA_ATTR_DELETE_DIALOG,
  qaAttrDialog = QA_ATTR_DIALOG,
}: IFavoriteItemWrapProps) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);

    if (onOpenDialog && openDialog) {
      onOpenDialog(type);
    }
  };

  const getDialogQaAttr = () => {
    switch (type) {
      case SERVICETYPE.AIR: {
        return qaAttrDialog.air;
      }
      case SERVICETYPE.TRAIN: {
        return qaAttrDialog.train;
      }
      case SERVICETYPE.HOTEL: {
        return qaAttrDialog.hotel;
      }
      default:
        return {};
    }
  };

  const renderDialog = () => {
    const Form = DIALOG_FORM[type];
    const qaAttrs = getDialogQaAttr();

    return (
      <Dialog
        show={ openDialog }
        onChange={ handleOpenDialog }
        qaAttrWrapper={ qaAttrs.wrapper }
      >
        <Form
          { ...dialogProps }
          onSearch={ onSearch }
          onClose={ handleOpenDialog }
          qaAttrs={ qaAttrs }
        />
      </Dialog>
    );
  };

  const renderHeader = () =>
    (
      <div className={ styles.header }>
        <div className={ styles.action }>
          <FavoriteAction
            id={ id }
            onClick={ onRemoveItem }
            qaAttrIcon={ qaAttrDelete }
            qaAttrDialog={ qaAttrDeleteDialog }
          />
        </div>
      </div>
    );
  const dialogHtml = openDialog && renderDialog();

  const searchTransfer = () => onSearch();
  const buttonAction = type === SERVICETYPE.TRANSFER ? searchTransfer : handleOpenDialog;
  const label = type === SERVICETYPE.TRANSFER ? LABELS.START_SEARCH : LABELS.BUTTON;

  return (
    <ItemPanel renderHeader={ renderHeader } >
      <div className={ styles.wrapper }>
        <div className={ styles.content }>
          <div className={ styles.icon }>
            <Icon qaAttr={ `${qaAttrIcon}-${type}` } type={ SERVICES_ICON[type] } />
          </div>
          <div className={ styles.children }>
            { children }
          </div>
        </div>
        <div className={ styles.action }>
          <Button
            onClick={ buttonAction }
            qaAttr={ qaAttrChooseDates }
          >
            { label }
          </Button>
        </div>
      </div>
      { dialogHtml }
    </ItemPanel>
  );
};

export { FavoriteItemWrap };
