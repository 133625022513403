import CONFIG from '../../../../config';

import { api } from '../../apiV2';

import { widget } from './widget';

import { PaymentMethodsStore, CloudPaymentStoreType } from './store';

import { calculationAmount } from '../../utils/cloudPayments';

import {
  NOT_NUMBERS,
  ONLY_NUMBERS_OR_EMPTY,
  FOUR_GROUPS_FOUR_NUMBERS,
  MORE_ONE_SPACE,
  GROUP_NOT_NUMBERS,
} from '../../constants/regExp';

import { PaymentMethodTypes, ERRORS_CARD_FORM, PAYMENT_INFO } from '../../types/paymentMethod';

class CloudPaymentService {
  store: CloudPaymentStoreType;
  api: typeof api.employeePersonalPayment;

  constructor() {
    this.store = PaymentMethodsStore;
    this.api = api.employeePersonalPayment;
  }

  getPaymentMethod = () => this.store.paymentMethod;

  getAccountId = () => this.store.accountId;

  setAccountId = (accountId: string) => {
    this.store.setAccountId(accountId);
  };

  getCanPayWithPersonalFunds = () => this.store.canPayWithPersonalFunds;

  clearStore = () => {
    this.store.clearStore();
  };

  setPaymentMethod = (paymentMethod: PaymentMethodTypes) => {
    this.store.setPaymentMethod(paymentMethod);
  };

  openPaymentWidget = (paymentInfo: Omit<PAYMENT_INFO, 'accountId'>, paymentType: string) => {
    widget({ ...paymentInfo, accountId: this.getAccountId() }, paymentType);
  };

  onChangeCardNumber = (value: string) => {
    let cardNumberWithSpaces = value;

    const cardValue = cardNumberWithSpaces
      .replace(NOT_NUMBERS, '')
      .match(FOUR_GROUPS_FOUR_NUMBERS);

    if (cardValue) {
      cardNumberWithSpaces = !cardValue[2]
        ? cardValue[1]
        : `${cardValue[1]}-${cardValue[2]}${`${
          cardValue[3] ? `-${cardValue[3]}` : ''
        }`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`;
    }

    const numbers = cardNumberWithSpaces.replace(GROUP_NOT_NUMBERS, ' ');

    this.store.setCardNumber(numbers);
  };

  onChangeExpDate = (value: string) => {
    let expDate = value.replace(NOT_NUMBERS, '');

    if (expDate.length >= 3) {
      expDate = `${expDate.substring(0, 2)}/${expDate.substring(2, 4)}`;
    }

    if (expDate.length >= 1 && (parseInt(expDate[0], 10) > 1 || parseInt(expDate[1], 10) > 2)) {
      expDate = expDate[0] === '1' ? '1' : '';
      expDate = expDate[1] > '2' ? '' : expDate[1];
    }

    this.store.setExpDate(expDate);
  };

  onChangeCvv = (value: string) => {
    const cvv = value.replace(NOT_NUMBERS, '');

    if (value.length < 4) {
      this.store.setCvv(cvv);
    }
  };

  onChangeEmail = (value: string) => {
    this.store.setEmail(value);
  };

  onChangeAmountOfDeposit = (value: string) => {
    const withoutSpaces = value.replace(MORE_ONE_SPACE, '');
    const { transactionFee } = calculationAmount(String(value), true);

    if (ONLY_NUMBERS_OR_EMPTY.test(withoutSpaces)) {
      this.store.setAmountOfDeposit(value);
      this.store.setCommission(String(transactionFee));
    }
  };

  createPaymentCryptogram = async () => {
    const { cvv, cardNumber, expDate } = this.store.cardForm;
    const splitDate = expDate.split('/');
    const expDateMonth = splitDate[0];
    const expDateYear = splitDate[1] || '';

    // @ts-ignore
    const checkout = new cp.Checkout({
      publicId: CONFIG.CLOUDPAYMENTS.API_KEY,
    });

    try {
      const result = await checkout.createPaymentCryptogram({
        cvv,
        cardNumber,
        expDateMonth,
        expDateYear,
      });

      this.store.setCryptogram(result);
      this.store.setCardFormErrors({});

      return result;
    } catch (e) {
      this.store.setCardFormErrors(e as ERRORS_CARD_FORM);

      return e;
    }
  };

  payViaApi = async () => {
    const { email, cryptogram, amountOfDeposit, commission } = this.store;
    const result = await this.api.payViaAPI({
      CardCryptogramPacket: cryptogram,
      Amount: amountOfDeposit,
      Commission: commission,
      Email: email,
    });

    return result;
  };
}

export default CloudPaymentService;
