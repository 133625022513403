import React from 'react';
import { Checkbox, StyledWrapper, Text } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import ACTION from '../../../../../../bi/services/hotels/action';
import { HotelFilters } from '../../../../../../bi/services/hotels/types';

import { QA_ATTRIBUTES } from '../../../../../../bi/constants/attributesForTests';
import { MEAL_FIELDS } from '../../../../../../bi/constants/hotel';

import styles from './styles/index.module.css';

const LABELS = {
  ONLINE: getText('hotels:regionResult.filters.onlyOnline'),
  HASCANCELLATION: getText('hotels:regionResult.filters.freeCancellation'),
  TITLE: {
    BOOKING: getText('hotels:regionResult.filters.booking'),
    MEAL: getText('hotels:regionResult.filters.placeholder'),
  },
};

interface FiltersProps {
  filters: HotelFilters,
  disabled: boolean
  updateFilters(field: string, value: { filterName: string, value: boolean | number[] }): void,
}

const Filters = ({
  filters,
  updateFilters,
  disabled,
}: FiltersProps) => {
  const updateOnlineFilter = (value: boolean) => updateFilters(ACTION.UPDATEHOTELFILTER, { filterName: 'online', value });

  const updateBreakfastFilter = (value: number) => {
    let updMeal = [...filters.meal];

    if (filters.meal.includes(value)) {
      updMeal = updMeal.filter((mealNumber) => mealNumber !== value);
    } else {
      updMeal.push(value);
    }

    updateFilters(ACTION.UPDATEHOTELFILTER, { filterName: 'meal', value: updMeal });
  };

  const updateCancellationFilter = (value: boolean) => updateFilters(ACTION.UPDATEHOTELFILTER, { filterName: 'hasCancellation', value });

  const renderOnline = () => (
    <Checkbox
      // @ts-ignore
      id={ 'check_online' }
      value={ filters.online }
      onChange={ updateOnlineFilter }
      disabled={ disabled }
    >
      <Text qaAttr={ QA_ATTRIBUTES.hotels.current.online } type='NORMAL_14'>
        { LABELS.ONLINE }
      </Text>
    </Checkbox>
  );

  const renderMeal = () => MEAL_FIELDS.map(({ value, label }, index) => {
    const selectedTypeMeal = filters.meal.includes(value);
    const disabledCheckbox = (filters.meal.includes(0) && (value === 1 || value === 6 || value === 8))
      || ((filters.meal.includes(1) || filters.meal.includes(6) || filters.meal.includes(8)) && value === 0);

    return (
      <Checkbox
        // @ts-ignore
        id={ 'check_breakfast' }
        value={ selectedTypeMeal }
        onChange={ () => updateBreakfastFilter(value) }
        disabled={ disabled || disabledCheckbox }
        key={ index }
      >
        <Text qaAttr={ QA_ATTRIBUTES.hotels.current.breakfast } type='NORMAL_14'>
          { label }
        </Text>
      </Checkbox>
    );
  });

  const renderRefundability = () => (
    <Checkbox
      // @ts-ignore
      id={ 'has_cancellation' }
      value={ filters.hasCancellation }
      onChange={ updateCancellationFilter }
      disabled={ disabled }
    >
      <Text qaAttr={ QA_ATTRIBUTES.hotels.current.hasCancellation } type='NORMAL_14_130'>
        { LABELS.HASCANCELLATION }
      </Text>
    </Checkbox>
  );

  return (
    <div className={ styles.wrapper }>
      <StyledWrapper className={ styles.blocks }>
        <div className={ styles.block_filters }>
          <Text type='SEMIBOLD_18'>{ LABELS.TITLE.BOOKING }</Text>
          <div className={ styles.block_checkboxes }>
            { renderOnline() }
            { renderRefundability() }
          </div>
        </div>
        <div className={ styles.block_filters }>
          <Text type='SEMIBOLD_18'>{ LABELS.TITLE.MEAL }</Text>
          <div className={ styles.block_checkboxes }>
            { renderMeal() }
          </div>
        </div>
      </StyledWrapper>
    </div>
  );
};

export default Filters;
