import React from 'react';
import { observer } from 'mobx-react';
import { Text, Button, DotLoading, UniversalTable } from 'new-ui';

import { useServices } from '../../../../bi/context/services';

import { StatusCondition } from '../../../../components/StatusCondition';
import { EmployeeSuggest } from '../../../../components/EmployeeSuggest';
import { GovServiceAuth } from './components/GovServiceAuth';

import { useModal } from './hooks';

import { LABELS, tableSettings } from './consts';

import { LoadingStatus } from '../../../../bi/services/utils/network/types';
import { LoadingFields } from '../../../../bi/services/signature';

import styles from './styles.module.css';

export const DocumentsGosSign = observer(() => {
  const {
    signatureService: {
      employeeAutocomplete,
      downloadReport,
      sendToGov,
      netStore,
      enhancedSignatureStore: {
        setSelectedIdsList,
        setInputParameter,
        selectedEmployee,
        selectedIds,
        integrationEnabled,
        listInputParams,
        signListData,
      },
    },
    workspaceService: { isAdmin },
  } = useServices(['Signature', 'Workspace']);

  const ModalComponent = useModal();

  const selectable = isAdmin && integrationEnabled;

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.topBlock }>
        <Text type='bold_24'>
          { LABELS.TITLE }
        </Text>
        <div className={ styles.actionsRow }>
          <Button type='textual'>
            <Text type='NORMAL_16' color='accent'>
              { LABELS.HOW_IT_WORK }
            </Text>
          </Button>
          <div className={ styles.actionsBlock }>
            <StatusCondition
              status={ netStore.getStatus(LoadingFields.downloadReport) }
              onLoading={ DotLoading }
              fallback={ () => (
                <Button type='textual' onClick={ downloadReport }>
                  <Text type='NORMAL_16' color='accent'>
                    { LABELS.DOWNLOAD_REPORT_XLSX }
                  </Text>
                </Button>
              ) }
            />
          </div>
        </div>
        <div className={ styles.actionsRow }>
          <div className={ styles.userSelect }>
            <EmployeeSuggest
              value={ selectedEmployee }
              onSelect={ ({ Id }) => setInputParameter('employeeId')(Id) }
              onFetch={ employeeAutocomplete }
              withFetching
              debounceMs={ 400 }
            />
          </div>
          <div className={ styles.actionsBlock }>
            <GovServiceAuth />
            <Button
              type='secondary'
              loading={ netStore.getIsStatus(LoadingFields.sendToGov, LoadingStatus.LOADING) }
              onClick={ sendToGov }
              disabled={ selectedIds.length === 0 }
            >
              { LABELS.SEND_TO_GOV }
            </Button>
          </div>
        </div>
      </div>
      <UniversalTable
        items={ signListData.documents }
        settings={ tableSettings(selectable) }
        selected={ selectedIds }
        onChange={ setSelectedIdsList }
        total={ signListData.count }
        currentPage={ listInputParams.page }
        itemsPerPage={ listInputParams.limit }
        onChangePage={ setInputParameter('page') }
      />
      <ModalComponent />
    </div>
  );
});
