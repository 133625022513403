import React, { Component, ReactElement, RefObject } from 'react';
import { observer } from 'mobx-react';
import {
  StyledWrapper,
  Text,
  PageLoader,
  BackLink,
  Dialog,
  Button,
  SidePanel,
  Icon,
} from 'new-ui';
import { RouteComponentProps } from 'react-router-dom';
import { UseStoresInterface, withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { getText } from '../../../i18n';

import { AddingTripItem } from './components/AddingTripItem';
import TripItem from '../../components/TripItem';
import TripDetailsDialog from '../../components/TripDetailsDialog';
import ApproveActionDialog from '../../components/ApproveActionDialog';
import InputModal from '../../components/InputModal';
import AnalyticsBar from '../../components/AnalyticsBar';
import TripHeader from './components/TripHeader';
import { TripPlan } from './components/TripPlan';
import { Insurance } from '../../components/Insurance';
import { InsuranceSidePanel } from '../../components/Insurance/components/InsuranceSidePanel';

import parseUnix from '../../bi/utils/parseDateTime';
import parseJSON from '../../bi/utils/parseJsonString';
import { MainAnalytic } from '../../bi/utils/analytics';
import { getMoment, formatRangeDateWithSimplicity } from '../../bi/utils/formatDate';
import {
  compareByAirCertificate,
  getAirTripItemId,
  getAllAirIds,
  getAllFlightCertificateIds,
  isAirTicketHasCertificate,
} from '../../bi/utils/airline';

import getAccountId from '../../bi/utils/getAccountId';
import {
  getTripItems,
  getTripItemsByGroups,
  ITripItemsByGroups,
  prepareTripDialogTitle,
} from '../../bi/utils/tripDetails';
import { CompanySetting, getFreeFlightCertificate } from '../../bi/utils/accountSettings';
import { isSmartAgent } from '../../bi/utils/env';
import { combinedTripAndAdditionalServices } from '../../bi/utils/airlineAdditionalServices';

import {
  ANALYTIC_SERVICE_TYPE_MATCH, ANALYTIC_SERVICE_TYPE_MATCH_SEARCH,
  DIALOG_TYPES,
  SERVICETYPE,
  ANALYTIC_SERVICE_TYPES,
} from '../../bi/constants/serviceType';
import NOTIFICATIONS from '../../bi/constants/notifications';
import { TRIPSTATUSES, TRIPSTATUS } from '../../bi/constants/trips';
import { TAXI_GROUP } from '../../bi/constants/taxi';
import { POPUP_POSITIONS, POPUP_NAME } from '../../bi/constants/popups';
import { ICON_TYPES_MAP } from '../../bi/constants/app';
import { withoutMentionSmartway } from '../../bi/constants/companiesRules';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import ChatService from '../../bi/services/chat';
import AppService from '../../bi/services/app';
import NotificationService from '../../bi/services/notification';
import OrderService from '../../bi/services/order';
import ReportService from '../../bi/services/report';
import WorkspaceService from '../../bi/services/workspace';
import UserSessionService from '../../bi/services/userSession';
import HotelsService from '../../bi/services/hotels';
import FavoritesService from '../../bi/services/favorites';
import SettingsService from '../../bi/services/settings';
import DepartmentsService from '../../bi/services/departments';
import FeatureFlagsService from '../../bi/services/featureFlags';
import AccountSettingsService from '../../bi/services/accountSettings';
import CustomAnalyticsService from '../../bi/services/customAnalytics';
import { TripComments as TripCommentsService } from '../../bi/services/tripComments';
import RequestsService from '../../bi/services/requests';
import PopupsService from '../../bi/services/popups';
import EventService from '../../bi/services/events';
import UiSettingsService from '../../bi/services/uiSettings';

import { UiSettingsProtocol } from '../../bi/protocols/uiSettings';
import { IProjects } from '../../bi/types/employees';
import { UiLanguageType } from '../../bi/services/uiSettings/types';
import { IMatch } from '../../bi/types/airlineAdditionalServices';
import { IOrderStore } from '../../bi/services/order/types';
import { AirlineJsonData } from '../../bi/types/airline';
import { IOrderDetailsData, OrderItem } from '../../bi/types/order';
import { IMenuItem } from '../../bi/types/app';
import { IInsuranceServices, IDates } from '../../bi/types/insurance';
import { IAdditionalService } from '../../bi/types/trips';
import { IVoucherTravellersWithId } from '../../bi/services/hotels/types';
import TripTagsService from '../../bi/services/tripTags';

import styles from './index.module.css';

const FIELDS = {
  EMAIL: 'email',
};

const INSURANCE_STATUS = {
  INSURED: 'Insured',
};

const LABELS = {
  PAGE_LOADER: getText('trip:loader'),
  BACK: getText('trip:back'),
  TO_CLOSE: getText('trip:toClose'),
  CANCELLED_TRIPS: getText('trip:cancelledTrips'),
  EMAIL: getText('trip:putEmail'),
  SEND_TRIPS: getText('trip:sendTrips'),
  SEND_EMAIL_INVOICE: getText('trip:sendEmailInvoice'),
  SEND_AIR: getText('trip:sendAir'),
  SEND_APPROVING: getText('trip:sendApprove'),
  SEND_SERVICE_FORM: getText('trip:sendServiceForm'),
  TRIP_WAS_SENT: getText('trip:wasSent'),
  VOUCHER_TITLE: getText('taxi:voucher.title'),
  ADD_TO_TRIP: getText('trip:addToTrip'),
  ADD_TO_TRIP_TITLE: getText('trip:addToTripTitle'),
  TRIP_PLAN: getText('trip:tripPlan.tripPlan'),
};

const MESSAGE_SENT = {
  CANCEL: 'cancel',
  EDIT: 'edit',
  REFUND: 'refund',
};

const mapOperatingAirlineIntoAirline = (json: string) => {
  const parsedJSON: AirlineJsonData = JSON.parse(json);
  const newValue = {
    ...parsedJSON,
    Routes: parsedJSON.Routes.map((ri) => ({
      ...ri,
      Segments: ri.Segments.map((si) => ({
        ...si,
        Airline: si.Airline || si.OperatingAirline,
      })),
    })),
  };

  return JSON.stringify(newValue);
};

const getVoidTimeLimit = (jsonData: string) => {
  const data = parseJSON(jsonData);

  if (!data) {
    return null;
  }

  const { TicketsExtended = [{}] } = data;

  const voidTimeLimitTimestamp = TicketsExtended[0].VoidTimeLimitUTC;

  if (!voidTimeLimitTimestamp) {
    return null;
  }

  const voidTimeLimit = parseUnix(voidTimeLimitTimestamp);
  const currentDate = getMoment();

  if (voidTimeLimit.isBefore(currentDate)) {
    return null;
  }

  return voidTimeLimit;
};

interface IStores extends Pick<UseStoresInterface, 'settingsStore' | 'popupsStore' | 'uiSettingsStore' | 'eventStore'> {}

interface ITripDetailsProps {
  location:RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  chatService: ChatService;
  appService: AppService;
  notificationService: NotificationService;
  orderService: OrderService;
  reportService: ReportService;
  workspaceService: WorkspaceService;
  userSessionService: UserSessionService;
  hotelsService: HotelsService;
  favoritesService: FavoritesService;
  tripTagsService: TripTagsService;
  settingsService: SettingsService;
  departmentsService: DepartmentsService;
  featureFlagsService: FeatureFlagsService;
  accountSettingsService: AccountSettingsService;
  customAnalyticsService: CustomAnalyticsService;
  tripCommentsService: TripCommentsService;
  requestsService: RequestsService;
  popupsService: PopupsService;
  eventService: EventService;
  match: IMatch;
  companyNameSettings: string;
  uiSettingsProtocol: UiSettingsProtocol;
  uiSettingsService: UiSettingsService;
  aggregationId: number | null;
  stores: IStores;
  insuranceService: IInsuranceServices;
}

interface ITripDetailsState {
  url?: string;
  loading: boolean;
  loadingTripRequest: boolean;
  bookflightCertificateInfo: any;
  allFlightCertificateStatus: any;
  isChatLoading: boolean;
  details: IOrderDetailsData;
  item?: OrderItem;
  isShowSendTripModal: boolean;
  isShowSendInvoiceEmailModal: boolean;
  isShowPaymentInvoiceModal: boolean;
  showApproveDialog: boolean;
  showServiceDialog: boolean;
  showPlanTrip: boolean;
  showAddingTripDialog: boolean;
  itemsForddedTrip: IMenuItem[];
  projects: IProjects[];
  emailInput: {
    tripItemId: number | null;
    employeeId: number | null;
    serviceId: number | null;
    visible: boolean;
    serviceType: string;
    title: string;
  },
  tripRequest: {
    TripItemId: null;
    RequestId: null | number;
  },
  messageSent: any;
  modalType: string | null;
  paymentInvoiceMessage: string;
  CompanyFunds: any;
  rights: any;
  tripPlan: any;
  locale: UiLanguageType;
  isContur: boolean;
}

@withStores([
  MOBX_STORES.SETTINGS_STORE,
  MOBX_STORES.POPUPS_STORE,
  MOBX_STORES.UI_SETTINGS,
  MOBX_STORES.EVENT_STORE,
])
@observer
class TripDetails extends Component<ITripDetailsProps, ITripDetailsState> {
  vipHallRef: RefObject<HTMLDivElement>;
  tripPlanRef: RefObject<HTMLButtonElement>;
  addingOrderRef: RefObject<HTMLDivElement>;

  unsubscribeFn: () => void;
  unsubcribeChat: () => void;
  unsubcribeRequests: () => void;

  constructor(props: ITripDetailsProps) {
    super(props);
    const {
      loading,
      details,
      bookflightCertificateInfo,
      allFlightCertificateStatus,
      tripPlan,
    } = props.orderService.get();

    const {
      header: {
        CompanyFunds,
      },
    } = props.appService.get();

    const { stores: { uiSettingsStore: { settings: { companyName } } } } = props;

    const itemsForddedTrip = props.uiSettingsProtocol.getSearchMenuForAddingTrip(props.appService.servicesItems);

    const isContur = companyName === withoutMentionSmartway[0];

    this.state = {
      loading,
      loadingTripRequest: props.requestsService.get().loadingTripRequest,
      bookflightCertificateInfo,
      allFlightCertificateStatus,
      isChatLoading: false,
      details: details as IOrderDetailsData,
      item: {} as OrderItem,
      isShowSendTripModal: false,
      isShowSendInvoiceEmailModal: false,
      isShowPaymentInvoiceModal: false,
      showApproveDialog: false,
      showServiceDialog: false,
      showPlanTrip: false,
      showAddingTripDialog: false,
      itemsForddedTrip,
      projects: props.userSessionService.get().projects,
      emailInput: {
        tripItemId: null,
        employeeId: null,
        serviceId: null,
        visible: false,
        serviceType: '',
        title: '',
      },
      tripRequest: {
        TripItemId: null,
        RequestId: null,
      },
      messageSent: {},
      modalType: null,
      paymentInvoiceMessage: '',
      CompanyFunds,
      rights: {},
      tripPlan,
      locale: props.uiSettingsService.getCurrentLanguage(),
      isContur,
    };
    this.vipHallRef = React.createRef();
    this.tripPlanRef = React.createRef();
    this.addingOrderRef = React.createRef();
  }

  async componentDidMount() {
    const {
      match: { params: { tripId } },
      orderService,
      departmentsService,
      chatService,
      customAnalyticsService,
      requestsService,
      tripTagsService,
      settingsService,
      tripCommentsService,
      popupsService,
      eventService: {
        getEvent,
      },
      stores: {
        popupsStore: {
          popupsState,
          showTripPlanPopup,
        },
      },
    } = this.props;

    const { isContur } = this.state;

    this.unsubscribeFn = orderService.subscribeCart(this.updateState);
    this.unsubcribeChat = chatService.subscribe(this.updateStateChat);
    this.unsubcribeRequests = requestsService.subscribe(this.updateStateRequest);
    await orderService.load(tripId);
    settingsService.getProhibitionVouchers(getAccountId());
    departmentsService.loadList();
    customAnalyticsService.getAnalytics();
    // @ts-ignore
    requestsService.getTripsRequests(); // TODO как это работает???
    tripTagsService.loadAccountTripTags();
    tripCommentsService.load(tripId);

    const { details }: IOrderStore = orderService.get();

    if (details?.MICEEventId) {
      getEvent(details.MICEEventId);
    }

    this.loadOrderServiceInfo();

    const statusTripForTripPlanPopup = details?.Status === TRIPSTATUS.COMPLETED || details?.Status === TRIPSTATUS.BOOKED;

    if (showTripPlanPopup && !popupsState.trip_plan_popup && statusTripForTripPlanPopup && !isSmartAgent && !isContur) {
      popupsService.addTripPlanPopup(POPUP_POSITIONS.TOP_RIGHT_BELOW, POPUP_NAME.ADDING_ORDER_TRIP_PLAN, () => {
        popupsService.closePopupState(POPUP_NAME.ADDING_ORDER_TRIP_PLAN);
        document.body.classList.remove(styles.disable_scroll);
      });

      document.body.classList.add(styles.disable_scroll);
    }

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_DETAIL_SCREEN_OPENED(tripId),
    );
  }

  componentWillUnmount() {
    this.unsubscribeFn();
    this.unsubcribeChat();
    this.unsubcribeRequests();
    this.props.orderService.setResetStore();
  }

  async componentDidUpdate(prevProps: ITripDetailsProps) {
    const { location, match: { params: { tripId } } } = this.props;
    const prevTripId = prevProps.match.params.tripId;

    this.handleLocationPathnameUpdate(location.pathname);

    if (location.pathname !== this.state.url) {
      this.setState({ url: location.pathname });
    }

    if (tripId !== prevTripId) {
      await this.handleServiceSubscriptions();
      await this.handleDataLoading();
      this.loadOrderServiceInfo();
      this.sendAnalyticsEvent(tripId);
    }
  }

  handleLocationPathnameUpdate = (locationPathname: string) => {
    if (locationPathname !== this.state.url) {
      this.setState({ url: locationPathname });
    }
  };

  handleServiceSubscriptions = async () => {
    const {
      orderService,
      chatService,
      requestsService,
      tripCommentsService,
      match: { params: { tripId } },
    } = this.props;

    this.unsubscribeFn = orderService.subscribeCart(this.updateState);
    this.unsubcribeChat = chatService.subscribe(this.updateStateChat);
    this.unsubcribeRequests = requestsService.subscribe(this.updateStateRequest);

    await orderService.load(tripId);
    tripCommentsService.load(tripId);
  };

  handleDataLoading = async () => {
    const {
      departmentsService,
      customAnalyticsService,
      requestsService,
      tripTagsService,
      settingsService,
      eventService: {
        getEvent,
      },
      orderService,
    } = this.props;

    const { details } = orderService.get();

    if (details?.MICEEventId) {
      getEvent(details.MICEEventId);
    }

    settingsService.getProhibitionVouchers(getAccountId());
    departmentsService.loadList();
    customAnalyticsService.getAnalytics();
    // @ts-ignore
    requestsService.getTripsRequests();
    tripTagsService.loadAccountTripTags();
  };

  sendAnalyticsEvent = (tripId: string) => {
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_DETAIL_SCREEN_OPENED(tripId),
    );
  };

  loadOrderServiceInfo = async (withLoading = true) => {
    const {
      orderService,
      match: {
        params: { tripId },
      },
      accountSettingsService: { getCompanySettings },
    } = this.props;

    if (withLoading) {
      orderService.start();
    }

    await orderService.load(tripId);

    // @ts-ignore
    const { OrderItems } = orderService.get().details;

    const allFlightCertificateIds = getAllFlightCertificateIds(OrderItems);

    const companiesSettings: CompanySetting[] = getCompanySettings();
    const isFreeFlightCertificate = getFreeFlightCertificate(companiesSettings, OrderItems[0]?.ActualVersion?.CompanyId);

    const allAirIds = getAllAirIds(OrderItems);

    orderService.getAllStatusesFlightCertificate([...allAirIds, ...allFlightCertificateIds], isFreeFlightCertificate);
  };

  updateStateChat = () => {
    const { isRequesting } = this.props.chatService.get();

    this.setState({ isChatLoading: isRequesting });
  };

  updateState = ({
    details,
    loading,
    bookflightCertificateInfo,
    allFlightCertificateStatus,
    tripPlan,
  }: IOrderStore) => {
    const userSessionServiceGet = this.props.userSessionService.get();
    const workspace = this.props.workspaceService.get();

    this.setState({
      bookflightCertificateInfo,
      allFlightCertificateStatus,
      rights: workspace.Rights,
      details: details as IOrderDetailsData,
      loading,
      projects: userSessionServiceGet.projects,
      tripPlan,
    });
  };

  updateStateRequest = () => {
    const {
      loadingTripRequest,
    } = this.props.requestsService.get();

    this.setState({
      loadingTripRequest,
    });
  };

  fetchTripDetails = (id: string) => this.props.orderService.load(id);

  handleShowPlanTrip = (value: boolean) => {
    const { popupsService } = this.props;

    this.setState({ showPlanTrip: value });
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.TRIP_DETAIL_TP_BUTTON_PRESSED);
    popupsService.setShowTripPlanPopup(false);
  };

  handleTripRename = (id: number, name: string) =>
    this.props.orderService.rename(id, { Name: name }).then(() =>
      this.setState({
        details: {
          ...this.state.details,
          Name: name,
        },
      }),
    );

  handleHideSendTripModal = () =>
    this.setState({
      isShowSendTripModal: false,
    });

  handleSendInvoiceEmailModal = () =>
    this.setState({
      isShowSendInvoiceEmailModal: false,
    });

  onShowPaymentInvoice = (paymentInvoiceMessage: string) => (
    this.setState({
      paymentInvoiceMessage,
      isShowPaymentInvoiceModal: true,
    })
  );

  onShowPaymentEmailInvoice = () => {
    this.setState({
      isShowSendInvoiceEmailModal: true,
    });

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.TRIP_DETAIL_INVOICE_PRESSED);
  };

  onClosePaymentInvoice = () =>
    this.setState({
      isShowPaymentInvoiceModal: false,
    });

  goChatPaymentInvoice = (tripId: number) => {
    this.setState({
      isShowPaymentInvoiceModal: false,
    });

    this.props.orderService.goChatPaymentInvoice(tripId);
  };

  handleShowAddingTrip = (value: boolean) =>
    this.setState({
      showAddingTripDialog: value,
    });

  handleSendTrip = (email: string) => {
    const { orderService, notificationService } = this.props;
    const { OrderItems, Status } = this.state.details;

    this.handleHideSendTripModal();

    orderService
      .send({
        tripId: this.state.details.Id,
        email,
      })
      .then(() => {
        notificationService.send({
          message: `${LABELS.TRIP_WAS_SENT} ${email}.`,
          type: 'success',
        });

        MainAnalytic.send(MainAnalytic.CATEGORY.TRIPS, MainAnalytic.ACTIONS.TRIPS.SENDVOUCHERS, {
          label: TRIPSTATUSES.get(Status),
          value: OrderItems.length,
        });
      });
  };

  handleSendInvoiceEmail = (email: string) => {
    const { orderService, notificationService } = this.props;
    const { details } = this.state;

    let sendMessage = `${LABELS.TRIP_WAS_SENT} ${email}.`;

    orderService.sendInvoiceEmail(details.Companies[0], details.TripId, email)
    // @ts-ignore
      .then((res) => {
        if (res.Message) {
          sendMessage = res.Message;
        }

        notificationService.send({
          message: `${sendMessage}`,
          type: 'success',
        });
      })
      .finally(() => {
        this.handleSendInvoiceEmailModal();
      });
  };

  handleDownloadOriginalTrip = (id: number) =>
    this.props.reportService.downloadOriginalTrip(id);

  handleCloseServiceDialog = () =>
    this.setState({
      showServiceDialog: false,
      item: {} as OrderItem,
      modalType: null,
    });

  handleCancellationBack = () => {
    const { item } = this.state;

    MainAnalytic.send(MainAnalytic.CATEGORY.SERVICEINTRIPS, MainAnalytic.ACTIONS.SERVICEINTRIPS.CANCELLATIONCANCEL, {
      label: MainAnalytic.LABELS.SERVICEINTRIPS[item?.ActualVersion?.ServiceType as string],
    });
  };

  handleCancelServiceModal = (service: OrderItem) => {
    this.setState({
      showServiceDialog: true,
      item: service,
      modalType: DIALOG_TYPES.CANCEL,
    });

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_ANNULATION_PRESSED(
        this.state.details.Id,
        ANALYTIC_SERVICE_TYPE_MATCH[service.ActualVersion.ServiceType],
      ),
    );
  };

  handleCalculationCancelServiceModal = (service: OrderItem) => {
    const { details } = this.state;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.RETURN_COST_PRESSED, {
      id: details?.TripId,
      status: details?.Status,
      date: details?.CheckoutDate,
      name: details?.Name,
    });

    this.setState({
      showServiceDialog: true,
      item: service,
      modalType: DIALOG_TYPES.CALCULATION_CANCEL,
    });
  };

  handleEditServiceModal = (service: any) => {
    const {
      ActualVersion: { ServiceType },
    } = service;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_CHANGE_PRESSED(
        this.state.details.Id,
        ANALYTIC_SERVICE_TYPE_MATCH[ServiceType],
      ),
    );

    if (ServiceType === SERVICETYPE.TRAIN) {
      this.props.chatService.requestOnChangeByOrder(service);

      return;
    }

    this.setState({
      showServiceDialog: true,
      item: service,
      modalType: DIALOG_TYPES.EDIT,
    });
  };

  handleSubmitCancellationModal = (text: string = '') => {
    const { chatService } = this.props;
    const {
      details: { Id },
      item,
    } = this.state;

    chatService.requestCancelByOrder(item, text).then(() => {
      this.setMessageSent(item?.Id as number, MESSAGE_SENT.CANCEL);

      if (item?.ActualVersion?.ServiceType === SERVICETYPE.AIR) {
        chatService.sendTypeForAirline(item.Id);
      }

      this.handleCloseServiceDialog();
      this.fetchTripDetails(Id);
    });

    MainAnalytic.send(MainAnalytic.CATEGORY.SERVICEINTRIPS, MainAnalytic.ACTIONS.SERVICEINTRIPS.CANCELLATIONREQUEST, {
      label: MainAnalytic.LABELS.SERVICEINTRIPS[item?.ActualVersion?.ServiceType as string],
    });
  };

  handleSubmitCalculationModal = (text = '') => {
    const { chatService } = this.props;
    const {
      details: { Id },
      item,
    } = this.state;

    chatService.requestCalculationByOrder(item, text).then(() => {
      this.setMessageSent(item?.Id as number, MESSAGE_SENT.CANCEL);

      if (item?.ActualVersion?.ServiceType === SERVICETYPE.AIR) {
        chatService.sendTypeForAirline(item.Id);
      }

      this.handleCloseServiceDialog();
      this.fetchTripDetails(Id);
    });

    MainAnalytic.send(MainAnalytic.CATEGORY.SERVICEINTRIPS, MainAnalytic.ACTIONS.SERVICEINTRIPS.CANCELLATIONREQUEST, {
      label: MainAnalytic.LABELS.SERVICEINTRIPS[item?.ActualVersion?.ServiceType as string],
    });
  };

  handleShowSendDocumentModal = (type: string, tripItemId: number, employeeId: number) => {
    this.setState({
      emailInput: {
        tripItemId,
        employeeId,
        serviceId: null,
        visible: true,
        serviceType: type,
        title:
          type === SERVICETYPE.AIR
            ? LABELS.SEND_AIR
            : LABELS.SEND_APPROVING,
      },
    });
  };

  handleHideSendDocumentModal = () => {
    this.setState({
      emailInput: {
        tripItemId: null,
        employeeId: null,
        serviceId: null,
        visible: false,
        serviceType: '',
        title: '',
      },
    });
  };

  getNotificationMessage = (
    serviceType: string,
    email: string,
    isServiceForm = false,
  ) => {
    const serviceNotification =
      isServiceForm && NOTIFICATIONS.SERVICE;
    const textNotification =
      NOTIFICATIONS[serviceType] ||
      serviceNotification ||
      NOTIFICATIONS.DEFAULT;

    return `${textNotification} ${email}`;
  };

  handleSendDocument = (email: string) => {
    const { orderService, notificationService } = this.props;
    const {
      tripItemId,
      serviceType,
      serviceId,
    } = this.state.emailInput;

    const isServiceForm = serviceId !== null;
    const sendDocument = isServiceForm
      ? () =>
        orderService.sendServiceForm({
          tripItemId: tripItemId as number,
          serviceId,
          email,
        })
      : () => orderService.sendDocument({ tripItemId: tripItemId as number, email });

    sendDocument().then(() => {
      notificationService.send({
        message: this.getNotificationMessage(
          serviceType,
          email,
          isServiceForm,
        ),
        type: 'success',
      });

      MainAnalytic.send(MainAnalytic.CATEGORY.SERVICEINTRIPS, MainAnalytic.ACTIONS.SERVICEINTRIPS.SENDVOUCHERS, {
        label: TRIPSTATUSES.get(this.state.details.Status),
      });
    });

    this.handleHideSendDocumentModal();
  };

  downloadVoucherLocale = (tripItemId: number, locale: UiLanguageType) =>
    this.props.reportService.downloadVoucherLocale(
      tripItemId,
      locale,
    );

  handlePrintDocument = (tripItemId: number, employeeId: number, type: string) => {
    if (type === SERVICETYPE.FLIGHT_CERTIFICATE) {
      return this.props.orderService.downloadFlightCertificate(
        employeeId,
      );
    }

    MainAnalytic.send(MainAnalytic.CATEGORY.SERVICEINTRIPS, MainAnalytic.ACTIONS.SERVICEINTRIPS.DOWNLOADVOUCHERS, {
      label: TRIPSTATUSES.get(this.state.details.Status),
    });

    return this.props.reportService.downloadVoucher(
      tripItemId,
    );
  };

  handleRequestAutomaticCancellation = () =>
    MainAnalytic.send(MainAnalytic.CATEGORY.SERVICEINTRIPS, MainAnalytic.ACTIONS.SERVICEINTRIPS
      .REQUESTAUTOMATICCANCELLATION);

  handleAutomaticCancellation = () =>
    MainAnalytic.send(MainAnalytic.CATEGORY.SERVICEINTRIPS, MainAnalytic.ACTIONS.SERVICEINTRIPS
      .CONFIRMATIONAUTOMATICCANCELLATION);

  handleCancelAutomaticCancellation = () =>
    MainAnalytic.send(MainAnalytic.CATEGORY.SERVICEINTRIPS, MainAnalytic.ACTIONS.SERVICEINTRIPS.CANCELAUTOMATICCANCELLATION);

  handleViewEditHistory = () =>
    MainAnalytic.send(MainAnalytic.CATEGORY.SERVICEINTRIPS, MainAnalytic.ACTIONS.SERVICEINTRIPS.VIEWEDITHISTORY, {
      label: TRIPSTATUSES.get(this.state.details.Status),
    });

  handleGoBack = () => {
    const { goBack } = this.props.history;

    return goBack();
  };

  handleDownloadCalendar = () =>
    this.props.orderService.downloadCalendar();

  setMessageSent = (id: number, field: string) =>
    this.setState({
      messageSent: {
        ...this.state.messageSent,
        [id]: {
          ...(() => this.state.messageSent[id] || {})(),
          [field]: true,
        },
      },
    });

  handleEditTripItem = (
    result: any,
    item: any,
    text: string = '',
    serviceType: string = '',
    selectedEmployes: IVoucherTravellersWithId[] = [],
  ) => {
    const { chatService } = this.props;
    const {
      details: { Id },
    } = this.state;

    const { match: { params: { tripId } } } = this.props;

    if (result && item.length && text && serviceType === SERVICETYPE.HOTEL) {
      return chatService.requestOnChangeByHotelOrders(item, text, getAccountId(), tripId).then(() => {
        this.setMessageSent(item[0].Id, MESSAGE_SENT.EDIT);
        this.handleCloseServiceDialog();
        this.fetchTripDetails(Id);
      });
    }

    if (result && serviceType === SERVICETYPE.INSURANCE) {
      return chatService.requestForInsuranceCompensation(item, text, selectedEmployes).then(() => {
        this.setMessageSent(item.Id, MESSAGE_SENT.REFUND);
        this.handleCloseServiceDialog();
        this.fetchTripDetails(Id);
      });
    }

    if (result && item && text) {
      chatService.requestOnChangeByOrder(item, text).then(() => {
        this.setMessageSent(item.Id, MESSAGE_SENT.EDIT);
        this.handleCloseServiceDialog();

        if (item.ActualVersion.ServiceType === SERVICETYPE.AIR) {
          chatService.sendTypeForAirline(item.Id);
        }

        this.fetchTripDetails(Id);
      });
    }

    return null;
  };

  handleOpenApproveDialog = (TripItemId = null, RequestId = null) => {
    this.setState({
      showApproveDialog: !this.state.showApproveDialog,
      tripRequest: {
        TripItemId,
        RequestId,
      },
    });
  };

  handleAddTripRequest = async () => {
    const { tripRequest: { TripItemId, RequestId } } = this.state;

    await this.props.requestsService.setTripsRequests(TripItemId, RequestId);
    await this.fetchTripDetails(this.state.details.Id);

    this.handleOpenApproveDialog();
  };

  getTicketsWithTheSamePNR = () => {
    const {
      item,
      details: { OrderItems = [] },
    } = this.state;

    const { ActualVersion: { JsonData, ServiceType } } = item as OrderItem;

    if (ServiceType !== SERVICETYPE.AIR) {
      return [];
    }

    const data = parseJSON(JsonData);

    if (!data) {
      return [];
    }

    const { TicketsExtended = [{ PNR: null }] } = data;
    const { PNR } = TicketsExtended[0];

    if (!PNR) {
      return [];
    }

    return OrderItems.filter(({ ActualVersion }) => {
      if (ActualVersion.ServiceType !== SERVICETYPE.AIR) {
        return false;
      }

      const itemData = parseJSON(ActualVersion.JsonData);

      if (!itemData) {
        return false;
      }

      const {
        TicketsExtended: ticketsExtended = [{ PNR: null }],
      } = itemData;
      const { PNR: pnr } = ticketsExtended[0];

      return pnr === PNR;
    });
  };

  setCustomAnalytics = async (
    tripId: number,
    analyticsValueId: number,
    cartItemId: number | null = null,
  ) => {
    const { orderService } = this.props;

    if (!cartItemId) {
      await orderService.setAnalytics(tripId, analyticsValueId);
      await this.loadOrderServiceInfo(false);

      return;
    }

    await orderService.setAnalyticsForItem(
      tripId,
      cartItemId,
      analyticsValueId,
    );
    await this.loadOrderServiceInfo(false);
  };

  unsetCustomAnalytics = async (
    tripId: number,
    analyticsId: number,
    cartItemId: number | null = null,
  ) => {
    const { orderService } = this.props;

    if (!cartItemId) {
      await orderService.unsetAnalytics(tripId, analyticsId);
      await this.loadOrderServiceInfo(false);

      return;
    }

    await orderService.unsetAnalyticsForItem(
      tripId,
      cartItemId,
      analyticsId,
    );
    await this.loadOrderServiceInfo(false);
  };

  handleAddAnalyticsValue = async (
    analyticsId: number,
    value: string,
    tripId: number | null = null,
    tripItemId: number | null = null,
  ) => {
    const { customAnalyticsService } = this.props;

    const analyticsValue = await customAnalyticsService.addAnalyticsValue(
      analyticsId,
      value,
    );

    if (tripId && analyticsValue?.Id) {
      await customAnalyticsService.getAnalytics(true);
      await this.setCustomAnalytics(
        tripId,
        analyticsValue.Id,
        tripItemId,
      );
    }
  };

  handleAddComment = (comments: string) => {
    const {
      tripCommentsService,
      workspaceService,
    } = this.props;
    const { Email } = workspaceService.get();

    const tripId = this.state.details?.TripId || null;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_CHANGE_CONFIRM_PRESSED(Number(tripId), ANALYTIC_SERVICE_TYPES.AIR),
    );

    return tripCommentsService.addComment(tripId, Email, comments);
  };

  renderByGroups = (itemsByGroups: ITripItemsByGroups, itemHtml: (item: OrderItem) => ReactElement) => Object.keys(itemsByGroups)
    .map(key => {
      if (key === TAXI_GROUP && itemsByGroups[key as keyof typeof itemsByGroups].length > 1) {
        return (
          <StyledWrapper key={ key } className={ styles.group_items }>
            {itemsByGroups[key as keyof typeof itemsByGroups].map(itemHtml)}
          </StyledWrapper>
        );
      }

      return itemsByGroups[key as keyof typeof itemsByGroups].map(itemHtml);
    });

  renderActiveItems = (items: OrderItem[]) => {
    const {
      details,
      projects,
      messageSent,
      allFlightCertificateStatus,
    } = this.state;
    const {
      chatService,
      tripTagsService,
      workspaceService,
      orderService,
      departmentsService: {
        store: {
          list: { value },
        },
      },
      hotelsService,
      favoritesService,
      notificationService,
      accountSettingsService,
      requestsService,
      customAnalyticsService: {
        store: { sortedCustomAnalytics },
      },
      stores: {
        settingsStore: {
          prohibitionVouchers,
        },
      },
      popupsService,
      aggregationId,
    } = this.props;

    const { Email } = workspaceService.get();
    const hasDisabledUser =
      chatService.hasDisabledUser(Email) ||
      !workspaceService.canUseIntercom;
    const serviceAnalytics = sortedCustomAnalytics.filter(
      ({ ApplyToTrip }) => !ApplyToTrip,
    );
    const tripId = details?.TripId || null;
    const { tripsRequests } = requestsService.get();

    const itemHtml = (item: OrderItem) => (
      <TripItem
        key={ item.Id }
        tripId={ tripId as number }
          // @ts-ignore
        item={ item } // TODO ТУТ 2 интерфейса пересеклись =((
        aggregationId={ aggregationId }
        messageSent={ messageSent[item.Id] || {} }
          // @ts-ignore
        companies={ value } // TODO ТУТ 2 интерфейса пересеклись =((
        projects={ projects }
        status={ details.Status }
        tripName={ details.Name }
        prohibitionVouchers={ prohibitionVouchers }
        onSendDocument={ this.handleShowSendDocumentModal }
        onPrintDocument={ this.handlePrintDocument }
        onCancelService={ () => this.handleCancelServiceModal(item) }
        onCalculationCancelService={ () => this.handleCalculationCancelServiceModal(item) }
        onEditService={ () => this.handleEditServiceModal(item) }
        hasDisabledUser={ hasDisabledUser }
        onViewEditHistory={ this.handleViewEditHistory }
        tripTagsService={ tripTagsService }
        workspaceService={ workspaceService }
        orderService={ orderService }
        notificationService={ notificationService }
        hotelsService={ hotelsService }
        favoritesService={ favoritesService }
        accountSettingsService={ accountSettingsService }
        popupsService={ popupsService }
        tripsRequests={ tripsRequests }
        onDownloadVoucherLocale={ this.downloadVoucherLocale }
        flightCertificateStatus={
          allFlightCertificateStatus[
            getAirTripItemId(item) || item.Id
          ]
        }
        airTicketHasCertificate={ isAirTicketHasCertificate(
          items,
          item.Id,
        ) }
        customAnalytics={ serviceAnalytics }
        setCustomAnalytics={ this.setCustomAnalytics }
        unsetCustomAnalytics={ this.unsetCustomAnalytics }
        onAddAnalyticsValue={ this.handleAddAnalyticsValue }
        onOpenApproveDialog={ this.handleOpenApproveDialog }
        vipHallRef={ this.vipHallRef }
        qaAttr={ QA_ATTRIBUTES.trips.trip.item }
      />
    );

    const itemsByGroups: ITripItemsByGroups = getTripItemsByGroups(items);

    return (
      <div className={ styles.active_items }>
        {this.renderByGroups(itemsByGroups, itemHtml)}
      </div>
    );
  };

  renderCancelledItems = (items: OrderItem[]) => {
    const { details, projects } = this.state;

    const {
      tripTagsService,
      workspaceService,
      orderService,
      departmentsService: {
        store: {
          list: { value },
        },
      },
      hotelsService,
      notificationService,
      favoritesService,
      accountSettingsService,
      customAnalyticsService: {
        store: { sortedCustomAnalytics },
      },
      stores: {
        settingsStore: {
          prohibitionVouchers,
        },
      },
      popupsService,
      aggregationId,
    } = this.props;

    const serviceAnalytics = sortedCustomAnalytics.filter(
      ({ ApplyToTrip }) => !ApplyToTrip,
    );
    const tripId = details?.TripId || null;

    const itemHtml = (item: OrderItem) => (
      <TripItem
        key={ item.Id }
        tripId={ tripId as number }
        aggregationId={ aggregationId }
        messageSent={ { } }
          // @ts-ignore
        item={ item }
        companies={ value }
        projects={ projects }
        status={ details.Status }
        tripName={ details.Name }
        prohibitionVouchers={ prohibitionVouchers }
        tripTagsService={ tripTagsService }
        workspaceService={ workspaceService }
        orderService={ orderService }
        notificationService={ notificationService }
        hotelsService={ hotelsService }
        favoritesService={ favoritesService }
        accountSettingsService={ accountSettingsService }
        popupsService={ popupsService }
        airTicketHasCertificate={ isAirTicketHasCertificate(items, item.Id) }
        customAnalytics={ serviceAnalytics }
        setCustomAnalytics={ this.setCustomAnalytics }
        unsetCustomAnalytics={ this.unsetCustomAnalytics }
        onAddAnalyticsValue={ this.handleAddAnalyticsValue }
        onOpenApproveDialog={ this.handleOpenApproveDialog }
        onCancelService={ () => this.handleCancelServiceModal(item) }
        onCalculationCancelService={ () => this.handleCalculationCancelServiceModal(item) }
        onDownloadVoucherLocale={ this.downloadVoucherLocale }
      />
    );

    const itemsByGroups = getTripItemsByGroups(items);

    return (
      <div>
        <Text type='bold_24' className={ styles.title }>
          {LABELS.CANCELLED_TRIPS}
        </Text>
        {this.renderByGroups(itemsByGroups, itemHtml)}
      </div>
    );
  };

  renderItems = () => {
    const {
      details: { OrderItems },
    } = this.state;

    const mappedOrderItems = OrderItems.map((oi) =>
      (oi.ActualVersion.ServiceType === SERVICETYPE.AIR
        ? {
          ...oi,
          ActualVersion: {
            ...oi.ActualVersion,
            JsonData: mapOperatingAirlineIntoAirline(
              oi.ActualVersion.JsonData,
            ),
          },
        }
        : oi),
    ).sort(compareByAirCertificate);

    const {
      activeItems = [],
      cancelledItems = [],
    } = getTripItems(mappedOrderItems);

    const activeItemsHtml =
      activeItems.length > 0 && this.renderActiveItems(activeItems);

    const cancelledItemsHtml =
      cancelledItems.length > 0 &&
      this.renderCancelledItems(cancelledItems);

    return (
      <div className={ styles.items }>
        { activeItemsHtml }
        { cancelledItemsHtml }
      </div>
    );
  };

  renderCustomAnalytics = () => {
    const {
      details: { UserAnalytics = [], TripId = null },
    } = this.state;
    const {
      customAnalyticsService: {
        store: { sortedCustomAnalytics },
      },
    } = this.props;

    const tripAnalytics = sortedCustomAnalytics.filter(
      ({ ApplyToTrip }) => ApplyToTrip,
    );

    return (
      <AnalyticsBar
        withRequiredValidation
        tripId={ TripId }
        onSet={ this.setCustomAnalytics }
        onUnset={ this.unsetCustomAnalytics }
        userAnalytics={ UserAnalytics }
        analyticsList={ tripAnalytics }
        onAddAnalyticsValue={ (aId, value) => this.handleAddAnalyticsValue(aId, value, TripId) }
      />
    );
  };
  handleSubmitModal = (
    result?: any,
    item?: any,
    text?: string,
    serviceType?: string,
    selectedEmployes?: IVoucherTravellersWithId[],
  ) => {
    const { modalType } = this.state;

    switch (modalType) {
      case DIALOG_TYPES.EDIT:
        return this.handleEditTripItem(result, item, text, serviceType, selectedEmployes);
      case DIALOG_TYPES.CANCEL:
        return this.handleSubmitCancellationModal(result);
      case DIALOG_TYPES.CALCULATION_CANCEL:
        return this.handleSubmitCalculationModal(result);
      default:
        return this.handleSubmitCancellationModal(result);
    }
  };

  renderServiceDialog = () => {
    const {
      chatService,
      orderService,
      workspaceService,
    } = this.props;
    const {
      details,
      details: {
        OrderItems,
      },
      item,
      isChatLoading,
      modalType,
    } = this.state;

    const { ActualVersion } = item as OrderItem;
    const { ServiceType, JsonData } = ActualVersion;

    const isTrain = ServiceType === SERVICETYPE.TRAIN;
    const isAeroexpress = ServiceType === SERVICETYPE.AEROEXPRESS;
    const isHotel = ServiceType === SERVICETYPE.HOTEL;
    const isAir = ServiceType === SERVICETYPE.AIR;
    const isTaxiVoucher = ServiceType === SERVICETYPE.TAXI_VOUCHER;
    const isVipHall = ServiceType === SERVICETYPE.VIP_HALL;
    const isTransfer = ServiceType === SERVICETYPE.TRANSFER;
    const ticketsWithSamePNR = this.getTicketsWithTheSamePNR();
    // @ts-ignore
    const additionalServiceTrip: IAdditionalService | null = combinedTripAndAdditionalServices(item, OrderItems);

    const name =
      isAir && modalType === DIALOG_TYPES.CANCEL
        ? ticketsWithSamePNR
          .map(({ ActualVersion: av }) =>
            prepareTripDialogTitle(av),
          )
          .join('\n')
        : prepareTripDialogTitle(ActualVersion);

    const voidTimeLimit = isAir ? getVoidTimeLimit(JsonData) : null;

    return (
      <TripDetailsDialog
        orderItems={ OrderItems }
        multipleTickets={ ticketsWithSamePNR.length > 1 }
        ticketsWithSamePNR={ ticketsWithSamePNR }
        nameTrip={ name }
          // @ts-ignore
        item={ item }
        additionalServiceTrip={ additionalServiceTrip }
        isTrain={ isTrain }
        isAeroexpress={ isAeroexpress }
        isHotel={ isHotel }
        isAir={ isAir }
        isTaxiVoucher={ isTaxiVoucher }
        isTransfer={ isTransfer }
        isVipHall={ isVipHall }
        isChatLoading={ isChatLoading }
        voidTimeLimit={ voidTimeLimit }
        modalType={ modalType || '' }
        chatService={ chatService }
        workspaceService={ workspaceService }
        orderService={ orderService }
        onRefreshTrain={ () => this.fetchTripDetails(details.Id) }
        onCloseDialog={ this.handleCloseServiceDialog }
        onSubmitCancellationModal={ this.handleSubmitModal }
        onCancellationBack={ this.handleCancellationBack }
        onAutomaticCancellation={ this.handleAutomaticCancellation }
        onCancelAutomaticCancellation={ this.handleCancelAutomaticCancellation }
        onRequestAutomaticCancellation={ this.handleRequestAutomaticCancellation }
      />
    );
  };

  renderAddingTrip = () => {
    const { details: { Status } } = this.state;

    const wrpperClasses = [styles.suggest_trip_wrapper];

    if (Status !== TRIPSTATUS.BOOKED) return null;

    return (
      <div
        ref={ this.addingOrderRef }
        className={ wrpperClasses.join(' ') }
        onClick={ () => this.handleShowAddingTrip(true) }
        data-qa={ QA_ATTRIBUTES.trips.trip.addToTrip }
      >
        <Icon type='plusRound' />
        <Text color='accent' type='NORMAL_14'>
          {LABELS.ADD_TO_TRIP}
        </Text>
      </div>
    );
  };

  renderInsurance = () => {
    const {
      workspaceService: {
        canBuyInsurance,
        isAdmin,
      },
      uiSettingsProtocol: {
        isWhiteLabel,
      },
      featureFlagsService,
      insuranceService,
    } = this.props;
    const { details } = this.state;

    const dates = [details.CheckinDate, details.CheckoutDate] as IDates;
    const { TripId, InsuranceStatus, OrderItems } = details;

    const displayInsurance = featureFlagsService.getDisplayInsurance();
    const isAllowToBuyInsurance = (canBuyInsurance || isAdmin) && displayInsurance;
    const hasInvalidInsurance = InsuranceStatus === INSURANCE_STATUS.INSURED;

    const isServiceNotRefundable = insuranceService.checkIsServicesNotRefundable(OrderItems);

    if (!isAllowToBuyInsurance || hasInvalidInsurance || isWhiteLabel()) return null;

    return (
      <div className={ styles.insurance }>
        <Insurance
          insuranceService={ insuranceService }
          isCart={ false }
          isServiceNotRefundable={ isServiceNotRefundable }
          orderDate={ dates }
          tripId={ TripId }
        />
      </div>
    );
  };

  render() {
    const {
      loading,
      details,
      showServiceDialog,
      isShowSendTripModal,
      isShowSendInvoiceEmailModal,
      isShowPaymentInvoiceModal,
      showApproveDialog,
      paymentInvoiceMessage,
      emailInput: { title, visible },
      bookflightCertificateInfo: { flightCertificateLoading },
      CompanyFunds,
      rights,
      tripPlan,
      showPlanTrip,
      itemsForddedTrip,
      showAddingTripDialog,
      isContur,
    } = this.state;

    const {
      match: { params: { tripId } },
      customAnalyticsService: {
        store: { loading: analyticsLoading },
      },
      orderService,
      companyNameSettings,
      popupsService: {
        store: {
          popupsState,
          showTripPlanPopup,
        },
      },
      // @ts-ignore
      stores: { eventStore: { event } }, // TODO event нет в сторе!!!!
      accountSettingsService,
      featureFlagsService,
      uiSettingsProtocol,
      aggregationId,
      insuranceService,
    } = this.props;

    const handleOnAddInTrip = (service: string) => {
      MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.TRIPS.TRIP_DETAIL_ADD_TRIP_PRESSED(ANALYTIC_SERVICE_TYPE_MATCH_SEARCH[service]),
      );
    };

    if (
      loading ||
      flightCertificateLoading ||
      analyticsLoading ||
      !details
    ) {
      return <PageLoader text={ LABELS.PAGE_LOADER } />;
    }

    const serviceDialogHtml = showServiceDialog && this.renderServiceDialog();

    const emailInputDialogHtml = visible && (
      <InputModal
        title={ title }
        show={ visible }
        label={ LABELS.EMAIL }
        type={ FIELDS.EMAIL }
        onSend={ this.handleSendDocument }
        onCancel={ this.handleHideSendDocumentModal }
      />
    );

    const renderItems = () => {
      const { details: { CheckinDate, CheckoutDate, TripId, Name } } = this.state;

      const date = formatRangeDateWithSimplicity(CheckinDate, CheckoutDate);
      const searchUrl = `?tripId=${TripId}&tripName=${Name}&tripDate=${date}`;

      return (itemsForddedTrip.map((itemFordedTrip, index) => (
        <div
          key={ index }
          data-qa={ QA_ATTRIBUTES.trips.trip.addToTripDialog[
            itemFordedTrip.type as keyof typeof QA_ATTRIBUTES.trips.trip.addToTripDialog
          ]
        }
        >
          <AddingTripItem
            searchUrl={ searchUrl }
            title={ itemFordedTrip.title as string }
            url={ itemFordedTrip.url }
            type={ ICON_TYPES_MAP[itemFordedTrip.type] }
            serviceType={ itemFordedTrip.type }
            onClick={ handleOnAddInTrip }
          />
        </div>
      ))
      );
    };

    const addingTripDialogHtml = showAddingTripDialog ? (
      <Dialog
        show={ showAddingTripDialog }
        showClosing
        onChange={ this.handleShowAddingTrip }
      >
        <div data-qa={ QA_ATTRIBUTES.trips.trip.addToTripDialog.wrapper }>
          <Text type='bold_20'>{LABELS.ADD_TO_TRIP_TITLE}</Text>
          <div className={ styles.adding_list }>
            {renderItems()}
          </div>
        </div>
      </Dialog>
    ) : null;

    const planTripDialogHtml = () => showPlanTrip && (
      <SidePanel
        show={ showPlanTrip }
        alternativeDesign={ isSmartAgent }
        onClose={ () => this.handleShowPlanTrip(false) }
      >
        <TripPlan
          orderService={ orderService }
          tripPlan={ tripPlan }
          tripId={ tripId }
          locale={ this.state.locale }
        />
      </SidePanel>
    );

    const sendTripDialogHtml = isShowSendTripModal && (
      <InputModal
        title={ LABELS.SEND_TRIPS }
        show={ isShowSendTripModal }
        label={ LABELS.EMAIL }
        type={ FIELDS.EMAIL }
        onSend={ this.handleSendTrip }
        onCancel={ this.handleHideSendTripModal }
      />
    );

    const sendInvoiceEmailDialogHtml = isShowSendInvoiceEmailModal && (
      <InputModal
        title={ LABELS.SEND_EMAIL_INVOICE }
        show={ isShowSendInvoiceEmailModal }
        label={ LABELS.EMAIL }
        type={ FIELDS.EMAIL }
        onSend={ this.handleSendInvoiceEmail }
        onCancel={ this.handleSendInvoiceEmailModal }
      />
    );

    const paymentInvoiceDialogHtml = (
      <Dialog show={ isShowPaymentInvoiceModal } onChange={ this.onClosePaymentInvoice }>
        <div className={ styles.wrapper_dialog }>
          <Text type='NORMAL_16_140'>{paymentInvoiceMessage}</Text>
          <div className={ styles.actions }>
            <Button onClick={ this.onClosePaymentInvoice } className={ styles.action }>
              {LABELS.TO_CLOSE}
            </Button>
          </div>
        </div>
      </Dialog>
    );

    const approveRequestDialogHtml = () => {
      const { loadingTripRequest, tripRequest: { RequestId } } = this.state;

      if (!RequestId) {
        return null;
      }

      return showApproveDialog && (
        <ApproveActionDialog
          show={ showApproveDialog }
          RequestId={ RequestId }
          onClose={ this.handleOpenApproveDialog }
          onSave={ this.handleAddTripRequest }
          loading={ loadingTripRequest }
        />
      );
    };

    const statusTripForTripPlanPopup = details?.Status === TRIPSTATUS.COMPLETED || details?.Status === TRIPSTATUS.BOOKED;
    const showTripPlanOverlay = showTripPlanPopup && !popupsState.trip_plan_popup && statusTripForTripPlanPopup && !isSmartAgent && !isContur;
    const overlayHtml = showTripPlanOverlay && (<div className={ styles.overlay } />);

    const tripPlanDisabled = (details?.Status !== TRIPSTATUS.BOOKED && details?.Status !== TRIPSTATUS.COMPLETED) ||
      (details?.OrderItems?.length === 1 && (details?.OrderItems[0]?.ActualVersion?.ServiceType === SERVICETYPE.CUSTOM)) ||
      (details?.OrderItems?.every(({ ActualVersion }) => ActualVersion?.ServiceType === SERVICETYPE.EVENT));

    const highlightTripButton = showTripPlanOverlay ? (
      <div className={ styles.highlight_trip }>
        <Button
          type='primary'
          onClick={ () => this.handleShowPlanTrip(true) }
          ref={ this.tripPlanRef }
          className={ styles.button_trip_plan }
        >
          { LABELS.TRIP_PLAN }
        </Button>
      </div>
    ) : (
      <div>
        <Button
          type='primary'
          onClick={ () => this.handleShowPlanTrip(true) }
          ref={ this.tripPlanRef }
          disabled={ tripPlanDisabled }
          className={ styles.button_trip_plan }
        >
          { LABELS.TRIP_PLAN }
        </Button>
      </div>
    );

    const tripPlanButton = !isSmartAgent && !isContur ? highlightTripButton : null;

    return (
      <div className={ styles.wrapper }>
        <BackLink
          text={ LABELS.BACK }
          className={ styles.back }
          alternativeDesign={ isSmartAgent }
          onClick={ this.handleGoBack }
        />
        <TripHeader
          details={ details }
          rights={ rights }
          onTripRename={ this.handleTripRename }
          orderService={ orderService }
          onDownloadOriginalTrip={ this.handleDownloadOriginalTrip }
          onAddComment={ this.handleAddComment }
          onShowPaymentInvoice={ this.onShowPaymentInvoice }
          onShowPaymentEmailInvoice={ this.onShowPaymentEmailInvoice }
          companyNameSettings={ companyNameSettings }
          CompanyFunds={ CompanyFunds }
          tripPlan={ tripPlanButton }
          event={ event }
          aggregationId={ aggregationId }
          accountSettingsService={ accountSettingsService }
          isDisplayInsurance={ featureFlagsService.getDisplayInsurance() }
          isWhiteLabel={ uiSettingsProtocol.isWhiteLabel() }
        />
        <InsuranceSidePanel
          insuranceService={ insuranceService }
        />
        { this.renderAddingTrip() }
        { this.renderInsurance() }
        { this.renderCustomAnalytics() }
        { this.renderItems() }
        { serviceDialogHtml }
        { sendTripDialogHtml }
        { paymentInvoiceDialogHtml }
        { sendInvoiceEmailDialogHtml }
        { emailInputDialogHtml }
        { planTripDialogHtml() }
        { addingTripDialogHtml }
        { approveRequestDialogHtml() }
        { overlayHtml }
      </div>
    );
  }
}

export default TripDetails;
