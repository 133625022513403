import React, { useState } from 'react';
import { Button, Text, RadioButton, Input, Dialog } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { isSmartAgent } from '../../../../../bi/utils/env';
import MoneyFormat from '../../../../../bi/utils/money';
import { additionalFeeValidation } from '../../../../../bi/utils/additionalFee';

import { PRICE_FLOAT_REG_EXP, PRICE_FLOAT_REG_EXP_NO_OGR } from '../../../../../bi/constants/regExp';

import { IFeeConstruction } from '../../../../../bi/services/settings/types';

import styles from '../styles/index.module.css';

const LABELS = {
  TYPE_FEE: getText('settings:feeConstructor:typeFee'),
  COLLECTION_VALUE: getText('settings:feeConstructor:collectionValue'),
  APPLICATION_FEE: getText('settings:feeConstructor:applicationFee'),
  PER_SEGMENT: getText('settings:feeConstructor:perSegment'),
  TO_ORDER: getText('settings:feeConstructor:toOrder'),
  IN_RUB: getText('settings:feeConstructor:inRub'),
  IN_PERCENT: getText('settings:feeConstructor:inPercent'),
  SAVE: getText('common:save'),
  CANCEL: getText('common:undo'),
  EDIT: getText('common:edit'),
  MODAL: {
    TITLE: getText('settings:feeConstructor:modal:title'),
    DELETE: getText('settings:feeConstructor:modal:done'),
    CANCEL: getText('settings:feeConstructor:modal:cancel'),
  },
  EDIT_FEE: getText('settings:feeConstructor:edit'),
  CANCEL_FEE: getText('settings:feeConstructor:delete'),
};

const VALIDATION = {
  MAST_BE_NUMBER: getText('settings:feeConstructor:valid.mustBeNumber'),
  REQUEST_FIELD: getText('settings:feeConstructor:valid.field'),
};

const TypeFeeRadioValues = {
  RUB: 'Currency',
  PERCENT: 'Percentages',
};

const ApplicationFeeRadioValues = {
  SEGMENT: 'Segment',
  ORDER: 'Order',
};

interface ConstructionFormType {
  title: string,
  typeFee: string,
  serviceType: string,
  applicationFee: string | null,
  valueFee: string | number,
  addApplication?: boolean,
  onSave(data: IFeeConstruction, isChangeForm: boolean): void,
}

const ConstructionForm = ({
  title,
  typeFee,
  valueFee,
  applicationFee,
  serviceType,
  addApplication,
  onSave,
}: ConstructionFormType) => {
  const [typeFeeRadioField, setSwitchTypeFeeRadioField] = useState(typeFee || TypeFeeRadioValues.RUB);
  const [applicationFeeRadioField, setSwitchApplicationFeeRadioField] = useState(applicationFee || ApplicationFeeRadioValues.SEGMENT);
  const [valueFeeField, setValueFee] = useState(valueFee || '');
  const [inputError, setInputError] = useState('');
  const [isChangeForm, setChangeForm] = useState(!!valueFee);
  const [isShowModal, setIsShowModal] = useState(false);

  const getTypeFee = (type: string) => ({
    [TypeFeeRadioValues.RUB]: LABELS.IN_RUB,
    [TypeFeeRadioValues.PERCENT]: LABELS.IN_PERCENT,
  })[type];

  const getApplicationFee = (type: string) => ({
    [ApplicationFeeRadioValues.SEGMENT]: LABELS.PER_SEGMENT,
    [ApplicationFeeRadioValues.ORDER]: LABELS.TO_ORDER,
  })[type];

  const getPriceFee = (type: string, value: string | number) => ({
    [TypeFeeRadioValues.RUB]: MoneyFormat.money(Number(value), true),
    [TypeFeeRadioValues.PERCENT]: `${value} %`,
  }[type]);

  const disabledRoubles = (value: string) => !additionalFeeValidation(value, PRICE_FLOAT_REG_EXP, PRICE_FLOAT_REG_EXP_NO_OGR, false)
    && typeFeeRadioField === TypeFeeRadioValues.RUB;
  const disabledPercent = (value: string) => (!Number(value) || Number(value) <= 0) && typeFeeRadioField === TypeFeeRadioValues.PERCENT;

  const handleChangeValueFee = (value: string) => {
    setValueFee(value);

    if (disabledRoubles(value)) {
      return setInputError(VALIDATION.MAST_BE_NUMBER);
    }

    if (disabledPercent(value)) {
      return setInputError(VALIDATION.MAST_BE_NUMBER);
    }

    return setInputError('');
  };

  const disabledSave = (value: string) => disabledRoubles(value) || disabledPercent(value);

  const handleSwitchSegment = () => {
    setSwitchApplicationFeeRadioField(ApplicationFeeRadioValues.SEGMENT);
    setSwitchTypeFeeRadioField(TypeFeeRadioValues.RUB);
  };

  const handleSave = () => {
    const prepareFeeNote = addApplication ? applicationFeeRadioField : null;

    const preparedData = {
      ServiceType: serviceType,
      Value: valueFeeField,
      PricingType: typeFeeRadioField,
      FeeNote: prepareFeeNote || ApplicationFeeRadioValues.ORDER,
    };

    setChangeForm(!isChangeForm);
    onSave(preparedData, !!valueFee);
  };

  const onCancelEditForm = () => {
    setChangeForm(!isChangeForm);

    setValueFee(valueFee || '');
  };

  const renderApplicationFee = addApplication ? (
    <div className={ styles.fee_application }>
      <Text type='NORMAL_14' className={ styles.header_row }>
        {LABELS.APPLICATION_FEE}
      </Text>
      <RadioButton
        className={ styles.radio }
        value={ ApplicationFeeRadioValues.SEGMENT }
        checked={ applicationFeeRadioField === ApplicationFeeRadioValues.SEGMENT }
        alternativeDesign={ isSmartAgent }
        onChange={ handleSwitchSegment }
      >
        <Text type='NORMAL_14'> {LABELS.PER_SEGMENT} </Text>
      </RadioButton>
      <RadioButton
        value={ ApplicationFeeRadioValues.ORDER }
        checked={ applicationFeeRadioField === ApplicationFeeRadioValues.ORDER }
        alternativeDesign={ isSmartAgent }
        onChange={ () => setSwitchApplicationFeeRadioField(ApplicationFeeRadioValues.ORDER) }
      >
        <Text type='NORMAL_14'> {LABELS.TO_ORDER} </Text>
      </RadioButton>
    </div>
  ) : null;

  const disabledRules = addApplication && applicationFeeRadioField === ApplicationFeeRadioValues.SEGMENT;
  const radioClassName = disabledRules ? styles.disabledRadio : '';

  const renderEditForm = (
    <>
      <div className={ styles.title }>
        <Text type='SEMIBOLD_18'>
          {title}
        </Text>
      </div>
      { renderApplicationFee }
      <div className={ styles.fee_type }>
        <Text type='NORMAL_14' className={ styles.header_fee }>
          { LABELS.TYPE_FEE }
        </Text>
        <RadioButton
          className={ styles.radio }
          value={ TypeFeeRadioValues.RUB }
          checked={ typeFeeRadioField === TypeFeeRadioValues.RUB }
          alternativeDesign={ isSmartAgent }
          onChange={ () => setSwitchTypeFeeRadioField(TypeFeeRadioValues.RUB) }
        >
          <Text type='NORMAL_14'> { LABELS.IN_RUB } </Text>
        </RadioButton>
        <RadioButton
          className={ radioClassName }
          value={ TypeFeeRadioValues.PERCENT }
          checked={ typeFeeRadioField === TypeFeeRadioValues.PERCENT }
          alternativeDesign={ isSmartAgent }
          onChange={ () => setSwitchTypeFeeRadioField(TypeFeeRadioValues.PERCENT) }
          disabled={ disabledRules }
        >
          <Text type='NORMAL_14'> { LABELS.IN_PERCENT } </Text>
        </RadioButton>
      </div>
      <div className={ styles.collection_value }>
        <Text type='NORMAL_14' className={ styles.header_value }>
          { LABELS.COLLECTION_VALUE }
        </Text>
        <Input
          value={ valueFeeField }
          onChange={ handleChangeValueFee }
          error={ inputError }
          className={ styles.fee_input }
        />
      </div>
      <div className={ styles.buttons }>
        <Button
          type='primary'
          className={ styles.save }
          onClick={ handleSave }
          disabled={ disabledSave(valueFeeField as string) }
        >
          { LABELS.SAVE }
        </Button>
        <Button
          type='textual'
          className={ styles.button_cancel }
          onClick={ () => onCancelEditForm() }
        >
          { LABELS.CANCEL }
        </Button>
      </div>
    </>
  );

  const renderDoneForm = () => {
    const renderApplicationValue = applicationFee ? (
      <div>
        <Text type='SEMIBOLD_14'>
          { getApplicationFee(applicationFee) }
        </Text>
      </div>
    ) : '';

    const renderFee = typeFee ? (
      <Text type='SEMIBOLD_14' className={ styles.btn } > { getTypeFee(typeFee) } </Text>
    ) : '';

    const applicationHtml = addApplication ? (
      <div className={ styles.done }>
        <div>
          <Text type='NORMAL_14' className={ styles.header_row }>
            { LABELS.APPLICATION_FEE }
          </Text>
        </div>
        { renderApplicationValue }
      </div>
    ) : null;

    const renderDeleteModal = () => {
      if (!isShowModal) {
        return null;
      }

      const handleDelete = () => {
        const prepareFeeNote = addApplication ? applicationFeeRadioField : null;

        const preparedData = {
          ServiceType: serviceType,
          Value: '0',
          PricingType: typeFeeRadioField,
          FeeNote: prepareFeeNote || ApplicationFeeRadioValues.ORDER,
        };

        setChangeForm(!isChangeForm);
        onSave(preparedData, !!valueFee);
      };

      return (
        <Dialog
          showClosing
          show={ isShowModal }
          onChange={ () => setIsShowModal(false) }
        >
          <div
            className={ styles.wrapper_dialog }
          >
            <Text>
              { LABELS.MODAL.TITLE }
            </Text>
          </div>
          <div
            className={ styles.button_wrapper }
          >
            <Button
              type='textual'
              onClick={ () => handleDelete() }
            >
              { LABELS.MODAL.DELETE }
            </Button>
            <Button
              onClick={ () => setIsShowModal(false) }
            >
              { LABELS.MODAL.CANCEL }
            </Button>
          </div>
        </Dialog>
      );
    };

    return (
      <>
        { renderDeleteModal() }
        <div className={ styles.title_change }>
          <Text type='SEMIBOLD_18'> { title } </Text>
          <div className={ styles.button_wrap }>
            <Button
              type='textual'
              className={ styles.button_change }
              onClick={ () => setChangeForm(!isChangeForm) }
            >
              { LABELS.EDIT_FEE }
            </Button>
            <Button
              type='textual'
              onClick={ () => setIsShowModal(true) }
            >
              { LABELS.CANCEL_FEE }
            </Button>
          </div>
        </div>
        { applicationHtml }
        <div className={ styles.done }>
          <div>
            <Text type='NORMAL_14' className={ styles.label_fee_row }>
              { LABELS.TYPE_FEE }
            </Text>
          </div>
          <div>
            { renderFee }
          </div>
        </div>
        <div className={ styles.done }>
          <div>
            <Text type='NORMAL_14' className={ styles.label_value_row }>
              { LABELS.COLLECTION_VALUE }
            </Text>
          </div>
          <div>
            <Text type='SEMIBOLD_14'>
              { getPriceFee(typeFee, valueFee) }
            </Text>
          </div>
        </div>
      </>
    );
  };

  const renderForm = isChangeForm && Number(valueFee) ? renderDoneForm() : renderEditForm;

  return (
    <div className={ styles.wrapper }>
      { renderForm }
    </div>
  );
};

export { ConstructionForm };
