import React, { useState } from 'react';

import { Input, Text, LinkButton } from 'new-ui';
import { getText } from '../../../../../../../i18n';

import { FieldLabel } from '../../../../../../components/FieldLabel';
import { FormWrapper } from '../../../../../../components/FormWrapper';

import DOCUMENTSFIELDS from '../../../../../../bi/constants/documentsFields';
import PASSPORTS from '../../../../../../bi/constants/passport';
import { IDS_FOR_INTEGRATION_TESTS } from '../../../../../../bi/constants/idsForInregrationTests';
import { QA_ATTRIBUTES } from '../../../../../../bi/constants/attributesForTests';

import { IErrorEmployee } from '../../../../../../bi/services/employee/types';

import { onlyNumbersOrEmpty, removeSpaces } from '../../../../../../bi/utils/text';

import styles from '../index.module.css';

const LABELS = {
  LATIN_LASTNAME: getText('settings:employees.employee.steps.documents.ru.lastName'),
  LATIN_FIRSTNAME: getText('settings:employees.employee.steps.documents.ru.firstName'),
  LATIN_PATRONYMIC: getText('settings:employees.employee.steps.documents.ru.latinPatronymic'),
  NAME: getText('settings:employees.employee.steps.documents.name'),
  LASTNAME: getText('settings:employees.employee.steps.documents.surname'),
  PATRONYMIC: getText('settings:employees.employee.steps.documents.ru.patronymic'),
  DOCUMENT_NUMBER: getText('settings:employees.employee.steps.documents.ru.number'),
  EDIT_TRANSLIT_TITLE: getText('settings:employees.employee.steps.documents.ru.translit.title'),
  CHECK_TRANLITIRATION: getText('settings:employees.employee.steps.documents.ru.translit.checkTransliteration'),
  SHOW_EDIT_TRANSLIT: getText('settings:employees.employee.steps.documents.ru.translit.show'),
  HIDE_EDIT_TRANSLIT: getText('settings:employees.employee.steps.documents.ru.translit.edit'),
};

const INPUT_PLACEHOLDER = '____ _____';

const { employee: { documents: { passportRu } } } = QA_ATTRIBUTES;

interface IRuDocuments {
  error: IErrorEmployee,
  document: IErrorEmployee,
  onResetErrors(field: string): void,
  onValidationInput(field: string, passport?: string): void,
  onInputChange(value: string, type: string): void,
  onBlurInput(value: string, type: string): void,
  onRuNumberChange(value: string): void,
  isAirTripChange: boolean,
  disabled: boolean,
  disabledFieldsNamesChangeAirTrip: boolean,
}

const RuDocument = ({
  error,
  document,
  onResetErrors,
  onValidationInput,
  onInputChange,
  onBlurInput,
  onRuNumberChange,
  isAirTripChange,
  disabled,
  disabledFieldsNamesChangeAirTrip,
}: IRuDocuments) => {
  const [showForm, setShowForm] = useState(false);

  const { LastName, FirstName, MiddleName, Number, Name, Surname, Patronymic } = document;
  const {
    LastName: errLastName,
    FirstName: errFirstName,
    MiddleName: errMiddleName,
    Number: errNumber,
    Name: errName,
    Surname: errSurname,
    Patronymic: errPatronymic,
  } = error;

  const formHtml = showForm && (
    <div className={ styles.row }>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.LATIN_LASTNAME } mustHave />
        <Input
          id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.RU.TRANSLITED_SURNAME }
          value={ LastName }
          onFocus={ () => onResetErrors(DOCUMENTSFIELDS.LASTNAME) }
          onBlur={ () => onBlurInput(LastName, DOCUMENTSFIELDS.LASTNAME) }
          onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.LASTNAME) }
          error={ errLastName }
          disabled={ disabled }
          qaAttr={ passportRu.translited.lastname }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.LATIN_FIRSTNAME } mustHave />
        <Input
          id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.RU.TRANSILTED_NAME }
          value={ FirstName }
          onFocus={ () => onResetErrors(DOCUMENTSFIELDS.FIRSTNAME) }
          onBlur={ () => onBlurInput(FirstName, DOCUMENTSFIELDS.FIRSTNAME) }
          onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.FIRSTNAME) }
          error={ errFirstName }
          disabled={ disabled }
          qaAttr={ passportRu.translited.firstname }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.LATIN_PATRONYMIC } mustHave />
        <Input
          id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.RU.TRANSLITED_PATRONYMIC }
          value={ MiddleName }
          onFocus={ () => onResetErrors(DOCUMENTSFIELDS.MIDDLENAME) }
          onBlur={ () => onBlurInput(MiddleName, DOCUMENTSFIELDS.MIDDLENAME) }
          onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.MIDDLENAME) }
          error={ errMiddleName }
          disabled={ disabled }
          qaAttr={ passportRu.translited.patronymic }
        />
      </div>
    </div>
  );

  const showTransliterated =
    FirstName.length || LastName.length || MiddleName.length || Name.length || Surname.length || Patronymic.length;

  const editTitle = showForm ? LABELS.HIDE_EDIT_TRANSLIT : LABELS.SHOW_EDIT_TRANSLIT;

  const textHtml = !!showTransliterated && (
    <>
      <Text
        className={ styles.translit }
        type='NORMAL_14_130'
      >
        { LABELS.EDIT_TRANSLIT_TITLE }
        {' '}
        <b data-qa={ passportRu.showTranslitedFullName }>{ LastName } { FirstName } { MiddleName }</b>
        {' '}
        <LinkButton qaAttr={ passportRu.showHideTranslited } tabIndex={ -1 } onClick={ () => setShowForm(!showForm) }>({ editTitle }).</LinkButton>
        {' '}
        { LABELS.CHECK_TRANLITIRATION }
      </Text>
      { formHtml }
    </>
  );

  const preparedNumber = Number.length > 4
    ? `${Number.slice(0, 4)} ${Number.slice(4)}`
    : Number;

  const wrapperClassName = disabled ? styles.disabled : '';
  const disabledItemClassName = !disabled && isAirTripChange ? styles.disabled : '';

  return (
    <FormWrapper className={ wrapperClassName }>
      <div className={ styles.row }>
        <div className={ `${styles.item} ${disabledItemClassName}` }>
          <FieldLabel text={ LABELS.LASTNAME } mustHave />
          <Input
            id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.RU.SURNAME }
            value={ Surname }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.SURNAME) }
            onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.SURNAME) }
            error={ errSurname }
            disabled={ disabledFieldsNamesChangeAirTrip }
            qaAttr={ passportRu.surname }
          />
        </div>
        <div className={ `${styles.item} ${disabledItemClassName}` }>
          <FieldLabel text={ LABELS.NAME } mustHave />
          <Input
            id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.RU.NAME }
            value={ Name }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.NAME) }
            onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.NAME) }
            error={ errName }
            disabled={ disabledFieldsNamesChangeAirTrip }
            qaAttr={ passportRu.name }
          />
        </div>
        <div className={ `${styles.item} ${disabledItemClassName}` }>
          <FieldLabel text={ LABELS.PATRONYMIC } mustHave />
          <Input
            id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.RU.PATRONYMIC }
            value={ Patronymic }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.PATRONYMIC) }
            onBlur={ () => onBlurInput(Patronymic, DOCUMENTSFIELDS.PATRONYMIC) }
            onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.PATRONYMIC) }
            error={ errPatronymic }
            disabled={ disabledFieldsNamesChangeAirTrip }
            qaAttr={ passportRu.patronymic }
          />
        </div>
      </div>
      { !isAirTripChange && textHtml }
      <div className={ styles.row }>
        <div className={ styles.item }>
          <FieldLabel text={ LABELS.DOCUMENT_NUMBER } mustHave />
          <Input
            id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.RU.NUMBER }
            value={ preparedNumber }
            withValidation
            validateFn={ v => {
              const withoutSpaces = removeSpaces(v);

              return withoutSpaces.length > 10 || !onlyNumbersOrEmpty(withoutSpaces);
            } }
            onChange={ v => onRuNumberChange(removeSpaces(v)) }
            onBlur={ () => onValidationInput(DOCUMENTSFIELDS.NUMBER, PASSPORTS.RU_PASSPORT.TYPE) }
            placeholder={ INPUT_PLACEHOLDER }
            debounceMs={ 0 }
            error={ errNumber }
            disabled={ disabled }
            qaAttr={ passportRu.seriesAndNumber }
            qaAttrError={ passportRu.seriesAndNumberError }
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export { RuDocument };
