import React, { useState, useRef, FC, CSSProperties } from 'react';
import moment from 'moment';
import { List as _List, ListProps } from 'react-virtualized';
import { Paginate, Text, IconButton } from 'new-ui';
import { ListRowProps } from 'react-virtualized/dist/es/List';
import { getText } from '../../../../../i18n';

import { YandexMaps } from '../../../../components/YandexMap';
import { MapDialog } from '../../../../components/YandexMap/components/MapDialog';
import { HotelResultItem } from '../HotelResultItem';

import CONFIG from '../../../../../config';
import { HEIGHT_LIST, MAP_SIZE_VIEW_MODE, WIDTH_LIST } from '../../../../bi/constants/hotel';
import { PATTERN } from '../../../../bi/constants/dateFormats';
import { VIEW_MODE_TYPES } from '../../../../bi/services/hotels/constants/searchMenuTypes';

import MoneyFormat from '../../../../bi/utils/money';
import slideToTop from '../../../utils/scrollToTop';
import scrollToItem from '../../../utils/scrollToItem';
import { getHotelStatic } from '../../../../bi/utils/hotels';

import HotelsService from '../../../../bi/services/hotels';
import NewHotelsService from '../../../../bi/services/newHotels';
import AppService from '../../../../bi/services/app';

import {
  Filters,
  HotelItemModalMap,
  IHotelsFilters,
  Paging,
  Placemarks,
  PreparedVariant,
} from '../../../../bi/services/hotels/types';

import { ITravelPolicyListItem } from '../../../../bi/types/travelPolicy';

import styles from './style.module.css';

const List = _List as unknown as FC<ListProps>;

const LABELS = {
  EXTENDED_RESULTS_ONE: (region: string) => getText('hotels:regionResult.extendedResults.one', { region }),
  EXTENDED_RESULTS_TWO: getText('hotels:regionResult.extendedResults.two'),
};

interface HotelsResultProps {
  hotels: PreparedVariant[],
  travelPolicyList: ITravelPolicyListItem[],
  paging: Paging,
  filters: Filters,
  query: any,
  selectedTravelPolicy: string,
  handlePageChange(number: number): void,
  showPriceDetails: boolean,
  hotelsService: HotelsService,
  onChangeFavorite(hotel: PreparedVariant, action: string): void,
  onGetLink(item: PreparedVariant, link: string): string,
  onClickToActionLink(): void,
  onCopyToClipboard(): void,
  onShowHotelOnMap(): void,
  nightsCount: string,
  aggregationId: number | null,
  generateLink(hotelId: number, defaultLink: boolean): string,
  newHotelsService: NewHotelsService,
  isFiltersHotelsInMicroservice: boolean,
  hotelsFilters: IHotelsFilters,
  appService: AppService,
}

const HotelResult = ({
  hotels,
  travelPolicyList,
  selectedTravelPolicy,
  paging,
  query,
  showPriceDetails,
  handlePageChange,
  onChangeFavorite,
  onGetLink,
  onClickToActionLink,
  onCopyToClipboard,
  onShowHotelOnMap,
  filters,
  hotelsService,
  newHotelsService,
  nightsCount,
  aggregationId,
  generateLink,
  isFiltersHotelsInMicroservice,
  hotelsFilters,
  appService,
}: HotelsResultProps) => {
  const [openMap, setOpenMap] = useState(false);
  const [hotel, setHotel] = useState<HotelItemModalMap>({
    Longitude: 0,
    Latitude: 0,
  });
  const [isHover, setIsHover] = useState(false);

  const listRef = useRef(null);

  let timeoutId: NodeJS.Timeout;

  const queryParams = {
    CheckinDate: query.checkin.format(PATTERN.YEARMONTHDAY),
    CheckoutDate: query.checkout.format(PATTERN.YEARMONTHDAY),
  };
  const checkin = moment(queryParams.CheckinDate);
  const checkout = moment(queryParams.CheckoutDate);
  const diffDays = checkout.diff(checkin, 'days');

  const { viewMode } = hotelsService.getHotelsState();

  const handleOpenMap = ({
    static: { Address, Stars, Rating, Thumbnail, Latitude, Longitude, HotelName, HotelId, IsSmartHotel },
    rate: { Total },
  }: PreparedVariant) => {
    setHotel({
      Address,
      Latitude,
      Longitude,
      IsSmartHotel,
      Stars,
      Rating,
      MainImageUrl: Thumbnail,
      Name: HotelName,
      ClassificatorId: HotelId,
      rate: {
        Price: {
          TotalPrice: Total,
        },
      },
    });
    setOpenMap(true);
  };

  const handleCloseMap = (value: boolean) => setOpenMap(value);

  const renderMap = () => {
    const { agentMode } = appService.get();
    const center = [hotel.Latitude, hotel.Longitude];

    const placeMarks: Placemarks[] = [
      {
        // @ts-ignore
        ...getHotelStatic(hotel),
      },
    ];

    return (
      <MapDialog show={ openMap } onChange={ handleCloseMap } renderDependsOnShow>
        <YandexMaps
          center={ center }
          customPlacemarks
          placeMarks={ placeMarks }
          APIKEY={ CONFIG.YANDEX_MAPS_API_KEY }
          nightsCount={ nightsCount }
          generateLink={ generateLink }
          onFormatMoney={ MoneyFormat.money }
          agentMode={ agentMode }
        />
      </MapDialog>
    );
  };

  const renderRow = ({ key, index, style }: ListRowProps) => {
    const { itemsMap } = hotelsService.getHotelsState();
    const item: PreparedVariant = itemsMap[index];
    const itemStyle = { ...style, left: 5 };

    return (
      <div
        key={ key }
        style={ itemStyle }
        onMouseEnter={ () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          timeoutId = setTimeout(() => {
            newHotelsService.updateActiveHotel({ id: item.static.HotelId, active: true });
          }, 100);
        } }
        onMouseLeave={ () => {
          clearTimeout(timeoutId);
          newHotelsService.updateActiveHotel({ id: item.static.HotelId, active: false });
        } }
      >
        <HotelResultItem
          ind={ index }
          item={ item }
          filters={ filters }
          query={ query }
          showPriceDetails={ showPriceDetails }
          travelPolicyList={ travelPolicyList }
          selectedTravelPolicy={ selectedTravelPolicy }
          onOpenMap={ handleOpenMap }
          onChangeFavorite={ onChangeFavorite }
          onGetLink={ onGetLink }
          diffDays={ diffDays }
          aggregationId={ aggregationId }
          onClickToActionLink={ onClickToActionLink }
          onCopyToClipboard={ onCopyToClipboard }
          onShowHotelOnMap={ onShowHotelOnMap }
          hotelsService={ hotelsService }
          isFiltersHotelsInMicroservice={ isFiltersHotelsInMicroservice }
          hotelsFilters={ hotelsFilters }
          appService={ appService }
        />
      </div>
    );
  };

  const handleScrollMap = () => (viewMode === VIEW_MODE_TYPES.LIST ? slideToTop() : scrollToItem(listRef, 0));

  const renderIconScrollButton = () => (
    <IconButton
      iconType={ !isHover ? 'arrowsUpRoundBig' : 'arrowsUpRoundBigHover' }
      onClick={ handleScrollMap }
      className={ styles.arrow }
    />
  );

  const renderHotelList = () => {
    const defaultHotelList = hotels.map((item, ind) => (
      <HotelResultItem
        key={ `${item.static.HotelId}__${ind}` }
        ind={ ind }
        item={ item }
        filters={ filters }
        query={ query }
        showPriceDetails={ showPriceDetails }
        travelPolicyList={ travelPolicyList }
        selectedTravelPolicy={ selectedTravelPolicy }
        onOpenMap={ handleOpenMap }
        onChangeFavorite={ onChangeFavorite }
        onGetLink={ onGetLink }
        diffDays={ diffDays }
        aggregationId={ aggregationId }
        onClickToActionLink={ onClickToActionLink }
        onCopyToClipboard={ onCopyToClipboard }
        onShowHotelOnMap={ onShowHotelOnMap }
        hotelsService={ hotelsService }
        isFiltersHotelsInMicroservice={ isFiltersHotelsInMicroservice }
        hotelsFilters={ hotelsFilters }
        appService={ appService }
      />
    ));

    return (
      <>
        {defaultHotelList}
        <div className={ styles.paging }>
          <Paginate
            onChange={ handlePageChange }
            page={ paging.current }
            itemsPerPage={ paging.count }
            total={ paging.total }
          />
        </div>
      </>
    );
  };

  const renderHotelMapList = () => {
    const { itemsMap } = hotelsService.getHotelsState();

    const listStyle: CSSProperties = { overflowX: 'hidden', scrollBehavior: 'smooth' };
    const rowHeight = isFiltersHotelsInMicroservice ? MAP_SIZE_VIEW_MODE.LIST_MAP_WITH_RATE : MAP_SIZE_VIEW_MODE.LISTMAP;

    return (
      <List
        ref={ listRef }
        style={ listStyle }
        width={ WIDTH_LIST }
        height={ HEIGHT_LIST }
        rowCount={ itemsMap.length }
        rowHeight={ rowHeight }
        rowRenderer={ renderRow }
      />
    );
  };

  const { region: { label } } = hotelsService.getSearchState();
  const { initDoubleRadiusSearch } = hotelsService.getHotelsState();

  const renderTitleRadiusSearch = () => (
    <div className={ styles.wrapper }>
      <Text type='bold_14' color='gray' className={ styles.text }>
        {LABELS.EXTENDED_RESULTS_ONE(label)}
      </Text>
      <Text type='bold_14' color='gray' className={ styles.text }>
        {LABELS.EXTENDED_RESULTS_TWO}
      </Text>
    </div>
  );

  const listHtml = viewMode === VIEW_MODE_TYPES.LIST ? renderHotelList() : renderHotelMapList();

  const renderTitleRadiusSearchHtml = initDoubleRadiusSearch && hotels.length
    ? renderTitleRadiusSearch()
    : null;

  const modalMapHtml = viewMode === VIEW_MODE_TYPES.LIST ? renderMap() : null;

  return (
    <>
      {renderTitleRadiusSearchHtml}
      {listHtml}
      {modalMapHtml}
      <div
        onMouseEnter={ () => setIsHover(true) }
        onMouseLeave={ () => setIsHover(false) }
      >
        { renderIconScrollButton() }
      </div>
    </>
  );
};

export { HotelResult };
