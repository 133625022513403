import { dateWithoutMoment, textualMonthPattern } from './formatDate';

import { AirSegment } from '../types/airline';

/** Утилита для сбора строки для названия роута */
const getRouteTitleAdditionalBaggage = (
  segments: AirSegment[],
  includeAirlineName: boolean,
) => {
  const firstSegment = segments[0];
  const lastSegment = segments[segments.length - 1];

  const {
    ArrivalCity,
    ArrivalCityCode,
    ArrivalTime_DateTime,
  } = lastSegment;
  const {
    DepartureCity,
    DepartureTime_DateTime,
    DepartureCityCode,
  } = firstSegment;

  const flightNumbers = segments.map(({ Airline: { ID }, FlightNumber }) => `${ID} ${FlightNumber}`);
  const uniqueFlightNumbers = [...new Set(flightNumbers)].join(', ');

  const departureDate = dateWithoutMoment(DepartureTime_DateTime, textualMonthPattern);
  const arrivalDate = dateWithoutMoment(ArrivalTime_DateTime, textualMonthPattern);

  const cityRouteInfo = `${DepartureCity} (${DepartureCityCode}) - ${ArrivalCity} (${ArrivalCityCode})`;
  const baseRouteTitle = `(${uniqueFlightNumbers}), ${departureDate} - ${arrivalDate}, ${cityRouteInfo}`;

  const routeTitle = includeAirlineName
    ? `${firstSegment.Airline.Name} ${baseRouteTitle}`
    : baseRouteTitle;

  return routeTitle;
};

export { getRouteTitleAdditionalBaggage };
