// @ts-nocheck
import React, { createRef } from 'react';
import { observer } from 'mobx-react';
import { Button, IconButton, Text, Tooltip, PROPS, PageLoader, SearchPanel } from 'new-ui';

import { getText } from '../../../i18n';

import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { disabledApprovalButton, checkFieldsForTooltip } from '../../bi/utils/approval';
import { MainAnalytic } from '../../bi/utils/analytics';
import getAccountId from '../../bi/utils/getAccountId';

import { ROLES_TYPE } from '../../bi/constants/employee';
import { APPROVAL_STATUS_PAGE, TRAVEL_APPROVAL_TYPE } from '../../bi/constants/travelApproval';
import { DEFAULTMENUTYPE } from '../../bi/constants/app';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { ApproveStatus } from '../../bi/services/travelApproval/consts';

import ApprovalSchemeDialog from '../../components/ApprovalSchemeDialog';
import { ApproverContent } from './components/ApproverContent';
import { SpendingBlock } from './components/SpendingBlock';
import GeneralBlock from './components/GeneralBlock';

import { IApprovalRequestPageProps } from './types';

import styles from './styles/index.module.css';

const {
  TEXT: {
    TYPES: { BOLD_32, BOLD_24, BOLD_16, NORMAL_14, NORMAL_14_130 },
    COLORS: { WHITE },
  },
  TOOLTIP: { POSITION: { RIGHT } },
  BUTTON: { TYPES: { SECONDARY, TEXTUAL_MEDIUM } },
  ICON: {
    TYPES: { ARROWS: { BACK } },
    COLORS: { ACCENT },
  },
} = PROPS;

export const LABELS = {
  BACK: getText('components:travelApproval.back'),
  NEW_REQUEST: getText('components:travelApproval.newRequest'),
  DELETE_REQUEST: getText('components:travelApproval.deleteRequest'),
  SAVE: getText('components:travelApproval.sendToApprove'),
  CREATE: getText('components:travelApproval.createRequest'),
  CANCEL: getText('components:travelApproval.cancel'),
  REQUIRED_FIELDS: getText('components:travelApproval.requiredFields'),
  REQUESTS: getText('components:travelApproval.requests'),
  SPENDING_REQUESTS: getText('components:travelApproval.spendingRequests'),
  START_SEARCH: getText('components:travelApproval.startSearch'),
  AVAILABLE_BALANCE: getText('components:travelApproval.availableBalance'),
  YOU_ARE_IN_DEMO: getText('components:travelApproval.youAreInDemo'),
  CREATE_REQUEST_NOT_AVAILABLE: getText('components:travelApproval.createRequestNotAvailable'),
  REQUEST_NOT_FOUND: getText('components:travelApproval.notFound'),
  SELF_APPROVE: {
    BUTTON: getText('components:travelApproval.createRequest'),
    TEXT: getText('components:travelApproval.selfApprove'),
  },
};

@withStores([
  MOBX_STORES.TRAVEL_APPROVAL_STORE,
  MOBX_STORES.DEPARTMENTS,
  MOBX_STORES.APPROVAL_SCHEMES,
  MOBX_STORES.SETTINGS_STORE,
])
@observer
class ApprovalRequestPage extends React.Component<IApprovalRequestPageProps> {
  static defaultProps = {
    isNew: false,
  };

  constructor(props) {
    super(props);

    const { region } = props.hotelsService.getSchemeHotelStoreState();

    this.suggest = createRef();

    this.state = {
      region,
      headDepartments: {},
    };
  }

  searchItems = this.props.uiSettingsProtocol.preparedSearchItems;
  readOnly = this.props.workspaceService.canBuyAccount;
  account = this.props.workspaceService.get();
  rights = this.props.workspaceService.rights;

  async componentDidMount() {
    const {
      isNew,
      match: { params: { id } },
      hotelsService,
      travelApprovalService,
      approvalSchemesService,
      approvalRequestProtocol,
      workspaceService,
      userSessionService,
      settingsService,
      customAnalyticsService,
      tripTagsService,
    } = this.props;
    const { ApprovalSchemeId } = workspaceService.get();

    this.unsubscribeFn = hotelsService.subscribeSchemeHotelStore(this.updateState);

    if (!isNew) {
      await approvalRequestProtocol.loadRequest(id);
    }

    travelApprovalService.setEmployeesWithRights();
    approvalSchemesService.loadRoles();
    travelApprovalService.setAccountAdmin();
    userSessionService.getProjects();
    tripTagsService.loadAccountTripTags();
    customAnalyticsService.getAnalytics().then((userAnalytics) => {
      travelApprovalService.setUsersCustomAnalytics(userAnalytics);
    });
    settingsService.getCompaniesWithCostCenter(getAccountId());

    return approvalSchemesService.loadList(ApprovalSchemeId || null);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
    this.props.hotelsService.resetSchemeHotelStore();
    this.props.travelApprovalService.setResetStore();
  }

  getEmployees = value => this.props.employeeService.autocomplete(value);

  getHead = (type, Id) => {
    const { userSessionService: { getProjectHead, getDepartmentHead } } = this.props;

    if (type === ROLES_TYPE.DEPARTMENT) {
      return getDepartmentHead(Id);
    }

    return getProjectHead(Id);
  };

  updateState = ({ region }) => this.setState({ region });

  handleGetDepartments = () => {
    const { stores: { travelApprovalStore: { travelApproval } } } = this.props;
    const { headDepartments } = this.state;

    const employees = travelApproval.Employees.filter((e) => e.DepartmentId && !headDepartments[e.DepartmentId]);

    if (employees.length) {
      employees.forEach((e) => {
        this.getHead(ROLES_TYPE.DEPARTMENT, e.DepartmentId).then(res => {
          headDepartments[e.DepartmentId] = res.EmployeeId;

          this.setState({ headDepartments });
        });
      });
    }
  };

  handleAddCustomAnalytic = async (analyticsId, value) => {
    const {
      customAnalyticsService,
      travelApprovalService,
    } = this.props;

    const analyticValue = await customAnalyticsService.addAnalyticsValue(analyticsId, value);

    travelApprovalService.setCustomAnalytic(analyticValue);
    customAnalyticsService.getAnalytics().then((userAnalytics) => {
      travelApprovalService.setUsersCustomAnalytics(userAnalytics);
    });
  };

  handleAddEmployee = (item, index) => {
    this.props.travelApprovalService.addEmployee(item, index);

    return Promise.resolve();
  };

  handleRemoveEmployee = (item) => this.props.travelApprovalService.removeEmployee(item);

  handleClickSearch = async (type) => {
    const { airlineService, trainsService, hotelsService, aeroexpressService,
      stores: { travelApprovalStore: { travelApproval } } } = this.props;

    switch (type) {
      case (DEFAULTMENUTYPE.SEARCHAIRLINE): {
        await airlineService.setSearchFromApproveRequest(travelApproval);

        break;
      }
      case (DEFAULTMENUTYPE.SEARCHTRAIN): {
        await trainsService.setSearchFromApproveRequest(travelApproval);

        break;
      }

      case (DEFAULTMENUTYPE.SEARCHHOTEL): {
        await hotelsService.setSearchFromApproveRequest(travelApproval);

        break;
      }

      case (DEFAULTMENUTYPE.SEARCHAEROEXPRESS): {
        await aeroexpressService.setSearchFromApproveRequest(travelApproval);

        break;
      }
    }
  };

  handleAddEmployeeCompany = (item, index) => {
    this.props.travelApprovalService.addEmployeeCompany(item, index);
    this.handleGetDepartments();
  };

  handleShowApproveDialog = value => this.props.travelApprovalService.setShowApproveDialog(value);

  handleApprove = async (field, comment) => {
    await this.props.approvalRequestProtocol.approveRequest(field, comment);

    this.handleGoBack();
  };

  handleGoBack = () => this.props.history.goBack();

  handleGoToApplicationsSchemes = () => this.props.history.push(ROUTES.APPLICATION_SCHEME_REQUEST);

  handleSendRequest = async (model) => {
    const {
      travelApprovalService: {
        sendRequest,
      },
      stores: {
        travelApprovalStore: { scheme },
        approvalSchemesStore: { list: { value } },
      },
    } = this.props;

    if (scheme?.CanSkipApproval) {
      const approvalScheme = value.find(({ Id }) => Id === this.account.ApprovalSchemeId) || null;

      const modelForSend = ({
        Comment: '',
        StepsSettings: [
          { Approvers: [this.rights.UserId], Id: approvalScheme.PreSteps[0].Id },
        ],
      });

      await sendRequest(modelForSend, this.account.ApprovalSchemeId);
    } else {
      await sendRequest(model, this.account.ApprovalSchemeId);
    }

    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_SEND_TO_APPROVER);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_SEND_TO_APPROVER);

    this.handleGoToApplicationsSchemes();
  };

  handleSave = () => {
    this.props.travelApprovalService.getApproverScheme();

    return this.handleShowApproveDialog(true);
  };

  handleChangeEmployeeProject = (projectId: number | null, employeeId: number) => {
    const {
      travelApprovalService: { addProject, updateProject },
      match: { params: { id: requestId } },
      isNew,
    } = this.props;

    if (isNew) {
      return addProject(projectId, employeeId);
    }

    return updateProject(projectId, employeeId, requestId);
  };

  emptyComment = (comment) => {
    const {
      accountSettingsService: {
        store: {
          accountSettings: {
            forceTripApproveComment,
          },
        },
      },
    } = this.props;

    return forceTripApproveComment && !comment.trim();
  };

  renderTooltipContent = (Employees, Budget, Destinations, date, Purpose, Comment, applicationScheme) => {
    const {
      workspaceService: { isDemo },
      stores: {
        settingsStore: { companiesWithCostCenter },
        travelApprovalStore: { travelApproval: { Analytics } },
      },
      customAnalyticsService: { store: { sortedCustomAnalytics } },
    } = this.props;

    const nonFilledFields = checkFieldsForTooltip(
      Employees,
      Budget,
      Destinations,
      date,
      Purpose,
      Comment,
      applicationScheme,
      false,
      companiesWithCostCenter,
      sortedCustomAnalytics,
      Analytics,
    );
    const textHtml = !!nonFilledFields.length && (
      <Text
        color={ WHITE }
        type={ NORMAL_14_130 }
        className={ styles['error-text'] }
      >
        { nonFilledFields.join(', ') }
      </Text>
    );

    return (
      <div className={ styles.tooltip }>
        {
          isDemo
            ? (
              <>
                <Text color={ WHITE } type={ NORMAL_14_130 }>
                  { LABELS.YOU_ARE_IN_DEMO }
                </Text>
                <Text color={ WHITE } type={ NORMAL_14_130 }>
                  { LABELS.CREATE_REQUEST_NOT_AVAILABLE }
                </Text>
              </>
            )
            : (
              <>
                <Text color={ WHITE } type={ BOLD_16 }>
                  { LABELS.REQUIRED_FIELDS }
                </Text>
                { textHtml }
              </>
            )
        }
      </div>
    );
  };

  renderGeneralBlock = (removeSpending?: boolean = false) => {
    const {
      stores: {
        travelApprovalStore,
        approvalSchemesStore,
      },
      workspaceService,
      isNew,
      userSessionService,
      customAnalyticsService,
      featureFlagsService,
      tripTagsService,
      travelApprovalService,
      hotelsService,
    } = this.props;
    const { region } = this.state;
    const { EmployeeId } = this.rights;
    const { travelApproval, purposes, employeesWithRights, statusPage, approvalPopUp } = travelApprovalStore;

    const { Template } = travelApproval;
    const { applicationSchemeTemplate } = approvalSchemesStore;
    const applicationScheme = isNew ? applicationSchemeTemplate : Template;
    const disabledField = statusPage !== APPROVAL_STATUS_PAGE.APPROVER && statusPage !== APPROVAL_STATUS_PAGE.CREATE;
    const disabledToUpdateField = statusPage !== APPROVAL_STATUS_PAGE.CREATE;

    return (
      <>
        <SpendingBlock hide={ removeSpending } />
        <GeneralBlock
          travelApproval={ travelApproval }
          approvalPopUp={ approvalPopUp }
          applicationSchemeTemplate={ applicationScheme }
          purposes={ purposes }
          region={ region }
          readOnly={ !this.readOnly }
          isMulti={ workspaceService.isMultiCompany() }
          employeesWithRights={ employeesWithRights }
          employeeId={ EmployeeId }
          disabledField={ disabledField }
          disabledToUpdateField={ disabledToUpdateField }
          getEmployees={ this.getEmployees }
          onAddEmployee={ this.handleAddEmployee }
          onRemoveEmployee={ this.handleRemoveEmployee }
          onAddEmployeeCompany={ this.handleAddEmployeeCompany }
          onAddCustomAnalytic={ this.handleAddCustomAnalytic }
          isNew={ isNew }
          userSessionService={ userSessionService }
          onAddProject={ this.handleChangeEmployeeProject }
          customAnalyticsService={ customAnalyticsService }
          featureFlagsService={ featureFlagsService }
          workspaceService={ workspaceService }
          hotelsService={ hotelsService }
          tripTagsService={ tripTagsService }
          travelApprovalService={ travelApprovalService }
          qaAttr={ QA_ATTRIBUTES.approvalRequest }
        />
      </>
    );
  };

  renderCreate = () => {
    const {
      stores: {
        travelApprovalStore: { travelApproval, loadingSendButton },
        approvalSchemesStore: { applicationSchemeTemplate },
        settingsStore: { companiesWithCostCenter },
      },
      workspaceService: { isDemo },
      customAnalyticsService: { store: { sortedCustomAnalytics } },
      isNew,
    } = this.props;
    const { Employees, Budget, Destinations, StartDate, EndDate, Purpose, Comment, Template, Analytics } = travelApproval;

    const date = StartDate && EndDate;
    const purposeOfTripIsRequired = travelApproval.Purpose === -1;

    const applicationScheme = isNew ? applicationSchemeTemplate : Template;
    const isDestinationsRequired = applicationSchemeTemplate && applicationSchemeTemplate.IsRequiredDestinationCity;
    const allDestinationsFilled = Destinations.every(destination => destination.Id !== null);
    const destination = isDestinationsRequired ? allDestinationsFilled : false;

    const { disabled } = disabledApprovalButton(
      Employees,
      Budget,
      destination,
      date,
      Purpose,
      Comment,
      applicationScheme,
      false,
      companiesWithCostCenter,
      sortedCustomAnalytics,
      Analytics,
    );

    const getQaAttrButton = (disabledButton, purpose) => (
      disabledButton || purpose ? QA_ATTRIBUTES.approvalRequest.button.disabled : QA_ATTRIBUTES.approvalRequest.button.enabled);

    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.CREATE_NEW_TRAVEL_APPROVAL_SCREEN_OPENED);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.CREATE_NEW_TRAVEL_APPROVAL_SCREEN_OPENED);

    return (
      <>
        <Text
          className={ styles.title }
          type={ BOLD_32 }
          qaAttr={ QA_ATTRIBUTES.approvalRequest.titleNew }
        >
          { LABELS.NEW_REQUEST }
        </Text>
        { this.renderGeneralBlock() }
        <div className={ styles.panel }>
          <div className={ styles.panel_content }>
            <Tooltip
              show={ isDemo || disabled || purposeOfTripIsRequired }
              position={ RIGHT }
              renderContent={ () => this.renderTooltipContent(Employees, Budget, Destinations[0].Id, date, Purpose, Comment, applicationScheme) }
            >
              <Button
                loading={ loadingSendButton }
                disabled={ isDemo || disabled || purposeOfTripIsRequired }
                type={ SECONDARY }
                className={ styles.panel_button }
                onClick={ this.handleSave }
                qaAttr={ getQaAttrButton(disabled, purposeOfTripIsRequired) }
              >
                { LABELS.SAVE }
              </Button>
            </Tooltip>
            <Button
              type={ TEXTUAL_MEDIUM }
              onClick={ this.handleGoBack }
            >
              { LABELS.CANCEL }
            </Button>
          </div>
        </div>
      </>
    );
  };

  renderApprove = () => (
    <>
      <div className={ styles.menu }>
        <Text className={ styles.search } type={ BOLD_24 }>{ LABELS.START_SEARCH }</Text>
        <SearchPanel
          searchMenuItems={ this.searchItems }
          onClick={ this.handleClickSearch }
        />
      </div>
      <SpendingBlock />
      { this.renderGeneralBlock(true) }
    </>
  );

  renderApprover = () => {
    const {
      workspaceService: {
        isDemo,
        isAdmin,
        AccountEmail,
        isMultiCompany,
        accountStore,
      },
      workspaceService,
      stores: {
        travelApprovalStore: {
          statusPage,
          travelApproval: { Status, Approves, Template, Analytics },
          admin,
        },
        approvalSchemesStore,
      },
      isNew,
      userSessionService,
      customAnalyticsService: { store: { sortedCustomAnalytics } },
      customAnalyticsService,
      featureFlagsService,
      travelApprovalService,
      accountSettingsService,
      tripTagsService,
      hotelsService,
    } = this.props;
    const { applicationSchemeTemplate } = approvalSchemesStore;

    const { region } = this.state;

    const { EmployeeId, UserId } = this.rights;
    const disabledField = !(
      statusPage === APPROVAL_STATUS_PAGE.CREATE ||
      (
        statusPage === APPROVAL_STATUS_PAGE.APPROVER
          && Status === ApproveStatus.waitingApprove
          && Approves.some((i) => accountStore.isNowUser(i.UserId))
      )
    );
    const disabledToUpdateField = statusPage !== APPROVAL_STATUS_PAGE.CREATE;

    const currentUserId = isAdmin ? admin?.id : UserId;
    const mayApprove = !!Approves.filter(({ UserId: id }) => id === currentUserId)?.length;
    const applicationScheme = isNew ? applicationSchemeTemplate : Template;

    const accountEmail = isAdmin ? AccountEmail : '';

    return (
      <ApproverContent
        region={ region }
        readOnly={ !this.readOnly }
        isMultiCompany={ isMultiCompany() }
        isDemo={ isDemo }
        accountEmail={ accountEmail }
        employeeId={ EmployeeId }
        disabled={ disabledField }
        disabledToUpdateField={ disabledToUpdateField }
        currentUserId={ currentUserId }
        applicationSchemeTemplate={ applicationScheme }
        mayApprove={ mayApprove }
        featureFlagsService={ featureFlagsService }
        customAnalyticsService={ customAnalyticsService }
        userAnalytics={ sortedCustomAnalytics }
        selectedAnalytics={ Analytics }
        onApproveRequest={ this.handleApprove }
        getEmployees={ this.getEmployees }
        onAddEmployee={ this.handleAddEmployee }
        onRemoveEmployee={ this.handleRemoveEmployee }
        onAddEmployeeCompany={ this.handleAddEmployeeCompany }
        onAddCustomAnalytic={ this.handleAddCustomAnalytic }
        isNew={ isNew }
        hotelsService={ hotelsService }
        userSessionService={ userSessionService }
        onAddProject={ this.handleChangeEmployeeProject }
        accountSettingsService={ accountSettingsService }
        travelApprovalService={ travelApprovalService }
        tripTagsService={ tripTagsService }
        workspaceService={ workspaceService }
      />
    );
  };

  renderPage = status => ({
    [APPROVAL_STATUS_PAGE.CREATE]: this.renderCreate,
    [APPROVAL_STATUS_PAGE.APPROVE]: this.renderApprove,
    [APPROVAL_STATUS_PAGE.WAITING_APPROVE]: this.renderGeneralBlock,
    [APPROVAL_STATUS_PAGE.NOT_APPROVE]: this.renderGeneralBlock,
    [APPROVAL_STATUS_PAGE.APPROVER]: this.renderApprover,
  }[status]());

  renderApprovalSchemeDialog = () => {
    const {
      stores: {
        travelApprovalStore: { showApproveDialog, admin, scheme },
        approvalSchemesStore: { list: { error }, getApproverListLoading } },
      userSessionService,
    } = this.props;

    const { UserId } = this.rights;

    const closeDialog = () => this.handleShowApproveDialog(false);

    return (
      <ApprovalSchemeDialog
        approvalType={ TRAVEL_APPROVAL_TYPE.IS_APPROVAL_PAGE }
        loaded={ getApproverListLoading }
        error={ error }
        show={ showApproveDialog }
        scheme={ scheme }
        isTpDisrupted={ false }
        chosenProjects={ [] }
        currentUserId={ UserId }
        admin={ admin }
        onSubmit={ this.handleSendRequest }
        onClose={ closeDialog }
        userSessionService={ userSessionService }
        emptyComment={ this.emptyComment }
        approveTexts={ LABELS.SELF_APPROVE }
        onlySteps
      />
    );
  };

  render() {
    const {
      stores: {
        travelApprovalStore: { showApproveDialog, statusPage, loading },
        approvalSchemesStore: { list: { loading: schemeLoading } } },
    } = this.props;

    if (loading || schemeLoading) {
      return <PageLoader />;
    }

    const label = statusPage === APPROVAL_STATUS_PAGE.CREATE ? LABELS.BACK : LABELS.REQUESTS;
    const showApproveDialogHtml = showApproveDialog && this.renderApprovalSchemeDialog();

    return (
      <>
        <div className={ styles.wrapper }>
          <IconButton
            className={ styles.back }
            iconType={ BACK }
            iconColor={ ACCENT }
            onClick={ this.handleGoBack }
          >
            <Text type={ NORMAL_14 }>
              { label }
            </Text>
          </IconButton>
          { this.renderPage(statusPage) }
          { showApproveDialogHtml }
        </div>
      </>
    );
  }
}

export default ApprovalRequestPage;
