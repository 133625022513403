// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Text } from 'new-ui';
import clsx from 'clsx';
import { getText } from '../../../i18n';

import { WarningMessage } from '../../components/WarningMessage';

import { AeroexpressSearchMenu } from './components/SearchMenu';
import { AeroexpressSearchResult } from './components/SearchResult';
import { DateInSearch } from '../../components/DateInSerch';
import { BackToTripLink } from '../../components/BackToTripLink';

import { parseSearchString } from '../../bi/utils/convertSearchParams';
import { momentObject } from '../../bi/utils/formatDate';
import { MainAnalytic } from '../../bi/utils/analytics';

import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { ANALYTIC_SERVICE_TYPES } from '../../bi/constants/serviceType';

import { isSmartAgent } from '../../bi/utils/env';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('aeroexpress:title'),
  GET_AEROEXPRESS_ADDED_TO_CART_TEXT: getText('aeroexpress:addedToCart'),
  ORDER_FOR_A_TRIP: getText('components:trip.orderForATrip'),
  INFO_MESSAGE: getText('aeroexpress:infoMessage'),
};

@withStores([MOBX_STORES.AEROEXPRESS])
@observer
class AeroexpressPage extends Component {
  static propTypes = {
    aeroexpressService: PropTypes.object.isRequired,
    travelApprovalService: PropTypes.object.isRequired,
    notificationService: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    isShowTitle: PropTypes.bool,
    stores: PropTypes.shape({
      aeroexpressStore: PropTypes.object.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isShowTitle: true,
  };

  constructor(props) {
    super(props);

    const searchParams = parseSearchString(props.history.location.search);

    this.state = {
      showResult: false,
      addingToCart: false,
      tripId: searchParams?.tripId || null,
      tripDate: searchParams?.tripDate || '',
      tripName: searchParams?.tripName || '',
    };
  }

  componentDidUpdate({ location: { key: prevKey } }) {
    const { tripId, tripDate, tripName } = this.state;
    const { location: { key }, aeroexpressService } = this.props;
    const searchParams = parseSearchString(this.props.history.location.search);

    const searchTripId = searchParams?.tripId || '';
    const searchTripDate = searchParams?.tripDate || '';
    const searchTripName = searchParams?.tripName || '';

    if (prevKey !== key) {
      aeroexpressService.setNewSearch();
    }

    if (searchTripId !== tripId || searchTripDate !== tripDate || searchTripName !== tripName) {
      this.setState({
        tripId: searchParams?.tripId || '',
        tripDate: searchParams?.tripDate || '',
        tripName: searchParams?.tripName || '',
      });
    }
  }

  componentDidMount() {
    const { aeroexpressService } = this.props;

    aeroexpressService.getRoutes();

    const searchParams = parseSearchString(location.search);

    if (searchParams && !searchParams.tripId && !aeroexpressService.isTravelApproval) {
      const { dateDeparture, dateBack, count } = searchParams;

      aeroexpressService.updateSearchSettings({
        ...searchParams,
        count: +count,
        dateDeparture: momentObject(dateDeparture),
        dateBack: dateBack ? momentObject(dateBack) : null,
      });

      this.handleSearch();
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SEARCH.SEARCH_AERO_EXPRESS_SCREEN_OPENED);
  }

  componentWillUnmount() {
    this.props.aeroexpressService.setNewSearch();
    this.props.travelApprovalService.setResetTravelApprovalsForCartStore();
  }

  handleSearch = () => {
    const { aeroexpressService } = this.props;

    this.setState({ showResult: true });
    aeroexpressService.search();

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.MAINSEARCH_SEARCH_PRESSED('searchAeroexpress'),
    );

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_FIND_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.AERO_EXPRESS),
    );
  };

  handleAddToCart = async (ids) => {
    const { aeroexpressService, notificationService, history } = this.props;
    const { tripId } = this.state;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_ADD_CART_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.AERO_EXPRESS),
    );

    this.setState({ addingToCart: true });

    const firstPromise = await aeroexpressService.addToCart({ RequestId: null, SearchId: ids[0], TripId: tripId });

    const otherAddToCartPromises = [];
    [...ids.splice(1)].forEach(id =>
      otherAddToCartPromises.push(aeroexpressService.addToCart({ RequestId: null, SearchId: id, TripId: tripId })));

    Promise.all([firstPromise, ...otherAddToCartPromises]).then(() => {
      this.setState({ showResult: false, addingToCart: false });
      notificationService.send({
        level: 'success',
        message: LABELS.GET_AEROEXPRESS_ADDED_TO_CART_TEXT,
        onClick: () => history.push(ROUTES.CART.MAIN),
        qaAttr: QA_ATTRIBUTES.search.aeroexpress.buttonCartNotification,
      });
    });
  };

  renderAeroexpressInfoMessage = () => (
    <div className={ styles.info }>
      <Text type='bold_24'>
        { LABELS.INFO_MESSAGE }
      </Text>
    </div>
  );

  renderWarningMessage = this.props.featureFlagsService.getTripRuleCustomMessage() ? <WarningMessage /> : null;

  renderTitle = () => {
    const { featureFlagsService: { getShowElementForSmartagent }, isShowTitle } = this.props;
    const { tripId } = this.state;

    if (isSmartAgent && getShowElementForSmartagent() && !isShowTitle) {
      return null;
    }

    const title = tripId ? LABELS.ORDER_FOR_A_TRIP : LABELS.TITLE;

    return (
      <Text
        type='bold_32'
        className={ styles.title }
        qaAttr={ QA_ATTRIBUTES.search.aeroexpress.text }
      >
        { title }
      </Text>
    );
  };

  render() {
    const { showResult, addingToCart, tripId, tripName, tripDate } = this.state;
    const {
      aeroexpressService,
      stores: {
        aeroexpressStore,
      },
      featureFlagsService,
      featureFlagsService: {
        getShowElementForSmartagent,
      },
      isShowTitle,
    } = this.props;

    const wrapper = clsx(
      styles.wrapper,
      isSmartAgent && getShowElementForSmartagent() && !isShowTitle && styles.smartdesk_sa,
    );

    const wrapperMenu = clsx(
      styles.menu,
      isSmartAgent && getShowElementForSmartagent() && !isShowTitle && styles.search_sa,
    );

    const addingTriipDate = tripId ? <DateInSearch name={ tripName } date={ tripDate }/> : null;
    const resultHtml = showResult
      ? (
        <AeroexpressSearchResult
          onClose={ () => this.setState({ showResult: false }) }
          result={ aeroexpressStore.searchResult }
          settings={ aeroexpressStore.searchSettings }
          onAddToCart={ this.handleAddToCart }
          addingToCartLoading={ addingToCart }
          show={ showResult }
          qaAttrs={ QA_ATTRIBUTES.search.aeroexpress.result }
        />
      )
      : null;

    return (
      <div className={ wrapper }>
        <BackToTripLink tripId={ tripId } />
        { this.renderTitle() }
        { addingTriipDate }
        <div className={ wrapperMenu }>
          <AeroexpressSearchMenu
            isValid={ aeroexpressStore.isFormValid }
            departureRange={ aeroexpressStore.departureDateAvailableRange }
            backRange={ aeroexpressStore.backDateAvailableRange }
            routes={ aeroexpressStore.routes }
            settings={ aeroexpressStore.searchSettings }
            schedules={ aeroexpressStore.schedules }
            onChange={ payload => aeroexpressService.updateSearchSettings(payload) }
            disableSearchButton={ dateValue => aeroexpressService.disableSearchButton(dateValue) }
            onSearch={ this.handleSearch }
            featureFlagsService={ featureFlagsService }
            qaAttrs={ QA_ATTRIBUTES.search.aeroexpress.panel }
          />
          { this.renderWarningMessage }
          { resultHtml }
        </div>
        { this.renderAeroexpressInfoMessage() }
      </div>
    );
  }
}

export { AeroexpressPage };
